/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvoiceLine } from './InvoiceLine';
import {
    InvoiceLineFromJSON,
    InvoiceLineFromJSONTyped,
    InvoiceLineToJSON,
} from './InvoiceLine';

/**
 * 
 * @export
 * @interface EditInvoiceRequest
 */
export interface EditInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    contactId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    paymentTermId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof EditInvoiceRequest
     */
    issuedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EditInvoiceRequest
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EditInvoiceRequest
     */
    dateTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof EditInvoiceRequest
     */
    discountPercent?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditInvoiceRequest
     */
    toErp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditInvoiceRequest
     */
    isDraft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditInvoiceRequest
     */
    reference?: string;
    /**
     * 
     * @type {Array<InvoiceLine>}
     * @memberof EditInvoiceRequest
     */
    invoiceLines?: Array<InvoiceLine>;
}

/**
 * Check if a given object implements the EditInvoiceRequest interface.
 */
export function instanceOfEditInvoiceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditInvoiceRequestFromJSON(json: any): EditInvoiceRequest {
    return EditInvoiceRequestFromJSONTyped(json, false);
}

export function EditInvoiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditInvoiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'paymentTermId': !exists(json, 'payment_term_id') ? undefined : json['payment_term_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'issuedDate': !exists(json, 'issued_date') ? undefined : (new Date(json['issued_date'])),
        'dateFrom': !exists(json, 'date_from') ? undefined : (new Date(json['date_from'])),
        'dateTo': !exists(json, 'date_to') ? undefined : (new Date(json['date_to'])),
        'discountPercent': !exists(json, 'discount_percent') ? undefined : json['discount_percent'],
        'toErp': !exists(json, 'to_erp') ? undefined : json['to_erp'],
        'isDraft': !exists(json, 'is_draft') ? undefined : json['is_draft'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'invoiceLines': !exists(json, 'invoice_lines') ? undefined : ((json['invoice_lines'] as Array<any>).map(InvoiceLineFromJSON)),
    };
}

export function EditInvoiceRequestToJSON(value?: EditInvoiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact_id': value.contactId,
        'project_id': value.projectId,
        'payment_term_id': value.paymentTermId,
        'title': value.title,
        'message': value.message,
        'issued_date': value.issuedDate === undefined ? undefined : (value.issuedDate.toISOString().substr(0,10)),
        'date_from': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString().substr(0,10)),
        'date_to': value.dateTo === undefined ? undefined : (value.dateTo.toISOString().substr(0,10)),
        'discount_percent': value.discountPercent,
        'to_erp': value.toErp,
        'is_draft': value.isDraft,
        'reference': value.reference,
        'invoice_lines': value.invoiceLines === undefined ? undefined : ((value.invoiceLines as Array<any>).map(InvoiceLineToJSON)),
    };
}

