import { Select } from "@ngxs/store";
import { FormTemplatesState } from "src/store/form-templates/form-templates.state";
import { Observable } from "rxjs";
import { StateForm, FormTimeEntry } from "src/app/services/response-models/form.response.model";
import * as deepClone from "lodash.clonedeep";
import { ProjectStatus } from "src/app/services/response-models/project-statuses.reponse.model";
import { SelectedProduct } from "src/app/employee/forms/form-field-types/products/models/SelectedProduct";

/**
 * This class wraps the setting and retrieving of values for the form fields.
 * Different form fields expect thier data to be handled in a different way
 * and the logic for that is wrapped here.
 * All form field components extend this class
 */
export class FormField {
    @Select(FormTemplatesState.getStateForm) stateForm$: Observable<StateForm>;
    // Inherited from child class
    public placement: number;

    // Common
    public contentValue: any;
    public comment: any;
    // Products
    public selectedProducts: Array<SelectedProduct>;
    // Checkboxes
    public isChecked: any;
    // Contacts
    public selectedContact: any;
    // Ks questions
    public ksStatus: string;
    public ksComment: string;
    // Materials
    public selectedCheckInMaterials: any;
    public selectedCheckOutMaterials: any;
    // Project Status
    public selectedStatus: Partial<ProjectStatus> = {};

    // Time Entries
    public getTimeEntriesToCreate: Array<FormTimeEntry>;

    public getContentValue = async () => {
        this.stateForm$
            .subscribe((data) => {
                if (data) {
                    const field = data.form_fields.find(
                        (x) => x.placement === Number(this.placement)
                    );
                    if (field) {
                        this.contentValue = field.content_value;
                    }
                }
            })
            .unsubscribe();
    };

    public getComment = async () => {
        this.stateForm$
            .subscribe((data) => {
                if (data) {
                    const field = data.form_fields.find(
                        (x) => x.placement === Number(this.placement)
                    );
                    if (field) {
                        this.comment = field.comment;
                    }
                }
            })
            .unsubscribe();
    };

    public getSelectedProducts = async () => {
        this.stateForm$
            .subscribe((data) => {
                let products = data.form_fields.filter(
                    (x) => x.placement === Number(this.placement)
                )[0].content_value;

                if (typeof products === "object") {
                    products = products.map((x) => {
                        x = { ...x };
                        x._joinData = { ...x._joinData };
                        return x;
                    });
                    this.selectedProducts = products;
                }
            })
            .unsubscribe();
    };

    public getCheckboxValue = async () => {
        this.stateForm$
            .subscribe((data) => {
                const checkboxField = data.form_fields.filter(
                    (x) => x.placement === Number(this.placement)
                )[0];

                this.isChecked = checkboxField.content_value;
                this.comment = checkboxField.comment;
            })
            .unsubscribe();
    };

    public getContactValue = async () => {
        this.stateForm$
            .subscribe((data) => {
                const contactField = (this.selectedContact = data.form_fields.filter(
                    (x) => x.placement === Number(this.placement)
                )[0]);

                this.selectedContact = {
                    id: contactField.content_value,
                    name: contactField.comment,
                };
            })
            .unsubscribe();
    };

    public getKsQuestionValue = async () => {
        this.stateForm$
            .subscribe((data) => {
                const ksField = data.form_fields.filter(
                    (x) => x.placement === Number(this.placement)
                )[0];

                this.ksStatus = ksField.content_value;

                if (window.location.pathname.includes("my-forms")) {
                    this.ksComment = ksField.comment;
                }
            })
            .unsubscribe();
    };

    public getMaterialsValue = async () => {
        let form: StateForm;
        await this.stateForm$
            .subscribe((data) => {
                form = data;
            })
            .unsubscribe();

        const materialsField = form.form_fields.find((x) => x.placement === Number(this.placement));

        if (!materialsField.content_value) {
            this.selectedCheckInMaterials = [];
            this.selectedCheckOutMaterials = [];
            return;
        }

        this.selectedCheckInMaterials = deepClone(
            materialsField.content_value.checkInMaterials
                ? materialsField.content_value.checkInMaterials
                : []
        );
        this.selectedCheckOutMaterials = deepClone(
            materialsField.content_value.checkOutMaterials
                ? materialsField.content_value.checkOutMaterials
                : []
        );
    };

    public getProjectStatusValue = async () => {
        this.stateForm$
            .subscribe((data) => {
                const selectedStatusField = data.form_fields.find(
                    (x) => x.placement === Number(this.placement)
                );

                if (!selectedStatusField) {
                    return;
                }

                this.selectedStatus.id = selectedStatusField.comment;
                this.selectedStatus.name = selectedStatusField.content_value;
            })
            .unsubscribe();
    };

    public getTimeEntriesValue = async () => {
        this.stateForm$
            .subscribe((data) => {
                if (data) {
                    const timeEntries = data.form_fields.find((x) =>
                        x.identifier.includes("time_entry")
                    );

                    // Set it to what is in the satete or an empty array
                    if (timeEntries) {
                        this.getTimeEntriesToCreate = timeEntries.content_value || [];
                    }
                }
            })
            .unsubscribe();
    };
}
