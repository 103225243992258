/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IsCheckedIn200ResponseData } from './IsCheckedIn200ResponseData';
import {
    IsCheckedIn200ResponseDataFromJSON,
    IsCheckedIn200ResponseDataFromJSONTyped,
    IsCheckedIn200ResponseDataToJSON,
} from './IsCheckedIn200ResponseData';

/**
 * 
 * @export
 * @interface IsCheckedIn200Response
 */
export interface IsCheckedIn200Response {
    /**
     * 
     * @type {IsCheckedIn200ResponseData}
     * @memberof IsCheckedIn200Response
     */
    data: IsCheckedIn200ResponseData;
}

/**
 * Check if a given object implements the IsCheckedIn200Response interface.
 */
export function instanceOfIsCheckedIn200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function IsCheckedIn200ResponseFromJSON(json: any): IsCheckedIn200Response {
    return IsCheckedIn200ResponseFromJSONTyped(json, false);
}

export function IsCheckedIn200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsCheckedIn200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': IsCheckedIn200ResponseDataFromJSON(json['data']),
    };
}

export function IsCheckedIn200ResponseToJSON(value?: IsCheckedIn200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': IsCheckedIn200ResponseDataToJSON(value.data),
    };
}

