/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckInRequest
 */
export interface CheckInRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckInRequest
     */
    projectId: string;
    /**
     * 
     * @type {Date}
     * @memberof CheckInRequest
     */
    checkedIn?: Date;
    /**
     * 
     * @type {string}
     * @memberof CheckInRequest
     */
    checkinLongitude?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckInRequest
     */
    checkinLatitude?: string | null;
}

/**
 * Check if a given object implements the CheckInRequest interface.
 */
export function instanceOfCheckInRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;

    return isInstance;
}

export function CheckInRequestFromJSON(json: any): CheckInRequest {
    return CheckInRequestFromJSONTyped(json, false);
}

export function CheckInRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckInRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'checkedIn': !exists(json, 'checked_in') ? undefined : (new Date(json['checked_in'])),
        'checkinLongitude': !exists(json, 'checkin_longitude') ? undefined : json['checkin_longitude'],
        'checkinLatitude': !exists(json, 'checkin_latitude') ? undefined : json['checkin_latitude'],
    };
}

export function CheckInRequestToJSON(value?: CheckInRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'checked_in': value.checkedIn === undefined ? undefined : (value.checkedIn.toISOString()),
        'checkin_longitude': value.checkinLongitude,
        'checkin_latitude': value.checkinLatitude,
    };
}

