/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckOutRequest
 */
export interface CheckOutRequest {
    /**
     * 
     * @type {Date}
     * @memberof CheckOutRequest
     */
    checkedOut?: Date;
    /**
     * 
     * @type {string}
     * @memberof CheckOutRequest
     */
    checkoutLatitude?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckOutRequest
     */
    checkoutLongitude?: string | null;
}

/**
 * Check if a given object implements the CheckOutRequest interface.
 */
export function instanceOfCheckOutRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CheckOutRequestFromJSON(json: any): CheckOutRequest {
    return CheckOutRequestFromJSONTyped(json, false);
}

export function CheckOutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckOutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkedOut': !exists(json, 'checked_out') ? undefined : (new Date(json['checked_out'])),
        'checkoutLatitude': !exists(json, 'checkout_latitude') ? undefined : json['checkout_latitude'],
        'checkoutLongitude': !exists(json, 'checkout_longitude') ? undefined : json['checkout_longitude'],
    };
}

export function CheckOutRequestToJSON(value?: CheckOutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checked_out': value.checkedOut === undefined ? undefined : (value.checkedOut.toISOString()),
        'checkout_latitude': value.checkoutLatitude,
        'checkout_longitude': value.checkoutLongitude,
    };
}

