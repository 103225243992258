/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddContactPersonRequest,
  AutoCompleteAddress200Response,
  CardUpdateDTO,
  CardsDeleteMany200Response,
  CardsDeleteManyRequest,
  ChangePasswordRequest,
  ChangeStatusRequest,
  CheckInRequest,
  CheckOutRequest,
  Contact,
  ControlPanelApiProjectFeedResponseDTO,
  CreateCardAndTask201Response,
  CreateCardAndTaskRequest,
  CreateContactRequest,
  CreateFormRequest,
  CreateInvoiceRequest,
  CreateLabelRequest,
  CreateProductRequest,
  CreateProjectCustomFieldAttributeRequest,
  CreateTextTemplateRequest,
  CreateUserRequest,
  CvrSearchByName200Response,
  DeleteCard200Response,
  DeleteContact200Response,
  DeleteProductVariantsRequest,
  EditCard200Response,
  EditContact200Response,
  EditContactPersonRequest,
  EditInvoiceRequest,
  EditOfferRequest,
  EditSubscriptionRequest,
  EditTextTemplateRequest,
  EditUserRequest,
  ExpenseLinesList200Response,
  ExpenseLinesView200Response,
  ExpensesList200Response,
  ExpensesView200Response,
  FormTemplateView200Response,
  FormTemplatesList200Response,
  FormView200Response,
  FormsList200Response,
  GatherOfferLines200Response,
  GetAllRegistrations200Response,
  GetCard200Response,
  GetCards200Response,
  GetCardsWithTaskAndUsers200Response,
  GetCities200Response,
  GetCity200Response,
  GetCompanies200Response,
  GetCompany200Response,
  GetContact200Response,
  GetContactPerson200Response,
  GetContactPersonsList200Response,
  GetContacts200Response,
  GetCountries200Response,
  GetEmailAction200Response,
  GetFeatures200Response,
  GetIntegrationTypes200Response,
  GetInvoiceActivityLog200Response,
  GetInvoiceEmails200Response,
  GetInvoices200Response,
  GetLabel200Response,
  GetLabels200Response,
  GetLanguages200Response,
  GetMe200Response,
  GetOfferActions200Response,
  GetOfferFiles200Response,
  GetOfferLine200Response,
  GetOfferLines200Response,
  GetOfferPdf200Response,
  GetPaymentTerms200Response,
  GetProductCustomFieldAttribute200Response,
  GetProject200Response,
  GetProjectCustomFieldAttributes200Response,
  GetProjects200Response,
  GetTask200Response,
  GetTaskProduct200Response,
  GetTaskProducts200Response,
  GetTextTemplate200Response,
  GetTextTemplates200Response,
  GetTimeEntryRuleGroups200Response,
  GetUsers200Response,
  GetVendor200Response,
  GetVendors200Response,
  GetWallPost200Response,
  GetWallPosts200Response,
  GetWallPosts404Response,
  HasIntegrationOfType200Response,
  ICreateProductBundleLineRequest,
  ICreateProductBundleRequest,
  ICreateProjectRequest,
  ICreateWallCommentRequest,
  IDeleteCard200Response,
  IDeleteCardRequest,
  IEditWallCommentRequest,
  IEditWallPost200Response,
  IEditWallPostRequest,
  IFormUploadFiles200Response,
  IGetActivities200Response,
  IGetConsumption200Response,
  IGetFeed200Response,
  IGetFrontpageInvoiced200Response,
  IGetInvoice200Response,
  IGetOffersKpiCards200Response,
  IGetProject200Response,
  IGetTask200Response,
  IGetTasksForProject200Response,
  IGetWorkRelated200Response,
  IListInvoices200Response,
  IListOfferStatuses200Response,
  IListProjectStatuses200Response,
  IListProjects200Response,
  IListTasks200Response,
  IListWallComments200ResponseInner,
  IProductBundleSetSalesPrice200Response,
  IProductBundleSetSalesPriceRequest,
  IProductBundleUploadFile200Response,
  IProjectDeleteFileRequestInner,
  IProjectListFiles200Response,
  IProjectSendFilesRequest,
  ISendProjectForms200Response,
  ISendProjectFormsRequest,
  IStandardOfferFileDelete200Response,
  IStandardOfferFilesList200Response,
  IWallpostCreate201Response,
  IWallpostDeleteFile200Response,
  InvoicesView200Response,
  IsCheckedIn200Response,
  LoginWithEmail200Response,
  LoginWithEmailRequest,
  LookupCVR200Response,
  MergeProjectsRequest,
  OfferAcceptRequest,
  OfferAddProductBundleRequest,
  OfferLine,
  OffersList200Response,
  OffersView200Response,
  ProductBundleLineView200Response,
  ProductBundleLinesList200Response,
  ProductBundlesGetKpiCardsData200Response,
  ProductBundlesList200Response,
  ProductBundlesView200Response,
  ProductPriceHistory200Response,
  ProductVariants200Response,
  ProductsList200Response,
  ProductsView200Response,
  ProjectGetKpiCardEconomySubPage200Response,
  ProjectGetKpiCardHoursAllFormsSubPage200Response,
  ProjectGetKpiCardHoursPreCalculationSubPage200Response,
  ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200Response,
  ProjectGetKpiCardHoursSummedSubPage200Response,
  ProjectGetKpiCardInvoicesSubPage200Response,
  ProjectGetKpiCardProductsPreCalculationSubPage200Response,
  ProjectGetKpiCardProductsSubpageProductsTab200Response,
  ProjectGetKpiCardsData200Response,
  ReportTypes,
  RequestResetPassword200Response,
  RequestResetPassword422Response,
  RequestResetPasswordRequest,
  ResetPasswordRequest,
  ReverseLookup200Response,
  SendReportRequest,
  SetPrimaryVariantRequest,
  TaskCreateOrUpdateDTO,
  UpdateCardListing200Response,
  UpdateCardListingRequest,
  UpdateUserIntegrationSettingRequest,
  UsersGetKpiCards200Response,
  UsersGetSpecified200Response,
  UsersGetWeekly200Response,
  UsersPriceGroup,
  UsersPriceGroupsList200Response,
  UsersPriceGroupsView200Response,
  UsersView200Response,
  VendorProductView200Response,
  VendorProductsList200Response,
} from '../models';
import {
    AddContactPersonRequestFromJSON,
    AddContactPersonRequestToJSON,
    AutoCompleteAddress200ResponseFromJSON,
    AutoCompleteAddress200ResponseToJSON,
    CardUpdateDTOFromJSON,
    CardUpdateDTOToJSON,
    CardsDeleteMany200ResponseFromJSON,
    CardsDeleteMany200ResponseToJSON,
    CardsDeleteManyRequestFromJSON,
    CardsDeleteManyRequestToJSON,
    ChangePasswordRequestFromJSON,
    ChangePasswordRequestToJSON,
    ChangeStatusRequestFromJSON,
    ChangeStatusRequestToJSON,
    CheckInRequestFromJSON,
    CheckInRequestToJSON,
    CheckOutRequestFromJSON,
    CheckOutRequestToJSON,
    ContactFromJSON,
    ContactToJSON,
    ControlPanelApiProjectFeedResponseDTOFromJSON,
    ControlPanelApiProjectFeedResponseDTOToJSON,
    CreateCardAndTask201ResponseFromJSON,
    CreateCardAndTask201ResponseToJSON,
    CreateCardAndTaskRequestFromJSON,
    CreateCardAndTaskRequestToJSON,
    CreateContactRequestFromJSON,
    CreateContactRequestToJSON,
    CreateFormRequestFromJSON,
    CreateFormRequestToJSON,
    CreateInvoiceRequestFromJSON,
    CreateInvoiceRequestToJSON,
    CreateLabelRequestFromJSON,
    CreateLabelRequestToJSON,
    CreateProductRequestFromJSON,
    CreateProductRequestToJSON,
    CreateProjectCustomFieldAttributeRequestFromJSON,
    CreateProjectCustomFieldAttributeRequestToJSON,
    CreateTextTemplateRequestFromJSON,
    CreateTextTemplateRequestToJSON,
    CreateUserRequestFromJSON,
    CreateUserRequestToJSON,
    CvrSearchByName200ResponseFromJSON,
    CvrSearchByName200ResponseToJSON,
    DeleteCard200ResponseFromJSON,
    DeleteCard200ResponseToJSON,
    DeleteContact200ResponseFromJSON,
    DeleteContact200ResponseToJSON,
    DeleteProductVariantsRequestFromJSON,
    DeleteProductVariantsRequestToJSON,
    EditCard200ResponseFromJSON,
    EditCard200ResponseToJSON,
    EditContact200ResponseFromJSON,
    EditContact200ResponseToJSON,
    EditContactPersonRequestFromJSON,
    EditContactPersonRequestToJSON,
    EditInvoiceRequestFromJSON,
    EditInvoiceRequestToJSON,
    EditOfferRequestFromJSON,
    EditOfferRequestToJSON,
    EditSubscriptionRequestFromJSON,
    EditSubscriptionRequestToJSON,
    EditTextTemplateRequestFromJSON,
    EditTextTemplateRequestToJSON,
    EditUserRequestFromJSON,
    EditUserRequestToJSON,
    ExpenseLinesList200ResponseFromJSON,
    ExpenseLinesList200ResponseToJSON,
    ExpenseLinesView200ResponseFromJSON,
    ExpenseLinesView200ResponseToJSON,
    ExpensesList200ResponseFromJSON,
    ExpensesList200ResponseToJSON,
    ExpensesView200ResponseFromJSON,
    ExpensesView200ResponseToJSON,
    FormTemplateView200ResponseFromJSON,
    FormTemplateView200ResponseToJSON,
    FormTemplatesList200ResponseFromJSON,
    FormTemplatesList200ResponseToJSON,
    FormView200ResponseFromJSON,
    FormView200ResponseToJSON,
    FormsList200ResponseFromJSON,
    FormsList200ResponseToJSON,
    GatherOfferLines200ResponseFromJSON,
    GatherOfferLines200ResponseToJSON,
    GetAllRegistrations200ResponseFromJSON,
    GetAllRegistrations200ResponseToJSON,
    GetCard200ResponseFromJSON,
    GetCard200ResponseToJSON,
    GetCards200ResponseFromJSON,
    GetCards200ResponseToJSON,
    GetCardsWithTaskAndUsers200ResponseFromJSON,
    GetCardsWithTaskAndUsers200ResponseToJSON,
    GetCities200ResponseFromJSON,
    GetCities200ResponseToJSON,
    GetCity200ResponseFromJSON,
    GetCity200ResponseToJSON,
    GetCompanies200ResponseFromJSON,
    GetCompanies200ResponseToJSON,
    GetCompany200ResponseFromJSON,
    GetCompany200ResponseToJSON,
    GetContact200ResponseFromJSON,
    GetContact200ResponseToJSON,
    GetContactPerson200ResponseFromJSON,
    GetContactPerson200ResponseToJSON,
    GetContactPersonsList200ResponseFromJSON,
    GetContactPersonsList200ResponseToJSON,
    GetContacts200ResponseFromJSON,
    GetContacts200ResponseToJSON,
    GetCountries200ResponseFromJSON,
    GetCountries200ResponseToJSON,
    GetEmailAction200ResponseFromJSON,
    GetEmailAction200ResponseToJSON,
    GetFeatures200ResponseFromJSON,
    GetFeatures200ResponseToJSON,
    GetIntegrationTypes200ResponseFromJSON,
    GetIntegrationTypes200ResponseToJSON,
    GetInvoiceActivityLog200ResponseFromJSON,
    GetInvoiceActivityLog200ResponseToJSON,
    GetInvoiceEmails200ResponseFromJSON,
    GetInvoiceEmails200ResponseToJSON,
    GetInvoices200ResponseFromJSON,
    GetInvoices200ResponseToJSON,
    GetLabel200ResponseFromJSON,
    GetLabel200ResponseToJSON,
    GetLabels200ResponseFromJSON,
    GetLabels200ResponseToJSON,
    GetLanguages200ResponseFromJSON,
    GetLanguages200ResponseToJSON,
    GetMe200ResponseFromJSON,
    GetMe200ResponseToJSON,
    GetOfferActions200ResponseFromJSON,
    GetOfferActions200ResponseToJSON,
    GetOfferFiles200ResponseFromJSON,
    GetOfferFiles200ResponseToJSON,
    GetOfferLine200ResponseFromJSON,
    GetOfferLine200ResponseToJSON,
    GetOfferLines200ResponseFromJSON,
    GetOfferLines200ResponseToJSON,
    GetOfferPdf200ResponseFromJSON,
    GetOfferPdf200ResponseToJSON,
    GetPaymentTerms200ResponseFromJSON,
    GetPaymentTerms200ResponseToJSON,
    GetProductCustomFieldAttribute200ResponseFromJSON,
    GetProductCustomFieldAttribute200ResponseToJSON,
    GetProject200ResponseFromJSON,
    GetProject200ResponseToJSON,
    GetProjectCustomFieldAttributes200ResponseFromJSON,
    GetProjectCustomFieldAttributes200ResponseToJSON,
    GetProjects200ResponseFromJSON,
    GetProjects200ResponseToJSON,
    GetTask200ResponseFromJSON,
    GetTask200ResponseToJSON,
    GetTaskProduct200ResponseFromJSON,
    GetTaskProduct200ResponseToJSON,
    GetTaskProducts200ResponseFromJSON,
    GetTaskProducts200ResponseToJSON,
    GetTextTemplate200ResponseFromJSON,
    GetTextTemplate200ResponseToJSON,
    GetTextTemplates200ResponseFromJSON,
    GetTextTemplates200ResponseToJSON,
    GetTimeEntryRuleGroups200ResponseFromJSON,
    GetTimeEntryRuleGroups200ResponseToJSON,
    GetUsers200ResponseFromJSON,
    GetUsers200ResponseToJSON,
    GetVendor200ResponseFromJSON,
    GetVendor200ResponseToJSON,
    GetVendors200ResponseFromJSON,
    GetVendors200ResponseToJSON,
    GetWallPost200ResponseFromJSON,
    GetWallPost200ResponseToJSON,
    GetWallPosts200ResponseFromJSON,
    GetWallPosts200ResponseToJSON,
    GetWallPosts404ResponseFromJSON,
    GetWallPosts404ResponseToJSON,
    HasIntegrationOfType200ResponseFromJSON,
    HasIntegrationOfType200ResponseToJSON,
    ICreateProductBundleLineRequestFromJSON,
    ICreateProductBundleLineRequestToJSON,
    ICreateProductBundleRequestFromJSON,
    ICreateProductBundleRequestToJSON,
    ICreateProjectRequestFromJSON,
    ICreateProjectRequestToJSON,
    ICreateWallCommentRequestFromJSON,
    ICreateWallCommentRequestToJSON,
    IDeleteCard200ResponseFromJSON,
    IDeleteCard200ResponseToJSON,
    IDeleteCardRequestFromJSON,
    IDeleteCardRequestToJSON,
    IEditWallCommentRequestFromJSON,
    IEditWallCommentRequestToJSON,
    IEditWallPost200ResponseFromJSON,
    IEditWallPost200ResponseToJSON,
    IEditWallPostRequestFromJSON,
    IEditWallPostRequestToJSON,
    IFormUploadFiles200ResponseFromJSON,
    IFormUploadFiles200ResponseToJSON,
    IGetActivities200ResponseFromJSON,
    IGetActivities200ResponseToJSON,
    IGetConsumption200ResponseFromJSON,
    IGetConsumption200ResponseToJSON,
    IGetFeed200ResponseFromJSON,
    IGetFeed200ResponseToJSON,
    IGetFrontpageInvoiced200ResponseFromJSON,
    IGetFrontpageInvoiced200ResponseToJSON,
    IGetInvoice200ResponseFromJSON,
    IGetInvoice200ResponseToJSON,
    IGetOffersKpiCards200ResponseFromJSON,
    IGetOffersKpiCards200ResponseToJSON,
    IGetProject200ResponseFromJSON,
    IGetProject200ResponseToJSON,
    IGetTask200ResponseFromJSON,
    IGetTask200ResponseToJSON,
    IGetTasksForProject200ResponseFromJSON,
    IGetTasksForProject200ResponseToJSON,
    IGetWorkRelated200ResponseFromJSON,
    IGetWorkRelated200ResponseToJSON,
    IListInvoices200ResponseFromJSON,
    IListInvoices200ResponseToJSON,
    IListOfferStatuses200ResponseFromJSON,
    IListOfferStatuses200ResponseToJSON,
    IListProjectStatuses200ResponseFromJSON,
    IListProjectStatuses200ResponseToJSON,
    IListProjects200ResponseFromJSON,
    IListProjects200ResponseToJSON,
    IListTasks200ResponseFromJSON,
    IListTasks200ResponseToJSON,
    IListWallComments200ResponseInnerFromJSON,
    IListWallComments200ResponseInnerToJSON,
    IProductBundleSetSalesPrice200ResponseFromJSON,
    IProductBundleSetSalesPrice200ResponseToJSON,
    IProductBundleSetSalesPriceRequestFromJSON,
    IProductBundleSetSalesPriceRequestToJSON,
    IProductBundleUploadFile200ResponseFromJSON,
    IProductBundleUploadFile200ResponseToJSON,
    IProjectDeleteFileRequestInnerFromJSON,
    IProjectDeleteFileRequestInnerToJSON,
    IProjectListFiles200ResponseFromJSON,
    IProjectListFiles200ResponseToJSON,
    IProjectSendFilesRequestFromJSON,
    IProjectSendFilesRequestToJSON,
    ISendProjectForms200ResponseFromJSON,
    ISendProjectForms200ResponseToJSON,
    ISendProjectFormsRequestFromJSON,
    ISendProjectFormsRequestToJSON,
    IStandardOfferFileDelete200ResponseFromJSON,
    IStandardOfferFileDelete200ResponseToJSON,
    IStandardOfferFilesList200ResponseFromJSON,
    IStandardOfferFilesList200ResponseToJSON,
    IWallpostCreate201ResponseFromJSON,
    IWallpostCreate201ResponseToJSON,
    IWallpostDeleteFile200ResponseFromJSON,
    IWallpostDeleteFile200ResponseToJSON,
    InvoicesView200ResponseFromJSON,
    InvoicesView200ResponseToJSON,
    IsCheckedIn200ResponseFromJSON,
    IsCheckedIn200ResponseToJSON,
    LoginWithEmail200ResponseFromJSON,
    LoginWithEmail200ResponseToJSON,
    LoginWithEmailRequestFromJSON,
    LoginWithEmailRequestToJSON,
    LookupCVR200ResponseFromJSON,
    LookupCVR200ResponseToJSON,
    MergeProjectsRequestFromJSON,
    MergeProjectsRequestToJSON,
    OfferAcceptRequestFromJSON,
    OfferAcceptRequestToJSON,
    OfferAddProductBundleRequestFromJSON,
    OfferAddProductBundleRequestToJSON,
    OfferLineFromJSON,
    OfferLineToJSON,
    OffersList200ResponseFromJSON,
    OffersList200ResponseToJSON,
    OffersView200ResponseFromJSON,
    OffersView200ResponseToJSON,
    ProductBundleLineView200ResponseFromJSON,
    ProductBundleLineView200ResponseToJSON,
    ProductBundleLinesList200ResponseFromJSON,
    ProductBundleLinesList200ResponseToJSON,
    ProductBundlesGetKpiCardsData200ResponseFromJSON,
    ProductBundlesGetKpiCardsData200ResponseToJSON,
    ProductBundlesList200ResponseFromJSON,
    ProductBundlesList200ResponseToJSON,
    ProductBundlesView200ResponseFromJSON,
    ProductBundlesView200ResponseToJSON,
    ProductPriceHistory200ResponseFromJSON,
    ProductPriceHistory200ResponseToJSON,
    ProductVariants200ResponseFromJSON,
    ProductVariants200ResponseToJSON,
    ProductsList200ResponseFromJSON,
    ProductsList200ResponseToJSON,
    ProductsView200ResponseFromJSON,
    ProductsView200ResponseToJSON,
    ProjectGetKpiCardEconomySubPage200ResponseFromJSON,
    ProjectGetKpiCardEconomySubPage200ResponseToJSON,
    ProjectGetKpiCardHoursAllFormsSubPage200ResponseFromJSON,
    ProjectGetKpiCardHoursAllFormsSubPage200ResponseToJSON,
    ProjectGetKpiCardHoursPreCalculationSubPage200ResponseFromJSON,
    ProjectGetKpiCardHoursPreCalculationSubPage200ResponseToJSON,
    ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseFromJSON,
    ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseToJSON,
    ProjectGetKpiCardHoursSummedSubPage200ResponseFromJSON,
    ProjectGetKpiCardHoursSummedSubPage200ResponseToJSON,
    ProjectGetKpiCardInvoicesSubPage200ResponseFromJSON,
    ProjectGetKpiCardInvoicesSubPage200ResponseToJSON,
    ProjectGetKpiCardProductsPreCalculationSubPage200ResponseFromJSON,
    ProjectGetKpiCardProductsPreCalculationSubPage200ResponseToJSON,
    ProjectGetKpiCardProductsSubpageProductsTab200ResponseFromJSON,
    ProjectGetKpiCardProductsSubpageProductsTab200ResponseToJSON,
    ProjectGetKpiCardsData200ResponseFromJSON,
    ProjectGetKpiCardsData200ResponseToJSON,
    ReportTypesFromJSON,
    ReportTypesToJSON,
    RequestResetPassword200ResponseFromJSON,
    RequestResetPassword200ResponseToJSON,
    RequestResetPassword422ResponseFromJSON,
    RequestResetPassword422ResponseToJSON,
    RequestResetPasswordRequestFromJSON,
    RequestResetPasswordRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    ReverseLookup200ResponseFromJSON,
    ReverseLookup200ResponseToJSON,
    SendReportRequestFromJSON,
    SendReportRequestToJSON,
    SetPrimaryVariantRequestFromJSON,
    SetPrimaryVariantRequestToJSON,
    TaskCreateOrUpdateDTOFromJSON,
    TaskCreateOrUpdateDTOToJSON,
    UpdateCardListing200ResponseFromJSON,
    UpdateCardListing200ResponseToJSON,
    UpdateCardListingRequestFromJSON,
    UpdateCardListingRequestToJSON,
    UpdateUserIntegrationSettingRequestFromJSON,
    UpdateUserIntegrationSettingRequestToJSON,
    UsersGetKpiCards200ResponseFromJSON,
    UsersGetKpiCards200ResponseToJSON,
    UsersGetSpecified200ResponseFromJSON,
    UsersGetSpecified200ResponseToJSON,
    UsersGetWeekly200ResponseFromJSON,
    UsersGetWeekly200ResponseToJSON,
    UsersPriceGroupFromJSON,
    UsersPriceGroupToJSON,
    UsersPriceGroupsList200ResponseFromJSON,
    UsersPriceGroupsList200ResponseToJSON,
    UsersPriceGroupsView200ResponseFromJSON,
    UsersPriceGroupsView200ResponseToJSON,
    UsersView200ResponseFromJSON,
    UsersView200ResponseToJSON,
    VendorProductView200ResponseFromJSON,
    VendorProductView200ResponseToJSON,
    VendorProductsList200ResponseFromJSON,
    VendorProductsList200ResponseToJSON,
} from '../models';

export interface AddContactPersonOperationRequest {
    contactId: string;
    addContactPersonRequest?: AddContactPersonRequest;
}

export interface AddOfferLineRequest {
    offerId: string;
    offerLine?: OfferLine;
}

export interface AddTotalPriceLineRequest {
    offerId: string;
    offerLine: Array<OfferLine>;
}

export interface AutoCompleteAddressRequest {
    address: string;
}

export interface CardsDeleteManyOperationRequest {
    cardsDeleteManyRequest: CardsDeleteManyRequest;
}

export interface ChangePasswordOperationRequest {
    userId: string;
    changePasswordRequest?: ChangePasswordRequest;
}

export interface ChangeStatusOperationRequest {
    projectId: string;
    changeStatusRequest?: ChangeStatusRequest;
}

export interface CheckInOperationRequest {
    checkInRequest?: CheckInRequest;
}

export interface CheckOutOperationRequest {
    checkOutRequest?: CheckOutRequest;
}

export interface CreateCardRequest {
    cardUpdateDTO?: CardUpdateDTO;
}

export interface CreateCardAndTaskOperationRequest {
    createCardAndTaskRequest: CreateCardAndTaskRequest;
}

export interface CreateContactOperationRequest {
    createContactRequest?: CreateContactRequest;
}

export interface CreateFormOperationRequest {
    createFormRequest?: CreateFormRequest;
}

export interface CreateInvoiceOperationRequest {
    createInvoiceRequest?: CreateInvoiceRequest;
}

export interface CreateLabelOperationRequest {
    createLabelRequest?: CreateLabelRequest;
}

export interface CreateOfferRequest {
    editOfferRequest?: EditOfferRequest;
}

export interface CreateProductOperationRequest {
    createProductRequest?: CreateProductRequest;
}

export interface CreateProjectCustomFieldAttributeOperationRequest {
    createProjectCustomFieldAttributeRequest?: CreateProjectCustomFieldAttributeRequest;
}

export interface CreateTaskRequest {
    taskCreateOrUpdateDTO?: TaskCreateOrUpdateDTO;
}

export interface CreateTextTemplateOperationRequest {
    createTextTemplateRequest?: CreateTextTemplateRequest;
}

export interface CreateUserOperationRequest {
    createUserRequest?: CreateUserRequest;
}

export interface CvrSearchByNameRequest {
    companyName: string;
}

export interface DeleteCardRequest {
    cardId: string;
}

export interface DeleteContactRequest {
    contactId: string;
}

export interface DeleteContactPersonRequest {
    contactId: string;
    contactPersonId: string;
}

export interface DeleteExpenseRequest {
    expenseId: string;
}

export interface DeleteFormRequest {
    formId: string;
}

export interface DeleteInvoiceRequest {
    invoiceId: string;
}

export interface DeleteLabelRequest {
    labelId: string;
}

export interface DeleteOfferFileRequest {
    offerId: string;
    requestBody: Array<string>;
}

export interface DeleteOfferLineRequest {
    offerId: string;
    offerLineId: string;
}

export interface DeleteProductRequest {
    productId: string;
}

export interface DeleteProductVariantsOperationRequest {
    deleteProductVariantsRequest?: DeleteProductVariantsRequest;
}

export interface DeleteProjectCustomFieldAttributeRequest {
    projectCustomFieldAttributeId: string;
}

export interface DeleteTaskRequest {
    taskId: string;
}

export interface DeleteTextTemplateRequest {
    textTemplateId: string;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface DistributionHoursOverProductsRequest {
    offerId: string;
    offerLine: Array<OfferLine>;
}

export interface DuplicateFormRequest {
    formId: string;
}

export interface DuplicateInvoiceRequest {
    invoiceId: string;
    asCreditNote?: boolean;
}

export interface DuplicateOfferRequest {
    offerId: string;
}

export interface DuplicateProjectRequest {
    projectId: string;
}

export interface EditCardRequest {
    cardId: string;
    cardUpdateDTO?: CardUpdateDTO;
}

export interface EditContactRequest {
    contactId: string;
    contact?: Contact;
}

export interface EditContactPersonOperationRequest {
    contactId: string;
    contactPersonId: string;
    editContactPersonRequest?: EditContactPersonRequest;
}

export interface EditInvoiceOperationRequest {
    invoiceId: string;
    editInvoiceRequest?: EditInvoiceRequest;
}

export interface EditLabelRequest {
    labelId: string;
    createLabelRequest?: CreateLabelRequest;
}

export interface EditOfferOperationRequest {
    offerId: string;
    editOfferRequest?: EditOfferRequest;
}

export interface EditOfferLineRequest {
    offerId: string;
    offerLineId: string;
    offerLine?: OfferLine;
}

export interface EditProductRequest {
    productId: string;
    createProductRequest?: CreateProductRequest;
}

export interface EditProjectCustomFieldAttributeRequest {
    projectCustomFieldAttributeId: string;
    createProjectCustomFieldAttributeRequest?: CreateProjectCustomFieldAttributeRequest;
}

export interface EditSubscriptionOperationRequest {
    subscriptionId: string;
    editSubscriptionRequest?: EditSubscriptionRequest;
}

export interface EditTaskRequest {
    taskId: string;
    taskCreateOrUpdateDTO?: TaskCreateOrUpdateDTO;
}

export interface EditTextTemplateOperationRequest {
    textTemplateId: string;
    editTextTemplateRequest?: EditTextTemplateRequest;
}

export interface EditUserOperationRequest {
    userId: string;
    editUserRequest?: EditUserRequest;
}

export interface ExpenseLinesListRequest {
    page?: number;
    direction?: ExpenseLinesListDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
    vendorId?: string;
}

export interface ExpenseLinesViewRequest {
    expenseId: string;
}

export interface ExpensesListRequest {
    page?: number;
    direction?: ExpensesListDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
    vendorId?: string;
    projectId?: string;
    sentToEmail?: boolean;
    distributed?: boolean;
    deliveryOrCreatedBefore?: string;
    deliveryOrCreatedAfter?: string;
    activityIds?: Array<string>;
    isInvoiced?: boolean;
}

export interface ExpensesViewRequest {
    expenseId: string;
}

export interface FormTemplateViewRequest {
    formTemplateId: string;
    projectId?: string;
}

export interface FormTemplatesListRequest {
    ids?: Array<string>;
    page?: number;
    direction?: FormTemplatesListDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
}

export interface FormViewRequest {
    formId: string;
}

export interface FormsListRequest {
    page?: number;
    direction?: FormsListDirectionEnum;
    sort?: string;
    limit?: number;
    dateFrom?: Date;
    dateTo?: Date;
    projectId?: Array<string>;
    createdById?: string;
    userId?: string;
    formTemplateId?: Array<string>;
    isApproved?: boolean;
    isInvoicedFrom?: Date;
    isInvoicedTo?: Date;
    isNotInvoiced?: boolean;
    activities?: Array<string>;
    approved?: boolean;
    documentType?: string;
    activityIds?: Array<string>;
}

export interface GatherOfferLinesRequest {
    offerId: string;
    offerLine: Array<OfferLine>;
}

export interface GenerateSalaryFileRequest {
    dateFrom?: string;
    dateTo?: string;
}

export interface GetAllRegistrationsRequest {
    projectId: string;
    page?: number;
    direction?: GetAllRegistrationsDirectionEnum;
    sort?: string;
    limit?: number;
    dateFrom?: string;
    dateTo?: string;
    allUsers?: boolean;
    approved?: boolean;
    documentType?: string;
    activityIds?: Array<string>;
    userId?: string;
}

export interface GetCardRequest {
    cardId: string;
}

export interface GetCardsRequest {
    page?: number;
    direction?: GetCardsDirectionEnum;
    sort?: string;
    limit?: number;
    dateBefore?: Date;
    dateAfter?: Date;
}

export interface GetCardsWithTaskAndUsersRequest {
    dateBefore: Date;
    dateAfter: Date;
    userId?: string;
    projectId?: string;
    taskId?: string;
}

export interface GetCitiesRequest {
    zipCode?: string;
    name?: string;
    includeAll?: boolean;
}

export interface GetCityRequest {
    cityId: string;
}

export interface GetCompanyRequest {
    companyId: string;
}

export interface GetContactRequest {
    contactId: string;
    include?: string;
}

export interface GetContactPersonRequest {
    contactId: string;
    contactPersonId: string;
}

export interface GetContactPersonsListRequest {
    contactId: string;
    q?: string;
    createdGte?: Date;
    createdLte?: Date;
    ids?: Array<string>;
    page?: number;
    direction?: GetContactPersonsListDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface GetContactsRequest {
    q?: string;
    include?: string;
    page?: number;
    direction?: GetContactsDirectionEnum;
    sort?: string;
    limit?: number;
    ids?: Array<string>;
}

export interface GetEmailActionRequest {
    emailId: string;
}

export interface GetIntegrationTypesRequest {
    userId: string;
    q?: string;
}

export interface GetInvoiceActivityLogRequest {
    invoiceId: string;
}

export interface GetInvoiceEmailsRequest {
    invoiceId: string;
    page?: number;
    direction?: GetInvoiceEmailsDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface GetInvoicesRequest {
    include?: string;
    contactId?: string;
    page?: number;
    direction?: GetInvoicesDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
}

export interface GetLabelRequest {
    labelId: string;
}

export interface GetLabelsRequest {
    q?: string;
    page?: number;
    direction?: GetLabelsDirectionEnum;
    sort?: string;
    limit?: number;
    entity?: string;
}

export interface GetOfferActionsRequest {
    offerId: string;
}

export interface GetOfferEmailsRequest {
    offerId: string;
    page?: number;
    direction?: GetOfferEmailsDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface GetOfferFilesRequest {
    offerId: string;
    page?: number;
    direction?: GetOfferFilesDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface GetOfferLineRequest {
    offerId: string;
    offerLineId: string;
}

export interface GetOfferLinesRequest {
    offerId: string;
    applyRules?: boolean;
    token?: string;
    page?: number;
    direction?: GetOfferLinesDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
}

export interface GetOfferPdfRequest {
    offerId: string;
    page?: number;
    direction?: GetOfferPdfDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface GetProductCustomFieldAttributeRequest {
    projectCustomFieldAttributeId: string;
}

export interface GetProjectRequest {
    projectId: string;
}

export interface GetProjectCustomFieldAttributesRequest {
    page?: number;
    direction?: GetProjectCustomFieldAttributesDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface GetProjectsRequest {
    include?: string;
    contactId?: string;
    page?: number;
    direction?: GetProjectsDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
    showAll?: boolean;
}

export interface GetTaskRequest {
    taskId: string;
}

export interface GetTaskProductRequest {
    taskId: string;
    taskProductId: string;
}

export interface GetTaskProductsRequest {
    taskId: string;
}

export interface GetTasksRequest {
    page?: number;
    direction?: GetTasksDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
}

export interface GetTextTemplateRequest {
    textTemplateId: string;
    include?: string;
}

export interface GetTextTemplatesRequest {
    q?: string;
    include?: string;
    page?: number;
    direction?: GetTextTemplatesDirectionEnum;
    sort?: string;
    limit?: number;
    visible?: string;
}

export interface GetUsersRequest {
    page?: number;
    direction?: GetUsersDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
    ids?: Array<string>;
    isActive?: boolean;
}

export interface GetVendorRequest {
    vendorId: string;
    include?: string;
}

export interface GetVendorsRequest {
    q?: string;
    include?: string;
    page?: number;
    direction?: GetVendorsDirectionEnum;
    sort?: string;
    limit?: number;
    withPriceFile?: boolean;
    withExpense?: boolean;
}

export interface GetWallPostRequest {
    postId: string;
}

export interface GetWallPostsRequest {
    page?: number;
    direction?: GetWallPostsDirectionEnum;
    sort?: string;
    limit?: number;
    projectId?: string;
    userId?: string;
}

export interface GroupByProductAndHoursRequest {
    offerId: string;
    offerLine: Array<OfferLine>;
}

export interface HasIntegrationOfTypeRequest {
    identifier: HasIntegrationOfTypeIdentifierEnum;
}

export interface ICreateProductBundleOperationRequest {
    iCreateProductBundleRequest?: ICreateProductBundleRequest;
}

export interface ICreateProductBundleLineOperationRequest {
    productBundleId: string;
    iCreateProductBundleLineRequest?: ICreateProductBundleLineRequest;
}

export interface ICreateProjectOperationRequest {
    iCreateProjectRequest?: ICreateProjectRequest;
}

export interface ICreateUsersPriceGroupRequest {
    usersPriceGroup?: UsersPriceGroup;
}

export interface ICreateWallCommentOperationRequest {
    postId: string;
    iCreateWallCommentRequest: ICreateWallCommentRequest;
}

export interface IDeleteCardOperationRequest {
    cardId: string;
    iDeleteCardRequest?: IDeleteCardRequest;
}

export interface IDeleteOfferRequest {
    offerId: string;
}

export interface IDeleteProductBundleRequest {
    productBundleId: string;
}

export interface IDeleteProjectRequest {
    projectId: string;
}

export interface IDeleteWallCommentRequest {
    postId: string;
    commentId: string;
}

export interface IDeleteWallPostRequest {
    postId: string;
}

export interface IEditProductBundleRequest {
    productBundleId: string;
    iCreateProductBundleRequest?: ICreateProductBundleRequest;
}

export interface IEditProductBundleLineRequest {
    productBundleId: string;
    productBundleLineId: string;
    iCreateProductBundleLineRequest?: ICreateProductBundleLineRequest;
}

export interface IEditProjectRequest {
    projectId: string;
    iCreateProjectRequest?: ICreateProjectRequest;
}

export interface IEditUsersPriceGroupRequest {
    usersPriceGroupId: string;
    usersPriceGroup?: UsersPriceGroup;
}

export interface IEditWallCommentOperationRequest {
    postId: string;
    commentId: string;
    iEditWallCommentRequest?: IEditWallCommentRequest;
}

export interface IEditWallPostOperationRequest {
    postId: string;
    iEditWallPostRequest: IEditWallPostRequest;
}

export interface IFormUploadFilesRequest {
    files?: Array<Blob>;
}

export interface IGetActivitiesRequest {
    page?: number;
    direction?: IGetActivitiesDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface IGetFeedRequest {
    dateFrom?: Date;
    dateTo?: Date;
}

export interface IGetFeedForProjectRequest {
    projectId: string;
    page?: number;
    limit?: number;
    dateBefore?: Date;
    dateAfter?: Date;
    type?: Array<IGetFeedForProjectTypeEnum>;
}

export interface IGetInvoiceRequest {
    invoiceId: string;
}

export interface IGetOffersKpiCardsRequest {
    period?: number;
}

export interface IGetProjectRequest {
    projectId: string;
}

export interface IGetTaskRequest {
    taskId: string;
    include?: string;
}

export interface IGetTasksForProjectRequest {
    projectId: string;
    page?: number;
    direction?: IGetTasksForProjectDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
}

export interface IGetWallCommentRequest {
    postId: string;
    commentId: string;
}

export interface IInvoiceSendEmailRequest {
    invoiceId: string;
    recipientEmail: string;
    subject?: string;
    body?: string;
}

export interface IListInvoicesRequest {
    q?: string;
    page?: number;
    direction?: IListInvoicesDirectionEnum;
    sort?: string;
    limit?: number;
    contactId?: string;
    projectId?: string;
    invoiceNumber?: string;
    filter?: IListInvoicesFilterEnum;
    issuedDate?: Date;
    projectName?: string;
}

export interface IListOfferStatusesRequest {
    q?: string;
    identifier?: string;
}

export interface IListProjectStatusesRequest {
    q?: string;
    name?: string;
    identifier?: string;
    isCustom?: string;
}

export interface IListProjectsRequest {
    q?: string;
    page?: number;
    direction?: IListProjectsDirectionEnum;
    sort?: string;
    limit?: number;
    isOffer?: boolean;
    projectStatusId?: string;
    ids?: Array<string>;
    activityIds?: Array<string>;
    projectStatus?: IListProjectsProjectStatusEnum;
    contactId?: string;
    employeeIds?: Array<string>;
}

export interface IListTasksRequest {
    q?: string;
    page?: number;
    direction?: IListTasksDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface IListWallCommentsRequest {
    page?: number;
    direction?: IListWallCommentsDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface IOfferSendEmailRequest {
    offerId: string;
    recipientEmail?: string;
    subject?: string;
    body?: string;
    extraFiles?: Array<Blob>;
    pdf?: Blob;
}

export interface IOfferUploadFileRequest {
    offerId: string;
    files?: Array<Blob>;
}

export interface IProductBundleDuplicateRequest {
    productBundleId: string;
}

export interface IProductBundleSetSalesPriceOperationRequest {
    productBundleId: string;
    iProductBundleSetSalesPriceRequest?: IProductBundleSetSalesPriceRequest;
}

export interface IProductBundleUploadFileRequest {
    productBundleId: string;
    file?: Blob;
}

export interface IProjectDeleteFileRequest {
    projectId: string;
    iProjectDeleteFileRequestInner: Array<IProjectDeleteFileRequestInner>;
}

export interface IProjectListFilesRequest {
    projectId: string;
    page?: number;
    direction?: IProjectListFilesDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface IProjectSendFilesOperationRequest {
    projectId: string;
    iProjectSendFilesRequest: IProjectSendFilesRequest;
}

export interface IProjectUploadFileRequest {
    projectId: string;
    file?: Blob;
}

export interface ISendProjectFormsOperationRequest {
    projectId: string;
    iSendProjectFormsRequest: ISendProjectFormsRequest;
}

export interface IStandardOfferFileDeleteRequest {
    standardOfferFileId: string;
}

export interface IStandardOfferFileUploadRequest {
    file?: Blob;
    type?: IStandardOfferFileUploadTypeEnum;
}

export interface IStandardOfferFilesListRequest {
    q?: string;
    page?: number;
    direction?: IStandardOfferFilesListDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface IWallpostAttachFileRequest {
    postId: string;
    file?: Blob;
}

export interface IWallpostCreateRequest {
    projectId: string;
    message?: string;
    postFiles?: Array<Blob>;
}

export interface IWallpostDeleteFileRequest {
    postId: string;
    requestBody: Array<string>;
}

export interface InvoiceAddProductBundleRequest {
    invoiceId: string;
    offerAddProductBundleRequest?: OfferAddProductBundleRequest;
}

export interface InvoicesViewRequest {
    invoiceId: string;
}

export interface ListUsersRequest {
    page?: number;
    direction?: ListUsersDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
    ids?: Array<string>;
    isActive?: boolean;
    labels?: Array<string>;
}

export interface LoginWithEmailOperationRequest {
    loginWithEmailRequest: LoginWithEmailRequest;
}

export interface LookupCVRRequest {
    cvr?: string;
}

export interface MarkAsInvoicedRequest {
    projectId: string;
    dateFrom?: string;
    dateTo?: string;
    activityIds?: Array<string>;
    userId?: string;
}

export interface MergeProjectsOperationRequest {
    mergeProjectsRequest?: MergeProjectsRequest;
}

export interface OfferAcceptOperationRequest {
    token?: string;
    offerAcceptRequest?: OfferAcceptRequest;
}

export interface OfferAddProductBundleOperationRequest {
    offerId: string;
    offerAddProductBundleRequest?: OfferAddProductBundleRequest;
}

export interface OfferRejectRequest {
    token?: string;
    offerAcceptRequest?: OfferAcceptRequest;
}

export interface OffersListRequest {
    page?: number;
    direction?: OffersListDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
    offerStatusId?: string;
}

export interface OffersViewRequest {
    offerId: string;
    token?: string;
}

export interface ProductBundleLineViewRequest {
    productBundleId: string;
    productBundleLineId: string;
}

export interface ProductBundleLinesListRequest {
    productBundleId: string;
    page?: number;
    direction?: ProductBundleLinesListDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
}

export interface ProductBundlesGetKpiCardsDataRequest {
    productBundleId: string;
}

export interface ProductBundlesListRequest {
    page?: number;
    direction?: ProductBundlesListDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
    activityIds?: string;
}

export interface ProductBundlesViewRequest {
    productBundleId: string;
}

export interface ProductPriceHistoryRequest {
    expenseLineId?: string;
    vendorProductId?: string;
}

export interface ProductVariantsRequest {
    productId: string;
}

export interface ProductsListRequest {
    page?: number;
    direction?: ProductsListDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
}

export interface ProductsViewRequest {
    productId: string;
    includeVariants?: boolean;
}

export interface ProjectGetKpiCardEconomySubPageRequest {
    projectId: string;
    dateBefore?: string;
    dateAfter?: string;
}

export interface ProjectGetKpiCardHoursAllFormsSubPageRequest {
    projectId: string;
    sort?: string;
    direction?: ProjectGetKpiCardHoursAllFormsSubPageDirectionEnum;
    dateBefore?: string;
    dateAfter?: string;
    page?: number;
    activityIds?: Array<string>;
    isInvoiced?: boolean;
}

export interface ProjectGetKpiCardHoursPreCalculationSubPageRequest {
    projectId: string;
}

export interface ProjectGetKpiCardHoursSummedByUserPriceGroupSubPageRequest {
    projectId: string;
    dateBefore?: string;
    dateAfter?: string;
    activityIds?: Array<string>;
    isInvoiced?: boolean;
}

export interface ProjectGetKpiCardHoursSummedSubPageRequest {
    projectId: string;
    dateBefore?: Date;
    dateAfter?: Date;
}

export interface ProjectGetKpiCardInvoicesSubPageRequest {
    projectId: string;
    dateBefore?: string;
    dateAfter?: string;
    page?: number;
    sort?: string;
    direction?: ProjectGetKpiCardInvoicesSubPageDirectionEnum;
}

export interface ProjectGetKpiCardProductsPreCalculationSubPageRequest {
    projectId: string;
    sort?: string;
    direction?: ProjectGetKpiCardProductsPreCalculationSubPageDirectionEnum;
}

export interface ProjectGetKpiCardProductsSubpageProductsTabRequest {
    projectId: string;
    dateBefore?: string;
    dateAfter?: string;
    activityIds?: Array<string>;
    isInvoiced?: boolean;
    sort?: string;
    direction?: ProjectGetKpiCardProductsSubpageProductsTabDirectionEnum;
}

export interface ProjectGetKpiCardsDataRequest {
    projectId: string;
    dateBefore?: string;
    dateAfter?: string;
    activityIds?: Array<string>;
    isInvoiced?: boolean;
}

export interface RequestResetPasswordOperationRequest {
    requestResetPasswordRequest: RequestResetPasswordRequest;
}

export interface ResendWelcomeSmsRequest {
    userId: string;
}

export interface ResetPasswordOperationRequest {
    resetPasswordRequest?: ResetPasswordRequest;
}

export interface ReverseLookupRequest {
    latitude: number;
    longitude: number;
}

export interface SendReportOperationRequest {
    type: ReportTypes;
    sendReportRequest?: SendReportRequest;
}

export interface SetPrimaryVariantOperationRequest {
    setPrimaryVariantRequest?: SetPrimaryVariantRequest;
}

export interface ShowOnlyProductBundlesRequest {
    offerId: string;
    offerLine: Array<OfferLine>;
}

export interface UndeleteFormRequest {
    projectId: string;
    formId: string;
}

export interface UpdateCardListingOperationRequest {
    cardId: string;
    updateCardListingRequest?: UpdateCardListingRequest;
}

export interface UpdateFormRequest {
    formId: string;
    createFormRequest?: CreateFormRequest;
}

export interface UpdateUserIntegrationSettingOperationRequest {
    userId: string;
    updateUserIntegrationSettingRequest?: UpdateUserIntegrationSettingRequest;
}

export interface UploadUserImageRequest {
    userId: string;
    image?: Blob;
}

export interface UsersAccessToProjectRequest {
    projectId: string;
    requestBody?: { [key: string]: boolean; };
}

export interface UsersGetKpiCardsRequest {
    startDate?: string;
    endDate?: string;
    activityIds?: Array<string>;
    employeeIds?: Array<string>;
    active?: boolean;
}

export interface UsersGetSpecifiedRequest {
    sort?: string;
    direction?: UsersGetSpecifiedDirectionEnum;
    startDate?: string;
    endDate?: string;
    activityIds?: Array<string>;
    employeeIds?: Array<string>;
    approved?: boolean;
    invoiced?: boolean;
}

export interface UsersGetSummedRequest {
    startDate?: string;
    endDate?: string;
    employeeIds?: Array<string>;
    page?: number;
    direction?: UsersGetSummedDirectionEnum;
    sort?: string;
    limit?: number;
}

export interface UsersGetWeeklyRequest {
    startDate?: string;
    endDate?: string;
    employeeIds?: Array<string>;
}

export interface UsersPriceGroupsListRequest {
    page?: number;
    direction?: UsersPriceGroupsListDirectionEnum;
    sort?: string;
    limit?: number;
    q?: string;
}

export interface UsersPriceGroupsViewRequest {
    usersPriceGroupId: string;
}

export interface UsersViewRequest {
    userId: string;
}

export interface VendorProductViewRequest {
    vendorProductId: string;
}

export interface VendorProductsListRequest {
    page?: number;
    limit?: number;
    q?: string;
    vendorId?: string;
}

export interface ViewAsPDFRequest {
    projectId: string;
    dateFrom?: string;
    dateTo?: string;
    activityIds?: Array<string>;
}

export interface ViewUserRequest {
    userId: string;
}

export interface ZipCodeLookupRequest {
    zipCode: string;
    countryIdentifier?: string;
}

export interface ZipCodeSearchRequest {
    zipCode: string;
    countryIdentifier?: string;
}

/**
 * DefaultApi - interface
 * 
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Create contact person
     * @param {string} contactId 
     * @param {AddContactPersonRequest} [addContactPersonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    addContactPersonRaw(requestParameters: AddContactPersonOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create contact person
     */
    addContactPerson(requestParameters: AddContactPersonOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create offer line
     * @param {string} offerId 
     * @param {OfferLine} [offerLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    addOfferLineRaw(requestParameters: AddOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create offer line
     */
    addOfferLine(requestParameters: AddOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Hide prices on individual offer lines and add a total price line
     * @param {string} offerId 
     * @param {Array<OfferLine>} offerLine 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    addTotalPriceLineRaw(requestParameters: AddTotalPriceLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>>;

    /**
     * Hide prices on individual offer lines and add a total price line
     */
    addTotalPriceLine(requestParameters: AddTotalPriceLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response>;

    /**
     * 
     * @summary Search for address for autocomplete usage
     * @param {string} address The address to search for - url encoded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    autoCompleteAddressRaw(requestParameters: AutoCompleteAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoCompleteAddress200Response>>;

    /**
     * Search for address for autocomplete usage
     */
    autoCompleteAddress(requestParameters: AutoCompleteAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoCompleteAddress200Response>;

    /**
     * 
     * @summary Delete list of cards and re-index affected cards
     * @param {CardsDeleteManyRequest} cardsDeleteManyRequest IDs of cards to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    cardsDeleteManyRaw(requestParameters: CardsDeleteManyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>>;

    /**
     * Delete list of cards and re-index affected cards
     */
    cardsDeleteMany(requestParameters: CardsDeleteManyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response>;

    /**
     * 
     * @summary Change password to a specific user
     * @param {string} userId 
     * @param {ChangePasswordRequest} [changePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    changePasswordRaw(requestParameters: ChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>>;

    /**
     * Change password to a specific user
     */
    changePassword(requestParameters: ChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response>;

    /**
     * 
     * @summary Change status of the selected forms
     * @param {string} projectId 
     * @param {ChangeStatusRequest} [changeStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    changeStatusRaw(requestParameters: ChangeStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>>;

    /**
     * Change status of the selected forms
     */
    changeStatus(requestParameters: ChangeStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response>;

    /**
     * 
     * @summary Create(check in) clocking record
     * @param {CheckInRequest} [checkInRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    checkInRaw(requestParameters: CheckInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create(check in) clocking record
     */
    checkIn(requestParameters: CheckInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Check out clocking record
     * @param {CheckOutRequest} [checkOutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    checkOutRaw(requestParameters: CheckOutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Check out clocking record
     */
    checkOut(requestParameters: CheckOutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Create card
     * @param {CardUpdateDTO} [cardUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createCardRaw(requestParameters: CreateCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create card
     */
    createCard(requestParameters: CreateCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * Creates a task and card at the same time
     * @summary Create task and card
     * @param {CreateCardAndTaskRequest} createCardAndTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createCardAndTaskRaw(requestParameters: CreateCardAndTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCardAndTask201Response>>;

    /**
     * Creates a task and card at the same time
     * Create task and card
     */
    createCardAndTask(requestParameters: CreateCardAndTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCardAndTask201Response>;

    /**
     * 
     * @summary Create contact
     * @param {CreateContactRequest} [createContactRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createContactRaw(requestParameters: CreateContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create contact
     */
    createContact(requestParameters: CreateContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create form with form fields
     * @param {CreateFormRequest} [createFormRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createFormRaw(requestParameters: CreateFormOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create form with form fields
     */
    createForm(requestParameters: CreateFormOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create invoice
     * @param {CreateInvoiceRequest} [createInvoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createInvoiceRaw(requestParameters: CreateInvoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create invoice
     */
    createInvoice(requestParameters: CreateInvoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create label
     * @param {CreateLabelRequest} [createLabelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createLabelRaw(requestParameters: CreateLabelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create label
     */
    createLabel(requestParameters: CreateLabelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create offer
     * @param {EditOfferRequest} [editOfferRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createOfferRaw(requestParameters: CreateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create offer
     */
    createOffer(requestParameters: CreateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create product
     * @param {CreateProductRequest} [createProductRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createProductRaw(requestParameters: CreateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create product
     */
    createProduct(requestParameters: CreateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create project custom field attribute
     * @param {CreateProjectCustomFieldAttributeRequest} [createProjectCustomFieldAttributeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createProjectCustomFieldAttributeRaw(requestParameters: CreateProjectCustomFieldAttributeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create project custom field attribute
     */
    createProjectCustomFieldAttribute(requestParameters: CreateProjectCustomFieldAttributeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create task
     * @param {TaskCreateOrUpdateDTO} [taskCreateOrUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createTaskRaw(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create task
     */
    createTask(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create template
     * @param {CreateTextTemplateRequest} [createTextTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createTextTemplateRaw(requestParameters: CreateTextTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create template
     */
    createTextTemplate(requestParameters: CreateTextTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create user
     * @param {CreateUserRequest} [createUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createUserRaw(requestParameters: CreateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create user
     */
    createUser(requestParameters: CreateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @param {string} companyName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    cvrSearchByNameRaw(requestParameters: CvrSearchByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CvrSearchByName200Response>>;

    /**
     */
    cvrSearchByName(requestParameters: CvrSearchByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CvrSearchByName200Response>;

    /**
     * 
     * @summary Delete card
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteCardRaw(requestParameters: DeleteCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCard200Response>>;

    /**
     * Delete card
     */
    deleteCard(requestParameters: DeleteCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCard200Response>;

    /**
     * 
     * @summary Delete contact
     * @param {string} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteContactRaw(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteContact200Response>>;

    /**
     * Delete contact
     */
    deleteContact(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteContact200Response>;

    /**
     * 
     * @summary Delete contact person
     * @param {string} contactId 
     * @param {string} contactPersonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteContactPersonRaw(requestParameters: DeleteContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Delete contact person
     */
    deleteContactPerson(requestParameters: DeleteContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Delete expense
     * @param {string} expenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteExpenseRaw(requestParameters: DeleteExpenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete expense
     */
    deleteExpense(requestParameters: DeleteExpenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete form
     * @param {string} formId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteFormRaw(requestParameters: DeleteFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete form
     */
    deleteForm(requestParameters: DeleteFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete invoice
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteInvoiceRaw(requestParameters: DeleteInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete invoice
     */
    deleteInvoice(requestParameters: DeleteInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete label
     * @param {string} labelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteLabelRaw(requestParameters: DeleteLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteContact200Response>>;

    /**
     * Delete label
     */
    deleteLabel(requestParameters: DeleteLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteContact200Response>;

    /**
     * 
     * @summary Delete offer file
     * @param {string} offerId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteOfferFileRaw(requestParameters: DeleteOfferFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Delete offer file
     */
    deleteOfferFile(requestParameters: DeleteOfferFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Delete offer line
     * @param {string} offerId 
     * @param {string} offerLineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteOfferLineRaw(requestParameters: DeleteOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Delete offer line
     */
    deleteOfferLine(requestParameters: DeleteOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Delete product
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteProductRaw(requestParameters: DeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete product
     */
    deleteProduct(requestParameters: DeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete product variants
     * @param {DeleteProductVariantsRequest} [deleteProductVariantsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteProductVariantsRaw(requestParameters: DeleteProductVariantsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete product variants
     */
    deleteProductVariants(requestParameters: DeleteProductVariantsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete project custom field attribute
     * @param {string} projectCustomFieldAttributeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteProjectCustomFieldAttributeRaw(requestParameters: DeleteProjectCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteContact200Response>>;

    /**
     * Delete project custom field attribute
     */
    deleteProjectCustomFieldAttribute(requestParameters: DeleteProjectCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteContact200Response>;

    /**
     * 
     * @summary Delete task
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteTaskRaw(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>>;

    /**
     * Delete task
     */
    deleteTask(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response>;

    /**
     * 
     * @summary Delete text template
     * @param {string} textTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteTextTemplateRaw(requestParameters: DeleteTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete text template
     */
    deleteTextTemplate(requestParameters: DeleteTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>>;

    /**
     * Delete user
     */
    deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response>;

    /**
     * 
     * @summary Distribution Hours Over Products
     * @param {string} offerId 
     * @param {Array<OfferLine>} offerLine 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    distributionHoursOverProductsRaw(requestParameters: DistributionHoursOverProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>>;

    /**
     * Distribution Hours Over Products
     */
    distributionHoursOverProducts(requestParameters: DistributionHoursOverProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response>;

    /**
     * 
     * @summary Duplicate form
     * @param {string} formId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    duplicateFormRaw(requestParameters: DuplicateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Duplicate form
     */
    duplicateForm(requestParameters: DuplicateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Duplicate invoice
     * @param {string} invoiceId 
     * @param {boolean} [asCreditNote] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    duplicateInvoiceRaw(requestParameters: DuplicateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Duplicate invoice
     */
    duplicateInvoice(requestParameters: DuplicateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Duplicate offer
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    duplicateOfferRaw(requestParameters: DuplicateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Duplicate offer
     */
    duplicateOffer(requestParameters: DuplicateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Duplicate project
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    duplicateProjectRaw(requestParameters: DuplicateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Duplicate project
     */
    duplicateProject(requestParameters: DuplicateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Edit card
     * @param {string} cardId 
     * @param {CardUpdateDTO} [cardUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editCardRaw(requestParameters: EditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditCard200Response>>;

    /**
     * Edit card
     */
    editCard(requestParameters: EditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditCard200Response>;

    /**
     * 
     * @summary Edit contact
     * @param {string} contactId 
     * @param {Contact} [contact] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editContactRaw(requestParameters: EditContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Edit contact
     */
    editContact(requestParameters: EditContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary Edit contact person
     * @param {string} contactId 
     * @param {string} contactPersonId 
     * @param {EditContactPersonRequest} [editContactPersonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editContactPersonRaw(requestParameters: EditContactPersonOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Edit contact person
     */
    editContactPerson(requestParameters: EditContactPersonOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Edit invoice
     * @param {string} invoiceId 
     * @param {EditInvoiceRequest} [editInvoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editInvoiceRaw(requestParameters: EditInvoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Edit invoice
     */
    editInvoice(requestParameters: EditInvoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Edit label
     * @param {string} labelId 
     * @param {CreateLabelRequest} [createLabelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editLabelRaw(requestParameters: EditLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Edit label
     */
    editLabel(requestParameters: EditLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary Edit offer
     * @param {string} offerId 
     * @param {EditOfferRequest} [editOfferRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editOfferRaw(requestParameters: EditOfferOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Edit offer
     */
    editOffer(requestParameters: EditOfferOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary Edit offer line
     * @param {string} offerId 
     * @param {string} offerLineId 
     * @param {OfferLine} [offerLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editOfferLineRaw(requestParameters: EditOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Edit offer line
     */
    editOfferLine(requestParameters: EditOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Edit product
     * @param {string} productId 
     * @param {CreateProductRequest} [createProductRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editProductRaw(requestParameters: EditProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Edit product
     */
    editProduct(requestParameters: EditProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary Edit project custom field attribute
     * @param {string} projectCustomFieldAttributeId 
     * @param {CreateProjectCustomFieldAttributeRequest} [createProjectCustomFieldAttributeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editProjectCustomFieldAttributeRaw(requestParameters: EditProjectCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Edit project custom field attribute
     */
    editProjectCustomFieldAttribute(requestParameters: EditProjectCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary Edit subscription
     * @param {string} subscriptionId 
     * @param {EditSubscriptionRequest} [editSubscriptionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editSubscriptionRaw(requestParameters: EditSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Edit subscription
     */
    editSubscription(requestParameters: EditSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Edit task
     * @param {string} taskId 
     * @param {TaskCreateOrUpdateDTO} [taskCreateOrUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editTaskRaw(requestParameters: EditTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Edit task
     */
    editTask(requestParameters: EditTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Edit text template
     * @param {string} textTemplateId 
     * @param {EditTextTemplateRequest} [editTextTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editTextTemplateRaw(requestParameters: EditTextTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Edit text template
     */
    editTextTemplate(requestParameters: EditTextTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Edit user
     * @param {string} userId 
     * @param {EditUserRequest} [editUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    editUserRaw(requestParameters: EditUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Edit user
     */
    editUser(requestParameters: EditUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary List expense lines
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [vendorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    expenseLinesListRaw(requestParameters: ExpenseLinesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpenseLinesList200Response>>;

    /**
     * List expense lines
     */
    expenseLinesList(requestParameters: ExpenseLinesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpenseLinesList200Response>;

    /**
     * 
     * @summary View expense lines
     * @param {string} expenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    expenseLinesViewRaw(requestParameters: ExpenseLinesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpenseLinesView200Response>>;

    /**
     * View expense lines
     */
    expenseLinesView(requestParameters: ExpenseLinesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpenseLinesView200Response>;

    /**
     * 
     * @summary List expenses
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [vendorId] 
     * @param {string} [projectId] 
     * @param {boolean} [sentToEmail] 
     * @param {boolean} [distributed] 
     * @param {string} [deliveryOrCreatedBefore] 
     * @param {string} [deliveryOrCreatedAfter] 
     * @param {Array<string>} [activityIds] Filter by activities
     * @param {boolean} [isInvoiced] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    expensesListRaw(requestParameters: ExpensesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpensesList200Response>>;

    /**
     * List expenses
     */
    expensesList(requestParameters: ExpensesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpensesList200Response>;

    /**
     * 
     * @summary View expense
     * @param {string} expenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    expensesViewRaw(requestParameters: ExpensesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpensesView200Response>>;

    /**
     * View expense
     */
    expensesView(requestParameters: ExpensesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpensesView200Response>;

    /**
     * 
     * @summary View form template
     * @param {string} formTemplateId 
     * @param {string} [projectId] Include project to use as context when generating form. Used e.g. for default values based on project/contact/contact_person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    formTemplateViewRaw(requestParameters: FormTemplateViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormTemplateView200Response>>;

    /**
     * View form template
     */
    formTemplateView(requestParameters: FormTemplateViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormTemplateView200Response>;

    /**
     * 
     * @summary List form templates
     * @param {Array<string>} [ids] 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    formTemplatesListRaw(requestParameters: FormTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormTemplatesList200Response>>;

    /**
     * List form templates
     */
    formTemplatesList(requestParameters: FormTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormTemplatesList200Response>;

    /**
     * 
     * @summary View form
     * @param {string} formId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    formViewRaw(requestParameters: FormViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormView200Response>>;

    /**
     * View form
     */
    formView(requestParameters: FormViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormView200Response>;

    /**
     * 
     * @summary List forms
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {Date} [dateFrom] 
     * @param {Date} [dateTo] 
     * @param {Array<string>} [projectId] 
     * @param {string} [createdById] 
     * @param {string} [userId] 
     * @param {Array<string>} [formTemplateId] 
     * @param {boolean} [isApproved] 
     * @param {Date} [isInvoicedFrom] 
     * @param {Date} [isInvoicedTo] 
     * @param {boolean} [isNotInvoiced] 
     * @param {Array<string>} [activities] 
     * @param {boolean} [approved] 
     * @param {string} [documentType] Filter by document type(invoice, expense) or form_template_id
     * @param {Array<string>} [activityIds] Filter by activities (Limited to Forms, Expenses)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    formsListRaw(requestParameters: FormsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormsList200Response>>;

    /**
     * List forms
     */
    formsList(requestParameters: FormsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormsList200Response>;

    /**
     * 
     * @summary Gather offer line
     * @param {string} offerId 
     * @param {Array<OfferLine>} offerLine 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    gatherOfferLinesRaw(requestParameters: GatherOfferLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>>;

    /**
     * Gather offer line
     */
    gatherOfferLines(requestParameters: GatherOfferLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response>;

    /**
     * 
     * @summary Generate salary file
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    generateSalaryFileRaw(requestParameters: GenerateSalaryFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Generate salary file
     */
    generateSalaryFile(requestParameters: GenerateSalaryFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @summary Get registrations for project
     * @param {string} projectId 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {boolean} [allUsers] 
     * @param {boolean} [approved] 
     * @param {string} [documentType] Filter by document type(invoice, expense) or form_template_id
     * @param {Array<string>} [activityIds] Filter by activities (Limited to Forms, Expenses)
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getAllRegistrationsRaw(requestParameters: GetAllRegistrationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllRegistrations200Response>>;

    /**
     * Get registrations for project
     */
    getAllRegistrations(requestParameters: GetAllRegistrationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllRegistrations200Response>;

    /**
     * 
     * @summary View card
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCardRaw(requestParameters: GetCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCard200Response>>;

    /**
     * View card
     */
    getCard(requestParameters: GetCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCard200Response>;

    /**
     * 
     * @summary List cards
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {Date} [dateBefore] Only show cards with a date before this
     * @param {Date} [dateAfter] Only show cards with a date after this
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCardsRaw(requestParameters: GetCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCards200Response>>;

    /**
     * List cards
     */
    getCards(requestParameters: GetCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCards200Response>;

    /**
     * 
     * @summary List cards
     * @param {Date} dateBefore Only show cards with a date before this
     * @param {Date} dateAfter Only show cards with a date after this
     * @param {string} [userId] Only show cards for specific user
     * @param {string} [projectId] Only show cards for specific project
     * @param {string} [taskId] Only show cards for specific task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCardsWithTaskAndUsersRaw(requestParameters: GetCardsWithTaskAndUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCardsWithTaskAndUsers200Response>>;

    /**
     * List cards
     */
    getCardsWithTaskAndUsers(requestParameters: GetCardsWithTaskAndUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCardsWithTaskAndUsers200Response>;

    /**
     * 
     * @summary List cities
     * @param {string} [zipCode] Used to search for a city with specific zip code
     * @param {string} [name] Used to search for a city by name
     * @param {boolean} [includeAll] Used to search for a city without filtering by country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCitiesRaw(requestParameters: GetCitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCities200Response>>;

    /**
     * List cities
     */
    getCities(requestParameters: GetCitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCities200Response>;

    /**
     * 
     * @summary View city
     * @param {string} cityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCityRaw(requestParameters: GetCityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCity200Response>>;

    /**
     * View city
     */
    getCity(requestParameters: GetCityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCity200Response>;

    /**
     * 
     * @summary List companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompanies200Response>>;

    /**
     * List companies
     */
    getCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompanies200Response>;

    /**
     * 
     * @summary View company
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompany200Response>>;

    /**
     * View company
     */
    getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompany200Response>;

    /**
     * 
     * @summary View contact
     * @param {string} contactId 
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getContactRaw(requestParameters: GetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContact200Response>>;

    /**
     * View contact
     */
    getContact(requestParameters: GetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContact200Response>;

    /**
     * 
     * @summary View contact person
     * @param {string} contactId 
     * @param {string} contactPersonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getContactPersonRaw(requestParameters: GetContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContactPerson200Response>>;

    /**
     * View contact person
     */
    getContactPerson(requestParameters: GetContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContactPerson200Response>;

    /**
     * Get a list of contact people associated with a contact
     * @summary List contact people for contact
     * @param {string} contactId 
     * @param {string} [q] 
     * @param {Date} [createdGte] 
     * @param {Date} [createdLte] 
     * @param {Array<string>} [ids] 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getContactPersonsListRaw(requestParameters: GetContactPersonsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContactPersonsList200Response>>;

    /**
     * Get a list of contact people associated with a contact
     * List contact people for contact
     */
    getContactPersonsList(requestParameters: GetContactPersonsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContactPersonsList200Response>;

    /**
     * 
     * @summary List contacts
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {Array<string>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getContactsRaw(requestParameters: GetContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContacts200Response>>;

    /**
     * List contacts
     */
    getContacts(requestParameters: GetContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContacts200Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCountriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCountries200Response>>;

    /**
     */
    getCountries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCountries200Response>;

    /**
     * Get a list of email actions for specific email
     * @summary List actions for email
     * @param {string} emailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getEmailActionRaw(requestParameters: GetEmailActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailAction200Response>>;

    /**
     * Get a list of email actions for specific email
     * List actions for email
     */
    getEmailAction(requestParameters: GetEmailActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailAction200Response>;

    /**
     * Lists available features for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getFeaturesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFeatures200Response>>;

    /**
     * Lists available features for the current user
     */
    getFeatures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFeatures200Response>;

    /**
     * Get a list of integration types associated with a user
     * @summary Return user integration types
     * @param {string} userId 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getIntegrationTypesRaw(requestParameters: GetIntegrationTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetIntegrationTypes200Response>>;

    /**
     * Get a list of integration types associated with a user
     * Return user integration types
     */
    getIntegrationTypes(requestParameters: GetIntegrationTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetIntegrationTypes200Response>;

    /**
     * 
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getInvoiceActivityLogRaw(requestParameters: GetInvoiceActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvoiceActivityLog200Response>>;

    /**
     */
    getInvoiceActivityLog(requestParameters: GetInvoiceActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvoiceActivityLog200Response>;

    /**
     * Get a list of invoice emails for specific invoice
     * @summary List invoice emails
     * @param {string} invoiceId 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getInvoiceEmailsRaw(requestParameters: GetInvoiceEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvoiceEmails200Response>>;

    /**
     * Get a list of invoice emails for specific invoice
     * List invoice emails
     */
    getInvoiceEmails(requestParameters: GetInvoiceEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvoiceEmails200Response>;

    /**
     * 
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {string} [contactId] Filter by contact_id
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getInvoicesRaw(requestParameters: GetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvoices200Response>>;

    /**
     */
    getInvoices(requestParameters: GetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvoices200Response>;

    /**
     * 
     * @summary View label
     * @param {string} labelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getLabelRaw(requestParameters: GetLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLabel200Response>>;

    /**
     * View label
     */
    getLabel(requestParameters: GetLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLabel200Response>;

    /**
     * 
     * @summary List labels
     * @param {string} [q] Magic param that searches across multiple places
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [entity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getLabelsRaw(requestParameters: GetLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLabels200Response>>;

    /**
     * List labels
     */
    getLabels(requestParameters: GetLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLabels200Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getLanguagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLanguages200Response>>;

    /**
     */
    getLanguages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLanguages200Response>;

    /**
     * 
     * @summary View currently authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMe200Response>>;

    /**
     * View currently authenticated user
     */
    getMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMe200Response>;

    /**
     * Get a list of offer actions for specific offer
     * @summary List offer actions for offer
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getOfferActionsRaw(requestParameters: GetOfferActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferActions200Response>>;

    /**
     * Get a list of offer actions for specific offer
     * List offer actions for offer
     */
    getOfferActions(requestParameters: GetOfferActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferActions200Response>;

    /**
     * Get a list of offer emails for specific offer
     * @summary List offer emails
     * @param {string} offerId 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getOfferEmailsRaw(requestParameters: GetOfferEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvoiceEmails200Response>>;

    /**
     * Get a list of offer emails for specific offer
     * List offer emails
     */
    getOfferEmails(requestParameters: GetOfferEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvoiceEmails200Response>;

    /**
     * Get a list of offer files for specific offer
     * @summary List offer files for offer
     * @param {string} offerId 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getOfferFilesRaw(requestParameters: GetOfferFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferFiles200Response>>;

    /**
     * Get a list of offer files for specific offer
     * List offer files for offer
     */
    getOfferFiles(requestParameters: GetOfferFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferFiles200Response>;

    /**
     * 
     * @summary View offer line
     * @param {string} offerId 
     * @param {string} offerLineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getOfferLineRaw(requestParameters: GetOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferLine200Response>>;

    /**
     * View offer line
     */
    getOfferLine(requestParameters: GetOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferLine200Response>;

    /**
     * Get a list of offer lines for specific offer
     * @summary List offer lines for offer
     * @param {string} offerId 
     * @param {boolean} [applyRules] 
     * @param {string} [token] 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getOfferLinesRaw(requestParameters: GetOfferLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferLines200Response>>;

    /**
     * Get a list of offer lines for specific offer
     * List offer lines for offer
     */
    getOfferLines(requestParameters: GetOfferLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferLines200Response>;

    /**
     * Get offer PDF for specific offer
     * @summary Get offer PDF for offer
     * @param {string} offerId 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getOfferPdfRaw(requestParameters: GetOfferPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferPdf200Response>>;

    /**
     * Get offer PDF for specific offer
     * Get offer PDF for offer
     */
    getOfferPdf(requestParameters: GetOfferPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferPdf200Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getPaymentTermsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentTerms200Response>>;

    /**
     */
    getPaymentTerms(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentTerms200Response>;

    /**
     * 
     * @summary View project custom field attribute
     * @param {string} projectCustomFieldAttributeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProductCustomFieldAttributeRaw(requestParameters: GetProductCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductCustomFieldAttribute200Response>>;

    /**
     * View project custom field attribute
     */
    getProductCustomFieldAttribute(requestParameters: GetProductCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductCustomFieldAttribute200Response>;

    /**
     * 
     * @summary View project
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProject200Response>>;

    /**
     * View project
     */
    getProject(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProject200Response>;

    /**
     * 
     * @summary List project custom field attributes
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProjectCustomFieldAttributesRaw(requestParameters: GetProjectCustomFieldAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectCustomFieldAttributes200Response>>;

    /**
     * List project custom field attributes
     */
    getProjectCustomFieldAttributes(requestParameters: GetProjectCustomFieldAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectCustomFieldAttributes200Response>;

    /**
     * 
     * @summary List projects
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {string} [contactId] Filter by contact_id
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {boolean} [showAll] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProjectsRaw(requestParameters: GetProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjects200Response>>;

    /**
     * List projects
     */
    getProjects(requestParameters: GetProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjects200Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getReleaseNotesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     */
    getReleaseNotes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * 
     * @summary View task
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTaskRaw(requestParameters: GetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTask200Response>>;

    /**
     * View task
     */
    getTask(requestParameters: GetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTask200Response>;

    /**
     * 
     * @param {string} taskId 
     * @param {string} taskProductId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTaskProductRaw(requestParameters: GetTaskProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTaskProduct200Response>>;

    /**
     */
    getTaskProduct(requestParameters: GetTaskProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTaskProduct200Response>;

    /**
     * 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTaskProductsRaw(requestParameters: GetTaskProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTaskProducts200Response>>;

    /**
     */
    getTaskProducts(requestParameters: GetTaskProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTaskProducts200Response>;

    /**
     * 
     * @summary List tasks
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTasksRaw(requestParameters: GetTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListTasks200Response>>;

    /**
     * List tasks
     */
    getTasks(requestParameters: GetTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListTasks200Response>;

    /**
     * 
     * @summary View text template
     * @param {string} textTemplateId 
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTextTemplateRaw(requestParameters: GetTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTextTemplate200Response>>;

    /**
     * View text template
     */
    getTextTemplate(requestParameters: GetTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTextTemplate200Response>;

    /**
     * 
     * @summary List text templates
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [visible] Comma separated entities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTextTemplatesRaw(requestParameters: GetTextTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTextTemplates200Response>>;

    /**
     * List text templates
     */
    getTextTemplates(requestParameters: GetTextTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTextTemplates200Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTimeEntryRuleGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTimeEntryRuleGroups200Response>>;

    /**
     */
    getTimeEntryRuleGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTimeEntryRuleGroups200Response>;

    /**
     * 
     * @summary List users
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {Array<string>} [ids] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getUsersRaw(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsers200Response>>;

    /**
     * List users
     */
    getUsers(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsers200Response>;

    /**
     * 
     * @summary View vendor
     * @param {string} vendorId 
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getVendorRaw(requestParameters: GetVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVendor200Response>>;

    /**
     * View vendor
     */
    getVendor(requestParameters: GetVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVendor200Response>;

    /**
     * 
     * @summary List vendors
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {boolean} [withPriceFile] 
     * @param {boolean} [withExpense] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getVendorsRaw(requestParameters: GetVendorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVendors200Response>>;

    /**
     * List vendors
     */
    getVendors(requestParameters: GetVendorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVendors200Response>;

    /**
     * 
     * @summary View wall post
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getWallPostRaw(requestParameters: GetWallPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWallPost200Response>>;

    /**
     * View wall post
     */
    getWallPost(requestParameters: GetWallPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWallPost200Response>;

    /**
     * 
     * @summary List wall posts
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [projectId] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getWallPostsRaw(requestParameters: GetWallPostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWallPosts200Response>>;

    /**
     * List wall posts
     */
    getWallPosts(requestParameters: GetWallPostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWallPosts200Response>;

    /**
     * 
     * @summary Group offer lines by product and hours
     * @param {string} offerId 
     * @param {Array<OfferLine>} offerLine 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    groupByProductAndHoursRaw(requestParameters: GroupByProductAndHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>>;

    /**
     * Group offer lines by product and hours
     */
    groupByProductAndHours(requestParameters: GroupByProductAndHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response>;

    /**
     * Company has certain integration type based on the identifier
     * @summary Check if company has integration by identifier
     * @param {'payroll_system' | 'roger' | 'erp'} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    hasIntegrationOfTypeRaw(requestParameters: HasIntegrationOfTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HasIntegrationOfType200Response>>;

    /**
     * Company has certain integration type based on the identifier
     * Check if company has integration by identifier
     */
    hasIntegrationOfType(requestParameters: HasIntegrationOfTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HasIntegrationOfType200Response>;

    /**
     * 
     * @summary Create product bundle
     * @param {ICreateProductBundleRequest} [iCreateProductBundleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iCreateProductBundleRaw(requestParameters: ICreateProductBundleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create product bundle
     */
    iCreateProductBundle(requestParameters: ICreateProductBundleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create product bundle line
     * @param {string} productBundleId 
     * @param {ICreateProductBundleLineRequest} [iCreateProductBundleLineRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iCreateProductBundleLineRaw(requestParameters: ICreateProductBundleLineOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create product bundle line
     */
    iCreateProductBundleLine(requestParameters: ICreateProductBundleLineOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create project
     * @param {ICreateProjectRequest} [iCreateProjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iCreateProjectRaw(requestParameters: ICreateProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create project
     */
    iCreateProject(requestParameters: ICreateProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create users price group
     * @param {UsersPriceGroup} [usersPriceGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iCreateUsersPriceGroupRaw(requestParameters: ICreateUsersPriceGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create users price group
     */
    iCreateUsersPriceGroup(requestParameters: ICreateUsersPriceGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @param {string} postId 
     * @param {ICreateWallCommentRequest} iCreateWallCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iCreateWallCommentRaw(requestParameters: ICreateWallCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     */
    iCreateWallComment(requestParameters: ICreateWallCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Delete card
     * @param {string} cardId 
     * @param {IDeleteCardRequest} [iDeleteCardRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iDeleteCardRaw(requestParameters: IDeleteCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IDeleteCard200Response>>;

    /**
     * Delete card
     */
    iDeleteCard(requestParameters: IDeleteCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IDeleteCard200Response>;

    /**
     * 
     * @summary Delete offer
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iDeleteOfferRaw(requestParameters: IDeleteOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete offer
     */
    iDeleteOffer(requestParameters: IDeleteOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete product bundle
     * @param {string} productBundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iDeleteProductBundleRaw(requestParameters: IDeleteProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete product bundle
     */
    iDeleteProductBundle(requestParameters: IDeleteProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete project
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iDeleteProjectRaw(requestParameters: IDeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete project
     */
    iDeleteProject(requestParameters: IDeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @param {string} postId 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iDeleteWallCommentRaw(requestParameters: IDeleteWallCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     */
    iDeleteWallComment(requestParameters: IDeleteWallCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * Delete wall post
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iDeleteWallPostRaw(requestParameters: IDeleteWallPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete wall post
     */
    iDeleteWallPost(requestParameters: IDeleteWallPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Edit product bundle
     * @param {string} productBundleId 
     * @param {ICreateProductBundleRequest} [iCreateProductBundleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iEditProductBundleRaw(requestParameters: IEditProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Edit product bundle
     */
    iEditProductBundle(requestParameters: IEditProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Edit product bundle line
     * @param {string} productBundleId 
     * @param {string} productBundleLineId 
     * @param {ICreateProductBundleLineRequest} [iCreateProductBundleLineRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iEditProductBundleLineRaw(requestParameters: IEditProductBundleLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Edit product bundle line
     */
    iEditProductBundleLine(requestParameters: IEditProductBundleLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Edit project
     * @param {string} projectId 
     * @param {ICreateProjectRequest} [iCreateProjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iEditProjectRaw(requestParameters: IEditProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Edit project
     */
    iEditProject(requestParameters: IEditProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Edit users price group
     * @param {string} usersPriceGroupId 
     * @param {UsersPriceGroup} [usersPriceGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iEditUsersPriceGroupRaw(requestParameters: IEditUsersPriceGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Edit users price group
     */
    iEditUsersPriceGroup(requestParameters: IEditUsersPriceGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @param {string} postId 
     * @param {string} commentId 
     * @param {IEditWallCommentRequest} [iEditWallCommentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iEditWallCommentRaw(requestParameters: IEditWallCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     */
    iEditWallComment(requestParameters: IEditWallCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * Update wall post
     * @param {string} postId 
     * @param {IEditWallPostRequest} iEditWallPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iEditWallPostRaw(requestParameters: IEditWallPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Update wall post
     */
    iEditWallPost(requestParameters: IEditWallPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Upload files for form
     * @param {Array<Blob>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iFormUploadFilesRaw(requestParameters: IFormUploadFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IFormUploadFiles200Response>>;

    /**
     * Upload files for form
     */
    iFormUploadFiles(requestParameters: IFormUploadFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IFormUploadFiles200Response>;

    /**
     * 
     * @summary List activities
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetActivitiesRaw(requestParameters: IGetActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetActivities200Response>>;

    /**
     * List activities
     */
    iGetActivities(requestParameters: IGetActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetActivities200Response>;

    /**
     * 
     * @summary Get total and weekly product, working hours cost consumption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetConsumptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetConsumption200Response>>;

    /**
     * Get total and weekly product, working hours cost consumption
     */
    iGetConsumption(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetConsumption200Response>;

    /**
     * 
     * @summary Get recent feed
     * @param {Date} [dateFrom] 
     * @param {Date} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetFeedRaw(requestParameters: IGetFeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetFeed200Response>>;

    /**
     * Get recent feed
     */
    iGetFeed(requestParameters: IGetFeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetFeed200Response>;

    /**
     * 
     * @summary Get feed for project
     * @param {string} projectId 
     * @param {number} [page] Page number
     * @param {number} [limit] Number of items per page
     * @param {Date} [dateBefore] 
     * @param {Date} [dateAfter] 
     * @param {Array<'form' | 'invoice' | 'expense' | 'wall_post' | 'project_file'>} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetFeedForProjectRaw(requestParameters: IGetFeedForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlPanelApiProjectFeedResponseDTO>>;

    /**
     * Get feed for project
     */
    iGetFeedForProject(requestParameters: IGetFeedForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlPanelApiProjectFeedResponseDTO>;

    /**
     * 
     * @summary Get total and weekly invoiced and not invoiced amounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetFrontpageInvoicedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetFrontpageInvoiced200Response>>;

    /**
     * Get total and weekly invoiced and not invoiced amounts
     */
    iGetFrontpageInvoiced(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetFrontpageInvoiced200Response>;

    /**
     * 
     * @summary Get invoice
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetInvoiceRaw(requestParameters: IGetInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetInvoice200Response>>;

    /**
     * Get invoice
     */
    iGetInvoice(requestParameters: IGetInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetInvoice200Response>;

    /**
     * 
     * @summary Return summed date grouped by offer statuses
     * @param {number} [period] Past days card data for offer statuses:[expired, accepted, rejected]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetOffersKpiCardsRaw(requestParameters: IGetOffersKpiCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetOffersKpiCards200Response>>;

    /**
     * Return summed date grouped by offer statuses
     */
    iGetOffersKpiCards(requestParameters: IGetOffersKpiCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetOffersKpiCards200Response>;

    /**
     * 
     * @summary Get project
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetProjectRaw(requestParameters: IGetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetProject200Response>>;

    /**
     * Get project
     */
    iGetProject(requestParameters: IGetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetProject200Response>;

    /**
     * 
     * @summary View task
     * @param {string} taskId 
     * @param {string} [include] Joins specified entities. E.g. \&quot;cities.countries\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetTaskRaw(requestParameters: IGetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetTask200Response>>;

    /**
     * View task
     */
    iGetTask(requestParameters: IGetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetTask200Response>;

    /**
     * 
     * @summary Get tasks for project
     * @param {string} projectId 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetTasksForProjectRaw(requestParameters: IGetTasksForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetTasksForProject200Response>>;

    /**
     * Get tasks for project
     */
    iGetTasksForProject(requestParameters: IGetTasksForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetTasksForProject200Response>;

    /**
     * 
     * @param {string} postId 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetWallCommentRaw(requestParameters: IGetWallCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListWallComments200ResponseInner>>;

    /**
     */
    iGetWallComment(requestParameters: IGetWallCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListWallComments200ResponseInner>;

    /**
     * 
     * @summary work related data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iGetWorkRelatedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetWorkRelated200Response>>;

    /**
     * work related data
     */
    iGetWorkRelated(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetWorkRelated200Response>;

    /**
     * 
     * @summary Send invoice email
     * @param {string} invoiceId 
     * @param {string} recipientEmail 
     * @param {string} [subject] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iInvoiceSendEmailRaw(requestParameters: IInvoiceSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>>;

    /**
     * Send invoice email
     */
    iInvoiceSendEmail(requestParameters: IInvoiceSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response>;

    /**
     * 
     * @summary List invoices
     * @param {string} [q] Magic param that searches across multiple places
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [contactId] Filter by contact_id
     * @param {string} [projectId] 
     * @param {string} [invoiceNumber] 
     * @param {'booked' | 'drafted' | 'booked_and_sent_as_draft' | 'sent_as_draft'} [filter] Filter by invoice status
     * @param {Date} [issuedDate] 
     * @param {string} [projectName] Filter by project name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iListInvoicesRaw(requestParameters: IListInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListInvoices200Response>>;

    /**
     * List invoices
     */
    iListInvoices(requestParameters: IListInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListInvoices200Response>;

    /**
     * 
     * @summary List Offer Statuses
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [identifier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iListOfferStatusesRaw(requestParameters: IListOfferStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListOfferStatuses200Response>>;

    /**
     * List Offer Statuses
     */
    iListOfferStatuses(requestParameters: IListOfferStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListOfferStatuses200Response>;

    /**
     * 
     * @summary List Project Statuses
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [name] 
     * @param {string} [identifier] 
     * @param {string} [isCustom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iListProjectStatusesRaw(requestParameters: IListProjectStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListProjectStatuses200Response>>;

    /**
     * List Project Statuses
     */
    iListProjectStatuses(requestParameters: IListProjectStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListProjectStatuses200Response>;

    /**
     * 
     * @summary List projects
     * @param {string} [q] Magic param that searches across multiple places
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {boolean} [isOffer] 
     * @param {string} [projectStatusId] 
     * @param {Array<string>} [ids] 
     * @param {Array<string>} [activityIds] 
     * @param {'open' | 'ready_for_billing' | 'closed'} [projectStatus] 
     * @param {string} [contactId] 
     * @param {Array<string>} [employeeIds] Array of User IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iListProjectsRaw(requestParameters: IListProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListProjects200Response>>;

    /**
     * List projects
     */
    iListProjects(requestParameters: IListProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListProjects200Response>;

    /**
     * 
     * @summary List tasks
     * @param {string} [q] Magic param that searches across multiple places
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iListTasksRaw(requestParameters: IListTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListTasks200Response>>;

    /**
     * List tasks
     */
    iListTasks(requestParameters: IListTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListTasks200Response>;

    /**
     * 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iListWallCommentsRaw(requestParameters: IListWallCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IListWallComments200ResponseInner>>>;

    /**
     */
    iListWallComments(requestParameters: IListWallCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IListWallComments200ResponseInner>>;

    /**
     * 
     * @summary Send offer email
     * @param {string} offerId 
     * @param {string} [recipientEmail] 
     * @param {string} [subject] 
     * @param {string} [body] 
     * @param {Array<Blob>} [extraFiles] 
     * @param {Blob} [pdf] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iOfferSendEmailRaw(requestParameters: IOfferSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>>;

    /**
     * Send offer email
     */
    iOfferSendEmail(requestParameters: IOfferSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response>;

    /**
     * 
     * @summary Upload offer file
     * @param {string} offerId 
     * @param {Array<Blob>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iOfferUploadFileRaw(requestParameters: IOfferUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>>;

    /**
     * Upload offer file
     */
    iOfferUploadFile(requestParameters: IOfferUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response>;

    /**
     * 
     * @summary Duplicate product bundle
     * @param {string} productBundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iProductBundleDuplicateRaw(requestParameters: IProductBundleDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>>;

    /**
     * Duplicate product bundle
     */
    iProductBundleDuplicate(requestParameters: IProductBundleDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response>;

    /**
     * 
     * @summary Set sales price based on contribution margin
     * @param {string} productBundleId 
     * @param {IProductBundleSetSalesPriceRequest} [iProductBundleSetSalesPriceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iProductBundleSetSalesPriceRaw(requestParameters: IProductBundleSetSalesPriceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleSetSalesPrice200Response>>;

    /**
     * Set sales price based on contribution margin
     */
    iProductBundleSetSalesPrice(requestParameters: IProductBundleSetSalesPriceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleSetSalesPrice200Response>;

    /**
     * 
     * @summary Upload a file to product bundle
     * @param {string} productBundleId 
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iProductBundleUploadFileRaw(requestParameters: IProductBundleUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>>;

    /**
     * Upload a file to product bundle
     */
    iProductBundleUploadFile(requestParameters: IProductBundleUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response>;

    /**
     * 
     * @param {string} projectId 
     * @param {Array<IProjectDeleteFileRequestInner>} iProjectDeleteFileRequestInner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iProjectDeleteFileRaw(requestParameters: IProjectDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     */
    iProjectDeleteFile(requestParameters: IProjectDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary List project files
     * @param {string} projectId 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iProjectListFilesRaw(requestParameters: IProjectListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProjectListFiles200Response>>;

    /**
     * List project files
     */
    iProjectListFiles(requestParameters: IProjectListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProjectListFiles200Response>;

    /**
     * Send one or more project files by email
     * @param {string} projectId 
     * @param {IProjectSendFilesRequest} iProjectSendFilesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iProjectSendFilesRaw(requestParameters: IProjectSendFilesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Send one or more project files by email
     */
    iProjectSendFiles(requestParameters: IProjectSendFilesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Upload a project file
     * @param {string} projectId 
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iProjectUploadFileRaw(requestParameters: IProjectUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>>;

    /**
     * Upload a project file
     */
    iProjectUploadFile(requestParameters: IProjectUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response>;

    /**
     * Send one or more project forms by email
     * @param {string} projectId 
     * @param {ISendProjectFormsRequest} iSendProjectFormsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iSendProjectFormsRaw(requestParameters: ISendProjectFormsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>>;

    /**
     * Send one or more project forms by email
     */
    iSendProjectForms(requestParameters: ISendProjectFormsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response>;

    /**
     * 
     * @param {string} standardOfferFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iStandardOfferFileDeleteRaw(requestParameters: IStandardOfferFileDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     */
    iStandardOfferFileDelete(requestParameters: IStandardOfferFileDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Upload standard offer file
     * @param {Blob} [file] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iStandardOfferFileUploadRaw(requestParameters: IStandardOfferFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>>;

    /**
     * Upload standard offer file
     */
    iStandardOfferFileUpload(requestParameters: IStandardOfferFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response>;

    /**
     * 
     * @summary List standard offer files
     * @param {string} [q] Magic param that searches across multiple places
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iStandardOfferFilesListRaw(requestParameters: IStandardOfferFilesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFilesList200Response>>;

    /**
     * List standard offer files
     */
    iStandardOfferFilesList(requestParameters: IStandardOfferFilesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFilesList200Response>;

    /**
     * 
     * @param {string} postId 
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iWallpostAttachFileRaw(requestParameters: IWallpostAttachFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     */
    iWallpostAttachFile(requestParameters: IWallpostAttachFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @summary Create wall post
     * @param {string} projectId 
     * @param {string} [message] 
     * @param {Array<Blob>} [postFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iWallpostCreateRaw(requestParameters: IWallpostCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Create wall post
     */
    iWallpostCreate(requestParameters: IWallpostCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * 
     * @param {string} postId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    iWallpostDeleteFileRaw(requestParameters: IWallpostDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostDeleteFile200Response>>;

    /**
     */
    iWallpostDeleteFile(requestParameters: IWallpostDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostDeleteFile200Response>;

    /**
     * 
     * @summary Add product bundle to invoice
     * @param {string} invoiceId 
     * @param {OfferAddProductBundleRequest} [offerAddProductBundleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    invoiceAddProductBundleRaw(requestParameters: InvoiceAddProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Add product bundle to invoice
     */
    invoiceAddProductBundle(requestParameters: InvoiceAddProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary View single invoice
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    invoicesViewRaw(requestParameters: InvoicesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesView200Response>>;

    /**
     * View single invoice
     */
    invoicesView(requestParameters: InvoicesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesView200Response>;

    /**
     * 
     * @summary Is current user checked in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    isCheckedInRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsCheckedIn200Response>>;

    /**
     * Is current user checked in
     */
    isCheckedIn(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsCheckedIn200Response>;

    /**
     * 
     * @summary List users
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {Array<string>} [ids] 
     * @param {boolean} [isActive] 
     * @param {Array<string>} [labels] Filter by labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    listUsersRaw(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsers200Response>>;

    /**
     * List users
     */
    listUsers(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsers200Response>;

    /**
     * 
     * @summary Log in with email/password
     * @param {LoginWithEmailRequest} loginWithEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginWithEmailRaw(requestParameters: LoginWithEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginWithEmail200Response>>;

    /**
     * Log in with email/password
     */
    loginWithEmail(requestParameters: LoginWithEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginWithEmail200Response>;

    /**
     * 
     * @param {string} [cvr] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    lookupCVRRaw(requestParameters: LookupCVRRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LookupCVR200Response>>;

    /**
     */
    lookupCVR(requestParameters: LookupCVRRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LookupCVR200Response>;

    /**
     * 
     * @summary Mark all registrations as invoiced
     * @param {string} projectId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {Array<string>} [activityIds] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    markAsInvoicedRaw(requestParameters: MarkAsInvoicedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Mark all registrations as invoiced
     */
    markAsInvoiced(requestParameters: MarkAsInvoicedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * Trigger background job to merge projects - can take a while depending on amount of content to move
     * @summary Merge projects
     * @param {MergeProjectsRequest} [mergeProjectsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    mergeProjectsRaw(requestParameters: MergeProjectsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Trigger background job to merge projects - can take a while depending on amount of content to move
     * Merge projects
     */
    mergeProjects(requestParameters: MergeProjectsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Accept offer
     * @param {string} [token] 
     * @param {OfferAcceptRequest} [offerAcceptRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    offerAcceptRaw(requestParameters: OfferAcceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Accept offer
     */
    offerAccept(requestParameters: OfferAcceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary Add product bundle to offer
     * @param {string} offerId 
     * @param {OfferAddProductBundleRequest} [offerAddProductBundleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    offerAddProductBundleRaw(requestParameters: OfferAddProductBundleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Add product bundle to offer
     */
    offerAddProductBundle(requestParameters: OfferAddProductBundleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary Reject offer
     * @param {string} [token] 
     * @param {OfferAcceptRequest} [offerAcceptRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    offerRejectRaw(requestParameters: OfferRejectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>>;

    /**
     * Reject offer
     */
    offerReject(requestParameters: OfferRejectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response>;

    /**
     * 
     * @summary List offers
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [offerStatusId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    offersListRaw(requestParameters: OffersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OffersList200Response>>;

    /**
     * List offers
     */
    offersList(requestParameters: OffersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OffersList200Response>;

    /**
     * 
     * @summary View single offer
     * @param {string} offerId 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    offersViewRaw(requestParameters: OffersViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OffersView200Response>>;

    /**
     * View single offer
     */
    offersView(requestParameters: OffersViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OffersView200Response>;

    /**
     * 
     * @summary View productBundleLine
     * @param {string} productBundleId 
     * @param {string} productBundleLineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productBundleLineViewRaw(requestParameters: ProductBundleLineViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleLineView200Response>>;

    /**
     * View productBundleLine
     */
    productBundleLineView(requestParameters: ProductBundleLineViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleLineView200Response>;

    /**
     * 
     * @summary List product bundle lines
     * @param {string} productBundleId 
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productBundleLinesListRaw(requestParameters: ProductBundleLinesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleLinesList200Response>>;

    /**
     * List product bundle lines
     */
    productBundleLinesList(requestParameters: ProductBundleLinesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleLinesList200Response>;

    /**
     * 
     * @summary Get numbers for the product bundle KPI cards
     * @param {string} productBundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productBundlesGetKpiCardsDataRaw(requestParameters: ProductBundlesGetKpiCardsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundlesGetKpiCardsData200Response>>;

    /**
     * Get numbers for the product bundle KPI cards
     */
    productBundlesGetKpiCardsData(requestParameters: ProductBundlesGetKpiCardsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundlesGetKpiCardsData200Response>;

    /**
     * 
     * @summary List product bundles
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [activityIds] Filter product bundles by activities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productBundlesListRaw(requestParameters: ProductBundlesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundlesList200Response>>;

    /**
     * List product bundles
     */
    productBundlesList(requestParameters: ProductBundlesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundlesList200Response>;

    /**
     * 
     * @summary View productBundle
     * @param {string} productBundleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productBundlesViewRaw(requestParameters: ProductBundlesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundlesView200Response>>;

    /**
     * View productBundle
     */
    productBundlesView(requestParameters: ProductBundlesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundlesView200Response>;

    /**
     * 
     * @summary Get products(expense lines, vendor products) pricing history
     * @param {string} [expenseLineId] 
     * @param {string} [vendorProductId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productPriceHistoryRaw(requestParameters: ProductPriceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductPriceHistory200Response>>;

    /**
     * Get products(expense lines, vendor products) pricing history
     */
    productPriceHistory(requestParameters: ProductPriceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductPriceHistory200Response>;

    /**
     * 
     * @summary Get product variants
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productVariantsRaw(requestParameters: ProductVariantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductVariants200Response>>;

    /**
     * Get product variants
     */
    productVariants(requestParameters: ProductVariantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductVariants200Response>;

    /**
     * 
     * @summary List products
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsListRaw(requestParameters: ProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductsList200Response>>;

    /**
     * List products
     */
    productsList(requestParameters: ProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductsList200Response>;

    /**
     * 
     * @summary View product
     * @param {string} productId 
     * @param {boolean} [includeVariants] Include product variants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsViewRaw(requestParameters: ProductsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductsView200Response>>;

    /**
     * View product
     */
    productsView(requestParameters: ProductsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductsView200Response>;

    /**
     * 
     * @summary Get data for page behind \"Economy\" KPI card
     * @param {string} projectId 
     * @param {string} [dateBefore] 
     * @param {string} [dateAfter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardEconomySubPageRaw(requestParameters: ProjectGetKpiCardEconomySubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardEconomySubPage200Response>>;

    /**
     * Get data for page behind \"Economy\" KPI card
     */
    projectGetKpiCardEconomySubPage(requestParameters: ProjectGetKpiCardEconomySubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardEconomySubPage200Response>;

    /**
     * 
     * @summary Get data for hours KPI card page - all forms tab
     * @param {string} projectId 
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [dateBefore] 
     * @param {string} [dateAfter] 
     * @param {number} [page] 
     * @param {Array<string>} [activityIds] Filter by activities
     * @param {boolean} [isInvoiced] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardHoursAllFormsSubPageRaw(requestParameters: ProjectGetKpiCardHoursAllFormsSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursAllFormsSubPage200Response>>;

    /**
     * Get data for hours KPI card page - all forms tab
     */
    projectGetKpiCardHoursAllFormsSubPage(requestParameters: ProjectGetKpiCardHoursAllFormsSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursAllFormsSubPage200Response>;

    /**
     * 
     * @summary Get data for hours KPI card page - pre calculation tab
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardHoursPreCalculationSubPageRaw(requestParameters: ProjectGetKpiCardHoursPreCalculationSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursPreCalculationSubPage200Response>>;

    /**
     * Get data for hours KPI card page - pre calculation tab
     */
    projectGetKpiCardHoursPreCalculationSubPage(requestParameters: ProjectGetKpiCardHoursPreCalculationSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursPreCalculationSubPage200Response>;

    /**
     * 
     * @summary Get data for hours KPI card page - `Summed by User price group` tab
     * @param {string} projectId 
     * @param {string} [dateBefore] 
     * @param {string} [dateAfter] 
     * @param {Array<string>} [activityIds] Filter by activities
     * @param {boolean} [isInvoiced] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardHoursSummedByUserPriceGroupSubPageRaw(requestParameters: ProjectGetKpiCardHoursSummedByUserPriceGroupSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200Response>>;

    /**
     * Get data for hours KPI card page - `Summed by User price group` tab
     */
    projectGetKpiCardHoursSummedByUserPriceGroupSubPage(requestParameters: ProjectGetKpiCardHoursSummedByUserPriceGroupSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200Response>;

    /**
     * 
     * @summary Get data for hours KPI card page - summed tab
     * @param {string} projectId 
     * @param {Date} [dateBefore] 
     * @param {Date} [dateAfter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardHoursSummedSubPageRaw(requestParameters: ProjectGetKpiCardHoursSummedSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursSummedSubPage200Response>>;

    /**
     * Get data for hours KPI card page - summed tab
     */
    projectGetKpiCardHoursSummedSubPage(requestParameters: ProjectGetKpiCardHoursSummedSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursSummedSubPage200Response>;

    /**
     * 
     * @summary Get data for page behind \"Invoices\" KPI card
     * @param {string} projectId 
     * @param {string} [dateBefore] 
     * @param {string} [dateAfter] 
     * @param {number} [page] Page number
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardInvoicesSubPageRaw(requestParameters: ProjectGetKpiCardInvoicesSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardInvoicesSubPage200Response>>;

    /**
     * Get data for page behind \"Invoices\" KPI card
     */
    projectGetKpiCardInvoicesSubPage(requestParameters: ProjectGetKpiCardInvoicesSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardInvoicesSubPage200Response>;

    /**
     * 
     * @summary Get data for products KPI card page - pre calculation tab
     * @param {string} projectId 
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardProductsPreCalculationSubPageRaw(requestParameters: ProjectGetKpiCardProductsPreCalculationSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardProductsPreCalculationSubPage200Response>>;

    /**
     * Get data for products KPI card page - pre calculation tab
     */
    projectGetKpiCardProductsPreCalculationSubPage(requestParameters: ProjectGetKpiCardProductsPreCalculationSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardProductsPreCalculationSubPage200Response>;

    /**
     * 
     * @summary Get data for products KPI card page - products tab
     * @param {string} projectId 
     * @param {string} [dateBefore] 
     * @param {string} [dateAfter] 
     * @param {Array<string>} [activityIds] Filter by activities (Limited to Forms, Expenses)
     * @param {boolean} [isInvoiced] 
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardProductsSubpageProductsTabRaw(requestParameters: ProjectGetKpiCardProductsSubpageProductsTabRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardProductsSubpageProductsTab200Response>>;

    /**
     * Get data for products KPI card page - products tab
     */
    projectGetKpiCardProductsSubpageProductsTab(requestParameters: ProjectGetKpiCardProductsSubpageProductsTabRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardProductsSubpageProductsTab200Response>;

    /**
     * 
     * @summary Get numbers for the projet KPI cards
     * @param {string} projectId 
     * @param {string} [dateBefore] 
     * @param {string} [dateAfter] 
     * @param {Array<string>} [activityIds] 
     * @param {boolean} [isInvoiced] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    projectGetKpiCardsDataRaw(requestParameters: ProjectGetKpiCardsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardsData200Response>>;

    /**
     * Get numbers for the projet KPI cards
     */
    projectGetKpiCardsData(requestParameters: ProjectGetKpiCardsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardsData200Response>;

    /**
     * 
     * @summary Request a password reset with email/mobile
     * @param {RequestResetPasswordRequest} requestResetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    requestResetPasswordRaw(requestParameters: RequestResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RequestResetPassword200Response>>;

    /**
     * Request a password reset with email/mobile
     */
    requestResetPassword(requestParameters: RequestResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RequestResetPassword200Response>;

    /**
     * 
     * @summary Resend welcome sms to user if he have mobile country code and mobile
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resendWelcomeSmsRaw(requestParameters: ResendWelcomeSmsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>>;

    /**
     * Resend welcome sms to user if he have mobile country code and mobile
     */
    resendWelcomeSms(requestParameters: ResendWelcomeSmsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response>;

    /**
     * 
     * @summary Sends a password reset email
     * @param {ResetPasswordRequest} [resetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>>;

    /**
     * Sends a password reset email
     */
    resetPassword(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response>;

    /**
     * 
     * @summary Reverse address lookup
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    reverseLookupRaw(requestParameters: ReverseLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReverseLookup200Response>>;

    /**
     * Reverse address lookup
     */
    reverseLookup(requestParameters: ReverseLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReverseLookup200Response>;

    /**
     * 
     * @summary Send a summary report based on the type
     * @param {ReportTypes} type 
     * @param {SendReportRequest} [sendReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    sendReportRaw(requestParameters: SendReportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>>;

    /**
     * Send a summary report based on the type
     */
    sendReport(requestParameters: SendReportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response>;

    /**
     * 
     * @summary Set variant as primary
     * @param {SetPrimaryVariantRequest} [setPrimaryVariantRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    setPrimaryVariantRaw(requestParameters: SetPrimaryVariantOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Set variant as primary
     */
    setPrimaryVariant(requestParameters: SetPrimaryVariantOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Show Only Product Bundles
     * @param {string} offerId 
     * @param {Array<OfferLine>} offerLine 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    showOnlyProductBundlesRaw(requestParameters: ShowOnlyProductBundlesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>>;

    /**
     * Show Only Product Bundles
     */
    showOnlyProductBundles(requestParameters: ShowOnlyProductBundlesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response>;

    /**
     * 
     * @summary Undelete deleted form
     * @param {string} projectId 
     * @param {string} formId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    undeleteFormRaw(requestParameters: UndeleteFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>>;

    /**
     * Undelete deleted form
     */
    undeleteForm(requestParameters: UndeleteFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response>;

    /**
     * Update a cards list_placement and reindex other cards for same date if necessary
     * @summary Bulk-update cards
     * @param {string} cardId 
     * @param {UpdateCardListingRequest} [updateCardListingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateCardListingRaw(requestParameters: UpdateCardListingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCardListing200Response>>;

    /**
     * Update a cards list_placement and reindex other cards for same date if necessary
     * Bulk-update cards
     */
    updateCardListing(requestParameters: UpdateCardListingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCardListing200Response>;

    /**
     * Update a form with form fields
     * @summary update form
     * @param {string} formId 
     * @param {CreateFormRequest} [createFormRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateFormRaw(requestParameters: UpdateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>>;

    /**
     * Update a form with form fields
     * update form
     */
    updateForm(requestParameters: UpdateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response>;

    /**
     * Update a user - integration setting value
     * @summary Update user integration setting value
     * @param {string} userId 
     * @param {UpdateUserIntegrationSettingRequest} [updateUserIntegrationSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateUserIntegrationSettingRaw(requestParameters: UpdateUserIntegrationSettingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>>;

    /**
     * Update a user - integration setting value
     * Update user integration setting value
     */
    updateUserIntegrationSetting(requestParameters: UpdateUserIntegrationSettingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response>;

    /**
     * 
     * @summary Upload image to a specific user
     * @param {string} userId 
     * @param {Blob} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    uploadUserImageRaw(requestParameters: UploadUserImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Upload image to a specific user
     */
    uploadUserImage(requestParameters: UploadUserImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Allow/disallow employees to access project
     * @param {string} projectId 
     * @param {{ [key: string]: boolean; }} [requestBody] Map of user IDs with true/false value to allow/disallow access to project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersAccessToProjectRaw(requestParameters: UsersAccessToProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>>;

    /**
     * Allow/disallow employees to access project
     */
    usersAccessToProject(requestParameters: UsersAccessToProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response>;

    /**
     * 
     * @summary Get data for employees page
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {Array<string>} [activityIds] Filter by activities
     * @param {Array<string>} [employeeIds] Filter by employees
     * @param {boolean} [active] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersGetKpiCardsRaw(requestParameters: UsersGetKpiCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersGetKpiCards200Response>>;

    /**
     * Get data for employees page
     */
    usersGetKpiCards(requestParameters: UsersGetKpiCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersGetKpiCards200Response>;

    /**
     * 
     * @summary Get data for specified tab in employees page
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {Array<string>} [activityIds] Filter by activities
     * @param {Array<string>} [employeeIds] Filter by employees
     * @param {boolean} [approved] 
     * @param {boolean} [invoiced] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersGetSpecifiedRaw(requestParameters: UsersGetSpecifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersGetSpecified200Response>>;

    /**
     * Get data for specified tab in employees page
     */
    usersGetSpecified(requestParameters: UsersGetSpecifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersGetSpecified200Response>;

    /**
     * 
     * @summary Get data for employees - summed tab
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {Array<string>} [employeeIds] Filter by employees
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersGetSummedRaw(requestParameters: UsersGetSummedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursSummedSubPage200Response>>;

    /**
     * Get data for employees - summed tab
     */
    usersGetSummed(requestParameters: UsersGetSummedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursSummedSubPage200Response>;

    /**
     * 
     * @summary Get data for employees - weekly tab
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {Array<string>} [employeeIds] Filter by employees
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersGetWeeklyRaw(requestParameters: UsersGetWeeklyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersGetWeekly200Response>>;

    /**
     * Get data for employees - weekly tab
     */
    usersGetWeekly(requestParameters: UsersGetWeeklyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersGetWeekly200Response>;

    /**
     * 
     * @summary List users price groups
     * @param {number} [page] Page number
     * @param {'asc' | 'desc'} [direction] Direction of sorting
     * @param {string} [sort] Field to sort by. Validated by backend.
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersPriceGroupsListRaw(requestParameters: UsersPriceGroupsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersPriceGroupsList200Response>>;

    /**
     * List users price groups
     */
    usersPriceGroupsList(requestParameters: UsersPriceGroupsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersPriceGroupsList200Response>;

    /**
     * 
     * @summary View users price group
     * @param {string} usersPriceGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersPriceGroupsViewRaw(requestParameters: UsersPriceGroupsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersPriceGroupsView200Response>>;

    /**
     * View users price group
     */
    usersPriceGroupsView(requestParameters: UsersPriceGroupsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersPriceGroupsView200Response>;

    /**
     * 
     * @summary View user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersViewRaw(requestParameters: UsersViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersView200Response>>;

    /**
     * View user
     */
    usersView(requestParameters: UsersViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersView200Response>;

    /**
     * 
     * @summary View vendor product
     * @param {string} vendorProductId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    vendorProductViewRaw(requestParameters: VendorProductViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorProductView200Response>>;

    /**
     * View vendor product
     */
    vendorProductView(requestParameters: VendorProductViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorProductView200Response>;

    /**
     * 
     * @summary List vendor products
     * @param {number} [page] Page number
     * @param {number} [limit] Number of items per page
     * @param {string} [q] Magic param that searches across multiple places
     * @param {string} [vendorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    vendorProductsListRaw(requestParameters: VendorProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorProductsList200Response>>;

    /**
     * List vendor products
     */
    vendorProductsList(requestParameters: VendorProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorProductsList200Response>;

    /**
     * 
     * @summary View project as PDF
     * @param {string} projectId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {Array<string>} [activityIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    viewAsPDFRaw(requestParameters: ViewAsPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * View project as PDF
     */
    viewAsPDF(requestParameters: ViewAsPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @summary View user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    viewUserRaw(requestParameters: ViewUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersView200Response>>;

    /**
     * View user
     */
    viewUser(requestParameters: ViewUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersView200Response>;

    /**
     * 
     * @summary Lookup City object for zip code
     * @param {string} zipCode The zipCode to search for
     * @param {string} [countryIdentifier] The country to lookup in - if no value supplied it looks up in all countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    zipCodeLookupRaw(requestParameters: ZipCodeLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCity200Response>>;

    /**
     * Lookup City object for zip code
     */
    zipCodeLookup(requestParameters: ZipCodeLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCity200Response>;

    /**
     * 
     * @summary Lookup City object for zip code
     * @param {string} zipCode 
     * @param {string} [countryIdentifier] The country to lookup in - defaults to &#x60;DK&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    zipCodeSearchRaw(requestParameters: ZipCodeSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCities200Response>>;

    /**
     * Lookup City object for zip code
     */
    zipCodeSearch(requestParameters: ZipCodeSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCities200Response>;

}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {

    /**
     * Create contact person
     */
    async addContactPersonRaw(requestParameters: AddContactPersonOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling addContactPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{contact_id}/contact_persons`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddContactPersonRequestToJSON(requestParameters.addContactPersonRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create contact person
     */
    async addContactPerson(requestParameters: AddContactPersonOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.addContactPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create offer line
     */
    async addOfferLineRaw(requestParameters: AddOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling addOfferLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferLineToJSON(requestParameters.offerLine),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create offer line
     */
    async addOfferLine(requestParameters: AddOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.addOfferLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Hide prices on individual offer lines and add a total price line
     */
    async addTotalPriceLineRaw(requestParameters: AddTotalPriceLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling addTotalPriceLine.');
        }

        if (requestParameters.offerLine === null || requestParameters.offerLine === undefined) {
            throw new runtime.RequiredError('offerLine','Required parameter requestParameters.offerLine was null or undefined when calling addTotalPriceLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines/add-total-price-line`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.offerLine.map(OfferLineToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatherOfferLines200ResponseFromJSON(jsonValue));
    }

    /**
     * Hide prices on individual offer lines and add a total price line
     */
    async addTotalPriceLine(requestParameters: AddTotalPriceLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response> {
        const response = await this.addTotalPriceLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for address for autocomplete usage
     */
    async autoCompleteAddressRaw(requestParameters: AutoCompleteAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutoCompleteAddress200Response>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling autoCompleteAddress.');
        }

        const queryParameters: any = {};

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/address-lookup/autocomplete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoCompleteAddress200ResponseFromJSON(jsonValue));
    }

    /**
     * Search for address for autocomplete usage
     */
    async autoCompleteAddress(requestParameters: AutoCompleteAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutoCompleteAddress200Response> {
        const response = await this.autoCompleteAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete list of cards and re-index affected cards
     */
    async cardsDeleteManyRaw(requestParameters: CardsDeleteManyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>> {
        if (requestParameters.cardsDeleteManyRequest === null || requestParameters.cardsDeleteManyRequest === undefined) {
            throw new runtime.RequiredError('cardsDeleteManyRequest','Required parameter requestParameters.cardsDeleteManyRequest was null or undefined when calling cardsDeleteMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/cards/delete-many`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: CardsDeleteManyRequestToJSON(requestParameters.cardsDeleteManyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardsDeleteMany200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete list of cards and re-index affected cards
     */
    async cardsDeleteMany(requestParameters: CardsDeleteManyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response> {
        const response = await this.cardsDeleteManyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change password to a specific user
     */
    async changePasswordRaw(requestParameters: ChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/change-password/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters.changePasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISendProjectForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Change password to a specific user
     */
    async changePassword(requestParameters: ChangePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response> {
        const response = await this.changePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change status of the selected forms
     */
    async changeStatusRaw(requestParameters: ChangeStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/forms/change-status`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatusRequestToJSON(requestParameters.changeStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISendProjectForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Change status of the selected forms
     */
    async changeStatus(requestParameters: ChangeStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response> {
        const response = await this.changeStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create(check in) clocking record
     */
    async checkInRaw(requestParameters: CheckInOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/clocking-records/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckInRequestToJSON(requestParameters.checkInRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create(check in) clocking record
     */
    async checkIn(requestParameters: CheckInOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.checkInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check out clocking record
     */
    async checkOutRaw(requestParameters: CheckOutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/clocking-records/check_out`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckOutRequestToJSON(requestParameters.checkOutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Check out clocking record
     */
    async checkOut(requestParameters: CheckOutOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.checkOutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create card
     */
    async createCardRaw(requestParameters: CreateCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/cards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CardUpdateDTOToJSON(requestParameters.cardUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create card
     */
    async createCard(requestParameters: CreateCardRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a task and card at the same time
     * Create task and card
     */
    async createCardAndTaskRaw(requestParameters: CreateCardAndTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCardAndTask201Response>> {
        if (requestParameters.createCardAndTaskRequest === null || requestParameters.createCardAndTaskRequest === undefined) {
            throw new runtime.RequiredError('createCardAndTaskRequest','Required parameter requestParameters.createCardAndTaskRequest was null or undefined when calling createCardAndTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/planning/task-and-card`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCardAndTaskRequestToJSON(requestParameters.createCardAndTaskRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCardAndTask201ResponseFromJSON(jsonValue));
    }

    /**
     * Creates a task and card at the same time
     * Create task and card
     */
    async createCardAndTask(requestParameters: CreateCardAndTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCardAndTask201Response> {
        const response = await this.createCardAndTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create contact
     */
    async createContactRaw(requestParameters: CreateContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactRequestToJSON(requestParameters.createContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create contact
     */
    async createContact(requestParameters: CreateContactOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create form with form fields
     */
    async createFormRaw(requestParameters: CreateFormOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/forms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFormRequestToJSON(requestParameters.createFormRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create form with form fields
     */
    async createForm(requestParameters: CreateFormOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create invoice
     */
    async createInvoiceRaw(requestParameters: CreateInvoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInvoiceRequestToJSON(requestParameters.createInvoiceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create invoice
     */
    async createInvoice(requestParameters: CreateInvoiceOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create label
     */
    async createLabelRaw(requestParameters: CreateLabelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/labels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLabelRequestToJSON(requestParameters.createLabelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create label
     */
    async createLabel(requestParameters: CreateLabelOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createLabelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create offer
     */
    async createOfferRaw(requestParameters: CreateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditOfferRequestToJSON(requestParameters.editOfferRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create offer
     */
    async createOffer(requestParameters: CreateOfferRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create product
     */
    async createProductRaw(requestParameters: CreateProductOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductRequestToJSON(requestParameters.createProductRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create product
     */
    async createProduct(requestParameters: CreateProductOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create project custom field attribute
     */
    async createProjectCustomFieldAttributeRaw(requestParameters: CreateProjectCustomFieldAttributeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/project-custom-field-attributes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProjectCustomFieldAttributeRequestToJSON(requestParameters.createProjectCustomFieldAttributeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create project custom field attribute
     */
    async createProjectCustomFieldAttribute(requestParameters: CreateProjectCustomFieldAttributeOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createProjectCustomFieldAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create task
     */
    async createTaskRaw(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskCreateOrUpdateDTOToJSON(requestParameters.taskCreateOrUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create task
     */
    async createTask(requestParameters: CreateTaskRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create template
     */
    async createTextTemplateRaw(requestParameters: CreateTextTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/text-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTextTemplateRequestToJSON(requestParameters.createTextTemplateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create template
     */
    async createTextTemplate(requestParameters: CreateTextTemplateOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createTextTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create user
     */
    async createUserRaw(requestParameters: CreateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserRequestToJSON(requestParameters.createUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create user
     */
    async createUser(requestParameters: CreateUserOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cvrSearchByNameRaw(requestParameters: CvrSearchByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CvrSearchByName200Response>> {
        if (requestParameters.companyName === null || requestParameters.companyName === undefined) {
            throw new runtime.RequiredError('companyName','Required parameter requestParameters.companyName was null or undefined when calling cvrSearchByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/cvr_look_up/search/{companyName}`.replace(`{${"companyName"}}`, encodeURIComponent(String(requestParameters.companyName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CvrSearchByName200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async cvrSearchByName(requestParameters: CvrSearchByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CvrSearchByName200Response> {
        const response = await this.cvrSearchByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete card
     */
    async deleteCardRaw(requestParameters: DeleteCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCard200Response>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling deleteCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/cards/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCard200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete card
     */
    async deleteCard(requestParameters: DeleteCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCard200Response> {
        const response = await this.deleteCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete contact
     */
    async deleteContactRaw(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteContact200Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete contact
     */
    async deleteContact(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteContact200Response> {
        const response = await this.deleteContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete contact person
     */
    async deleteContactPersonRaw(requestParameters: DeleteContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteContactPerson.');
        }

        if (requestParameters.contactPersonId === null || requestParameters.contactPersonId === undefined) {
            throw new runtime.RequiredError('contactPersonId','Required parameter requestParameters.contactPersonId was null or undefined when calling deleteContactPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{contact_id}/contact_persons/{contact_person_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))).replace(`{${"contact_person_id"}}`, encodeURIComponent(String(requestParameters.contactPersonId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete contact person
     */
    async deleteContactPerson(requestParameters: DeleteContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.deleteContactPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete expense
     */
    async deleteExpenseRaw(requestParameters: DeleteExpenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.expenseId === null || requestParameters.expenseId === undefined) {
            throw new runtime.RequiredError('expenseId','Required parameter requestParameters.expenseId was null or undefined when calling deleteExpense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/expenses/{expense_id}`.replace(`{${"expense_id"}}`, encodeURIComponent(String(requestParameters.expenseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete expense
     */
    async deleteExpense(requestParameters: DeleteExpenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.deleteExpenseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete form
     */
    async deleteFormRaw(requestParameters: DeleteFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling deleteForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/forms/{form_id}`.replace(`{${"form_id"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete form
     */
    async deleteForm(requestParameters: DeleteFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.deleteFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete invoice
     */
    async deleteInvoiceRaw(requestParameters: DeleteInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling deleteInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/invoices/{invoice_id}`.replace(`{${"invoice_id"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete invoice
     */
    async deleteInvoice(requestParameters: DeleteInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.deleteInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete label
     */
    async deleteLabelRaw(requestParameters: DeleteLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteContact200Response>> {
        if (requestParameters.labelId === null || requestParameters.labelId === undefined) {
            throw new runtime.RequiredError('labelId','Required parameter requestParameters.labelId was null or undefined when calling deleteLabel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/labels/{label_id}`.replace(`{${"label_id"}}`, encodeURIComponent(String(requestParameters.labelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete label
     */
    async deleteLabel(requestParameters: DeleteLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteContact200Response> {
        const response = await this.deleteLabelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete offer file
     */
    async deleteOfferFileRaw(requestParameters: DeleteOfferFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling deleteOfferFile.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteOfferFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/files/delete`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete offer file
     */
    async deleteOfferFile(requestParameters: DeleteOfferFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.deleteOfferFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete offer line
     */
    async deleteOfferLineRaw(requestParameters: DeleteOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling deleteOfferLine.');
        }

        if (requestParameters.offerLineId === null || requestParameters.offerLineId === undefined) {
            throw new runtime.RequiredError('offerLineId','Required parameter requestParameters.offerLineId was null or undefined when calling deleteOfferLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines/{offer_line_id}`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"offer_line_id"}}`, encodeURIComponent(String(requestParameters.offerLineId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete offer line
     */
    async deleteOfferLine(requestParameters: DeleteOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.deleteOfferLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete product
     */
    async deleteProductRaw(requestParameters: DeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling deleteProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products/{product_id}`.replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete product
     */
    async deleteProduct(requestParameters: DeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.deleteProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete product variants
     */
    async deleteProductVariantsRaw(requestParameters: DeleteProductVariantsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products/delete-variants`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteProductVariantsRequestToJSON(requestParameters.deleteProductVariantsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete product variants
     */
    async deleteProductVariants(requestParameters: DeleteProductVariantsOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.deleteProductVariantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete project custom field attribute
     */
    async deleteProjectCustomFieldAttributeRaw(requestParameters: DeleteProjectCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteContact200Response>> {
        if (requestParameters.projectCustomFieldAttributeId === null || requestParameters.projectCustomFieldAttributeId === undefined) {
            throw new runtime.RequiredError('projectCustomFieldAttributeId','Required parameter requestParameters.projectCustomFieldAttributeId was null or undefined when calling deleteProjectCustomFieldAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/project-custom-field-attributes/{project_custom_field_attribute_id}`.replace(`{${"project_custom_field_attribute_id"}}`, encodeURIComponent(String(requestParameters.projectCustomFieldAttributeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete project custom field attribute
     */
    async deleteProjectCustomFieldAttribute(requestParameters: DeleteProjectCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteContact200Response> {
        const response = await this.deleteProjectCustomFieldAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete task
     */
    async deleteTaskRaw(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling deleteTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/tasks/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardsDeleteMany200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete task
     */
    async deleteTask(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response> {
        const response = await this.deleteTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete text template
     */
    async deleteTextTemplateRaw(requestParameters: DeleteTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.textTemplateId === null || requestParameters.textTemplateId === undefined) {
            throw new runtime.RequiredError('textTemplateId','Required parameter requestParameters.textTemplateId was null or undefined when calling deleteTextTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/text-templates/{text_template_id}`.replace(`{${"text_template_id"}}`, encodeURIComponent(String(requestParameters.textTemplateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete text template
     */
    async deleteTextTemplate(requestParameters: DeleteTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.deleteTextTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardsDeleteMany200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete user
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response> {
        const response = await this.deleteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Distribution Hours Over Products
     */
    async distributionHoursOverProductsRaw(requestParameters: DistributionHoursOverProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling distributionHoursOverProducts.');
        }

        if (requestParameters.offerLine === null || requestParameters.offerLine === undefined) {
            throw new runtime.RequiredError('offerLine','Required parameter requestParameters.offerLine was null or undefined when calling distributionHoursOverProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines/distribution-hours-over-products`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.offerLine.map(OfferLineToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatherOfferLines200ResponseFromJSON(jsonValue));
    }

    /**
     * Distribution Hours Over Products
     */
    async distributionHoursOverProducts(requestParameters: DistributionHoursOverProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response> {
        const response = await this.distributionHoursOverProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate form
     */
    async duplicateFormRaw(requestParameters: DuplicateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling duplicateForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/forms/duplicate/{formId}`.replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate form
     */
    async duplicateForm(requestParameters: DuplicateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.duplicateFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate invoice
     */
    async duplicateInvoiceRaw(requestParameters: DuplicateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling duplicateInvoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.asCreditNote !== undefined) {
            queryParameters['as_credit_note'] = requestParameters.asCreditNote;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices/duplicate/{invoice_id}`.replace(`{${"invoice_id"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate invoice
     */
    async duplicateInvoice(requestParameters: DuplicateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.duplicateInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate offer
     */
    async duplicateOfferRaw(requestParameters: DuplicateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling duplicateOffer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/duplicate/{offer_id}`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate offer
     */
    async duplicateOffer(requestParameters: DuplicateOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.duplicateOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate project
     */
    async duplicateProjectRaw(requestParameters: DuplicateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling duplicateProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/duplicate/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate project
     */
    async duplicateProject(requestParameters: DuplicateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.duplicateProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit card
     */
    async editCardRaw(requestParameters: EditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditCard200Response>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling editCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/cards/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CardUpdateDTOToJSON(requestParameters.cardUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditCard200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit card
     */
    async editCard(requestParameters: EditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditCard200Response> {
        const response = await this.editCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit contact
     */
    async editContactRaw(requestParameters: EditContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling editContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactToJSON(requestParameters.contact),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit contact
     */
    async editContact(requestParameters: EditContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.editContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit contact person
     */
    async editContactPersonRaw(requestParameters: EditContactPersonOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling editContactPerson.');
        }

        if (requestParameters.contactPersonId === null || requestParameters.contactPersonId === undefined) {
            throw new runtime.RequiredError('contactPersonId','Required parameter requestParameters.contactPersonId was null or undefined when calling editContactPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{contact_id}/contact_persons/{contact_person_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))).replace(`{${"contact_person_id"}}`, encodeURIComponent(String(requestParameters.contactPersonId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditContactPersonRequestToJSON(requestParameters.editContactPersonRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit contact person
     */
    async editContactPerson(requestParameters: EditContactPersonOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.editContactPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit invoice
     */
    async editInvoiceRaw(requestParameters: EditInvoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling editInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditInvoiceRequestToJSON(requestParameters.editInvoiceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit invoice
     */
    async editInvoice(requestParameters: EditInvoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.editInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit label
     */
    async editLabelRaw(requestParameters: EditLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        if (requestParameters.labelId === null || requestParameters.labelId === undefined) {
            throw new runtime.RequiredError('labelId','Required parameter requestParameters.labelId was null or undefined when calling editLabel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/labels/{label_id}`.replace(`{${"label_id"}}`, encodeURIComponent(String(requestParameters.labelId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLabelRequestToJSON(requestParameters.createLabelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit label
     */
    async editLabel(requestParameters: EditLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.editLabelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit offer
     */
    async editOfferRaw(requestParameters: EditOfferOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling editOffer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditOfferRequestToJSON(requestParameters.editOfferRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit offer
     */
    async editOffer(requestParameters: EditOfferOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.editOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit offer line
     */
    async editOfferLineRaw(requestParameters: EditOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling editOfferLine.');
        }

        if (requestParameters.offerLineId === null || requestParameters.offerLineId === undefined) {
            throw new runtime.RequiredError('offerLineId','Required parameter requestParameters.offerLineId was null or undefined when calling editOfferLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines/{offer_line_id}`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"offer_line_id"}}`, encodeURIComponent(String(requestParameters.offerLineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfferLineToJSON(requestParameters.offerLine),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit offer line
     */
    async editOfferLine(requestParameters: EditOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.editOfferLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit product
     */
    async editProductRaw(requestParameters: EditProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling editProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products/{product_id}`.replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductRequestToJSON(requestParameters.createProductRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit product
     */
    async editProduct(requestParameters: EditProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.editProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit project custom field attribute
     */
    async editProjectCustomFieldAttributeRaw(requestParameters: EditProjectCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        if (requestParameters.projectCustomFieldAttributeId === null || requestParameters.projectCustomFieldAttributeId === undefined) {
            throw new runtime.RequiredError('projectCustomFieldAttributeId','Required parameter requestParameters.projectCustomFieldAttributeId was null or undefined when calling editProjectCustomFieldAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/project-custom-field-attributes/{project_custom_field_attribute_id}`.replace(`{${"project_custom_field_attribute_id"}}`, encodeURIComponent(String(requestParameters.projectCustomFieldAttributeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProjectCustomFieldAttributeRequestToJSON(requestParameters.createProjectCustomFieldAttributeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit project custom field attribute
     */
    async editProjectCustomFieldAttribute(requestParameters: EditProjectCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.editProjectCustomFieldAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit subscription
     */
    async editSubscriptionRaw(requestParameters: EditSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling editSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/subscriptions/{subscription_id}`.replace(`{${"subscription_id"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditSubscriptionRequestToJSON(requestParameters.editSubscriptionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Edit subscription
     */
    async editSubscription(requestParameters: EditSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.editSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit task
     */
    async editTaskRaw(requestParameters: EditTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling editTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/tasks/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaskCreateOrUpdateDTOToJSON(requestParameters.taskCreateOrUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Edit task
     */
    async editTask(requestParameters: EditTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.editTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit text template
     */
    async editTextTemplateRaw(requestParameters: EditTextTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.textTemplateId === null || requestParameters.textTemplateId === undefined) {
            throw new runtime.RequiredError('textTemplateId','Required parameter requestParameters.textTemplateId was null or undefined when calling editTextTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/text-templates/{text_template_id}`.replace(`{${"text_template_id"}}`, encodeURIComponent(String(requestParameters.textTemplateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditTextTemplateRequestToJSON(requestParameters.editTextTemplateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit text template
     */
    async editTextTemplate(requestParameters: EditTextTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.editTextTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit user
     */
    async editUserRaw(requestParameters: EditUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling editUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditUserRequestToJSON(requestParameters.editUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit user
     */
    async editUser(requestParameters: EditUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.editUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List expense lines
     */
    async expenseLinesListRaw(requestParameters: ExpenseLinesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpenseLinesList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.vendorId !== undefined) {
            queryParameters['vendor_id'] = requestParameters.vendorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/expense-lines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpenseLinesList200ResponseFromJSON(jsonValue));
    }

    /**
     * List expense lines
     */
    async expenseLinesList(requestParameters: ExpenseLinesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpenseLinesList200Response> {
        const response = await this.expenseLinesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View expense lines
     */
    async expenseLinesViewRaw(requestParameters: ExpenseLinesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpenseLinesView200Response>> {
        if (requestParameters.expenseId === null || requestParameters.expenseId === undefined) {
            throw new runtime.RequiredError('expenseId','Required parameter requestParameters.expenseId was null or undefined when calling expenseLinesView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/expense-lines/{expense_id}`.replace(`{${"expense_id"}}`, encodeURIComponent(String(requestParameters.expenseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpenseLinesView200ResponseFromJSON(jsonValue));
    }

    /**
     * View expense lines
     */
    async expenseLinesView(requestParameters: ExpenseLinesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpenseLinesView200Response> {
        const response = await this.expenseLinesViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List expenses
     */
    async expensesListRaw(requestParameters: ExpensesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpensesList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.vendorId !== undefined) {
            queryParameters['vendor_id'] = requestParameters.vendorId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.sentToEmail !== undefined) {
            queryParameters['sent_to_email'] = requestParameters.sentToEmail;
        }

        if (requestParameters.distributed !== undefined) {
            queryParameters['distributed'] = requestParameters.distributed;
        }

        if (requestParameters.deliveryOrCreatedBefore !== undefined) {
            queryParameters['delivery_or_created_before'] = requestParameters.deliveryOrCreatedBefore;
        }

        if (requestParameters.deliveryOrCreatedAfter !== undefined) {
            queryParameters['delivery_or_created_after'] = requestParameters.deliveryOrCreatedAfter;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.isInvoiced !== undefined) {
            queryParameters['is_invoiced'] = requestParameters.isInvoiced;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/expenses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpensesList200ResponseFromJSON(jsonValue));
    }

    /**
     * List expenses
     */
    async expensesList(requestParameters: ExpensesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpensesList200Response> {
        const response = await this.expensesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View expense
     */
    async expensesViewRaw(requestParameters: ExpensesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpensesView200Response>> {
        if (requestParameters.expenseId === null || requestParameters.expenseId === undefined) {
            throw new runtime.RequiredError('expenseId','Required parameter requestParameters.expenseId was null or undefined when calling expensesView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/expenses/{expense_id}`.replace(`{${"expense_id"}}`, encodeURIComponent(String(requestParameters.expenseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpensesView200ResponseFromJSON(jsonValue));
    }

    /**
     * View expense
     */
    async expensesView(requestParameters: ExpensesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpensesView200Response> {
        const response = await this.expensesViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View form template
     */
    async formTemplateViewRaw(requestParameters: FormTemplateViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormTemplateView200Response>> {
        if (requestParameters.formTemplateId === null || requestParameters.formTemplateId === undefined) {
            throw new runtime.RequiredError('formTemplateId','Required parameter requestParameters.formTemplateId was null or undefined when calling formTemplateView.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/form-templates/{form_template_id}`.replace(`{${"form_template_id"}}`, encodeURIComponent(String(requestParameters.formTemplateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormTemplateView200ResponseFromJSON(jsonValue));
    }

    /**
     * View form template
     */
    async formTemplateView(requestParameters: FormTemplateViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormTemplateView200Response> {
        const response = await this.formTemplateViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List form templates
     */
    async formTemplatesListRaw(requestParameters: FormTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormTemplatesList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/form-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormTemplatesList200ResponseFromJSON(jsonValue));
    }

    /**
     * List form templates
     */
    async formTemplatesList(requestParameters: FormTemplatesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormTemplatesList200Response> {
        const response = await this.formTemplatesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View form
     */
    async formViewRaw(requestParameters: FormViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormView200Response>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling formView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/forms/{form_id}`.replace(`{${"form_id"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormView200ResponseFromJSON(jsonValue));
    }

    /**
     * View form
     */
    async formView(requestParameters: FormViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormView200Response> {
        const response = await this.formViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List forms
     */
    async formsListRaw(requestParameters: FormsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormsList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.projectId) {
            queryParameters['project_id[]'] = requestParameters.projectId;
        }

        if (requestParameters.createdById !== undefined) {
            queryParameters['created_by_id'] = requestParameters.createdById;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.formTemplateId) {
            queryParameters['form_template_id[]'] = requestParameters.formTemplateId;
        }

        if (requestParameters.isApproved !== undefined) {
            queryParameters['is_approved'] = requestParameters.isApproved;
        }

        if (requestParameters.isInvoicedFrom !== undefined) {
            queryParameters['is_invoiced_from'] = (requestParameters.isInvoicedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.isInvoicedTo !== undefined) {
            queryParameters['is_invoiced_to'] = (requestParameters.isInvoicedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.isNotInvoiced !== undefined) {
            queryParameters['is_not_invoiced'] = requestParameters.isNotInvoiced;
        }

        if (requestParameters.activities) {
            queryParameters['activities'] = requestParameters.activities;
        }

        if (requestParameters.approved !== undefined) {
            queryParameters['approved'] = requestParameters.approved;
        }

        if (requestParameters.documentType !== undefined) {
            queryParameters['document_type'] = requestParameters.documentType;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/forms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormsList200ResponseFromJSON(jsonValue));
    }

    /**
     * List forms
     */
    async formsList(requestParameters: FormsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormsList200Response> {
        const response = await this.formsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gather offer line
     */
    async gatherOfferLinesRaw(requestParameters: GatherOfferLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling gatherOfferLines.');
        }

        if (requestParameters.offerLine === null || requestParameters.offerLine === undefined) {
            throw new runtime.RequiredError('offerLine','Required parameter requestParameters.offerLine was null or undefined when calling gatherOfferLines.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines/gather-offer-lines`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.offerLine.map(OfferLineToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatherOfferLines200ResponseFromJSON(jsonValue));
    }

    /**
     * Gather offer line
     */
    async gatherOfferLines(requestParameters: GatherOfferLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response> {
        const response = await this.gatherOfferLinesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate salary file
     */
    async generateSalaryFileRaw(requestParameters: GenerateSalaryFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/generate-salary-file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate salary file
     */
    async generateSalaryFile(requestParameters: GenerateSalaryFileRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.generateSalaryFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get registrations for project
     */
    async getAllRegistrationsRaw(requestParameters: GetAllRegistrationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllRegistrations200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getAllRegistrations.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.allUsers !== undefined) {
            queryParameters['all_users'] = requestParameters.allUsers;
        }

        if (requestParameters.approved !== undefined) {
            queryParameters['approved'] = requestParameters.approved;
        }

        if (requestParameters.documentType !== undefined) {
            queryParameters['document_type'] = requestParameters.documentType;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/registrations`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllRegistrations200ResponseFromJSON(jsonValue));
    }

    /**
     * Get registrations for project
     */
    async getAllRegistrations(requestParameters: GetAllRegistrationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllRegistrations200Response> {
        const response = await this.getAllRegistrationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View card
     */
    async getCardRaw(requestParameters: GetCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCard200Response>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling getCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/cards/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCard200ResponseFromJSON(jsonValue));
    }

    /**
     * View card
     */
    async getCard(requestParameters: GetCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCard200Response> {
        const response = await this.getCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List cards
     */
    async getCardsRaw(requestParameters: GetCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCards200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = (requestParameters.dateBefore as any).toISOString().substr(0,10);
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = (requestParameters.dateAfter as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/cards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCards200ResponseFromJSON(jsonValue));
    }

    /**
     * List cards
     */
    async getCards(requestParameters: GetCardsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCards200Response> {
        const response = await this.getCardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List cards
     */
    async getCardsWithTaskAndUsersRaw(requestParameters: GetCardsWithTaskAndUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCardsWithTaskAndUsers200Response>> {
        if (requestParameters.dateBefore === null || requestParameters.dateBefore === undefined) {
            throw new runtime.RequiredError('dateBefore','Required parameter requestParameters.dateBefore was null or undefined when calling getCardsWithTaskAndUsers.');
        }

        if (requestParameters.dateAfter === null || requestParameters.dateAfter === undefined) {
            throw new runtime.RequiredError('dateAfter','Required parameter requestParameters.dateAfter was null or undefined when calling getCardsWithTaskAndUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = (requestParameters.dateBefore as any).toISOString();
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = (requestParameters.dateAfter as any).toISOString();
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.taskId !== undefined) {
            queryParameters['task_id'] = requestParameters.taskId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/cards/all-cards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCardsWithTaskAndUsers200ResponseFromJSON(jsonValue));
    }

    /**
     * List cards
     */
    async getCardsWithTaskAndUsers(requestParameters: GetCardsWithTaskAndUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCardsWithTaskAndUsers200Response> {
        const response = await this.getCardsWithTaskAndUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List cities
     */
    async getCitiesRaw(requestParameters: GetCitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCities200Response>> {
        const queryParameters: any = {};

        if (requestParameters.zipCode !== undefined) {
            queryParameters['zip_code'] = requestParameters.zipCode;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.includeAll !== undefined) {
            queryParameters['include_all'] = requestParameters.includeAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/cities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCities200ResponseFromJSON(jsonValue));
    }

    /**
     * List cities
     */
    async getCities(requestParameters: GetCitiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCities200Response> {
        const response = await this.getCitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View city
     */
    async getCityRaw(requestParameters: GetCityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCity200Response>> {
        if (requestParameters.cityId === null || requestParameters.cityId === undefined) {
            throw new runtime.RequiredError('cityId','Required parameter requestParameters.cityId was null or undefined when calling getCity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/cities/{city_id}`.replace(`{${"city_id"}}`, encodeURIComponent(String(requestParameters.cityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCity200ResponseFromJSON(jsonValue));
    }

    /**
     * View city
     */
    async getCity(requestParameters: GetCityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCity200Response> {
        const response = await this.getCityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List companies
     */
    async getCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompanies200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanies200ResponseFromJSON(jsonValue));
    }

    /**
     * List companies
     */
    async getCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompanies200Response> {
        const response = await this.getCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     * View company
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompany200Response>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/companies/{company_id}`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompany200ResponseFromJSON(jsonValue));
    }

    /**
     * View company
     */
    async getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompany200Response> {
        const response = await this.getCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View contact
     */
    async getContactRaw(requestParameters: GetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContact200Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getContact.');
        }

        const queryParameters: any = {};

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContact200ResponseFromJSON(jsonValue));
    }

    /**
     * View contact
     */
    async getContact(requestParameters: GetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContact200Response> {
        const response = await this.getContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View contact person
     */
    async getContactPersonRaw(requestParameters: GetContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContactPerson200Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getContactPerson.');
        }

        if (requestParameters.contactPersonId === null || requestParameters.contactPersonId === undefined) {
            throw new runtime.RequiredError('contactPersonId','Required parameter requestParameters.contactPersonId was null or undefined when calling getContactPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{contact_id}/contact_persons/{contact_person_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))).replace(`{${"contact_person_id"}}`, encodeURIComponent(String(requestParameters.contactPersonId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContactPerson200ResponseFromJSON(jsonValue));
    }

    /**
     * View contact person
     */
    async getContactPerson(requestParameters: GetContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContactPerson200Response> {
        const response = await this.getContactPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of contact people associated with a contact
     * List contact people for contact
     */
    async getContactPersonsListRaw(requestParameters: GetContactPersonsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContactPersonsList200Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getContactPersonsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.createdGte !== undefined) {
            queryParameters['created_gte'] = (requestParameters.createdGte as any).toISOString().substr(0,10);
        }

        if (requestParameters.createdLte !== undefined) {
            queryParameters['created_lte'] = (requestParameters.createdLte as any).toISOString().substr(0,10);
        }

        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/contacts/{contact_id}/contact_persons`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContactPersonsList200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of contact people associated with a contact
     * List contact people for contact
     */
    async getContactPersonsList(requestParameters: GetContactPersonsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContactPersonsList200Response> {
        const response = await this.getContactPersonsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List contacts
     */
    async getContactsRaw(requestParameters: GetContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContacts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContacts200ResponseFromJSON(jsonValue));
    }

    /**
     * List contacts
     */
    async getContacts(requestParameters: GetContactsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContacts200Response> {
        const response = await this.getContactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCountriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCountries200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCountries200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCountries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCountries200Response> {
        const response = await this.getCountriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a list of email actions for specific email
     * List actions for email
     */
    async getEmailActionRaw(requestParameters: GetEmailActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailAction200Response>> {
        if (requestParameters.emailId === null || requestParameters.emailId === undefined) {
            throw new runtime.RequiredError('emailId','Required parameter requestParameters.emailId was null or undefined when calling getEmailAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/emails/{email_id}/actions`.replace(`{${"email_id"}}`, encodeURIComponent(String(requestParameters.emailId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailAction200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of email actions for specific email
     * List actions for email
     */
    async getEmailAction(requestParameters: GetEmailActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailAction200Response> {
        const response = await this.getEmailActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists available features for the current user
     */
    async getFeaturesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFeatures200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFeatures200ResponseFromJSON(jsonValue));
    }

    /**
     * Lists available features for the current user
     */
    async getFeatures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFeatures200Response> {
        const response = await this.getFeaturesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a list of integration types associated with a user
     * Return user integration types
     */
    async getIntegrationTypesRaw(requestParameters: GetIntegrationTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetIntegrationTypes200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getIntegrationTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/integration-types/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIntegrationTypes200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of integration types associated with a user
     * Return user integration types
     */
    async getIntegrationTypes(requestParameters: GetIntegrationTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetIntegrationTypes200Response> {
        const response = await this.getIntegrationTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInvoiceActivityLogRaw(requestParameters: GetInvoiceActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvoiceActivityLog200Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling getInvoiceActivityLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices/{invoice_id}/actions`.replace(`{${"invoice_id"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInvoiceActivityLog200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getInvoiceActivityLog(requestParameters: GetInvoiceActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvoiceActivityLog200Response> {
        const response = await this.getInvoiceActivityLogRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of invoice emails for specific invoice
     * List invoice emails
     */
    async getInvoiceEmailsRaw(requestParameters: GetInvoiceEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvoiceEmails200Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling getInvoiceEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices/{invoice_id}/emails`.replace(`{${"invoice_id"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInvoiceEmails200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of invoice emails for specific invoice
     * List invoice emails
     */
    async getInvoiceEmails(requestParameters: GetInvoiceEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvoiceEmails200Response> {
        const response = await this.getInvoiceEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInvoicesRaw(requestParameters: GetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvoices200Response>> {
        const queryParameters: any = {};

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInvoices200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getInvoices(requestParameters: GetInvoicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvoices200Response> {
        const response = await this.getInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View label
     */
    async getLabelRaw(requestParameters: GetLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLabel200Response>> {
        if (requestParameters.labelId === null || requestParameters.labelId === undefined) {
            throw new runtime.RequiredError('labelId','Required parameter requestParameters.labelId was null or undefined when calling getLabel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/labels/{label_id}`.replace(`{${"label_id"}}`, encodeURIComponent(String(requestParameters.labelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLabel200ResponseFromJSON(jsonValue));
    }

    /**
     * View label
     */
    async getLabel(requestParameters: GetLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLabel200Response> {
        const response = await this.getLabelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List labels
     */
    async getLabelsRaw(requestParameters: GetLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLabels200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.entity !== undefined) {
            queryParameters['entity'] = requestParameters.entity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/labels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLabels200ResponseFromJSON(jsonValue));
    }

    /**
     * List labels
     */
    async getLabels(requestParameters: GetLabelsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLabels200Response> {
        const response = await this.getLabelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLanguagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLanguages200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/languages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLanguages200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLanguages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLanguages200Response> {
        const response = await this.getLanguagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * View currently authenticated user
     */
    async getMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMe200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMe200ResponseFromJSON(jsonValue));
    }

    /**
     * View currently authenticated user
     */
    async getMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMe200Response> {
        const response = await this.getMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a list of offer actions for specific offer
     * List offer actions for offer
     */
    async getOfferActionsRaw(requestParameters: GetOfferActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferActions200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling getOfferActions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/actions`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOfferActions200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of offer actions for specific offer
     * List offer actions for offer
     */
    async getOfferActions(requestParameters: GetOfferActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferActions200Response> {
        const response = await this.getOfferActionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of offer emails for specific offer
     * List offer emails
     */
    async getOfferEmailsRaw(requestParameters: GetOfferEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvoiceEmails200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling getOfferEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/emails`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInvoiceEmails200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of offer emails for specific offer
     * List offer emails
     */
    async getOfferEmails(requestParameters: GetOfferEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvoiceEmails200Response> {
        const response = await this.getOfferEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of offer files for specific offer
     * List offer files for offer
     */
    async getOfferFilesRaw(requestParameters: GetOfferFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferFiles200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling getOfferFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/files`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOfferFiles200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of offer files for specific offer
     * List offer files for offer
     */
    async getOfferFiles(requestParameters: GetOfferFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferFiles200Response> {
        const response = await this.getOfferFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View offer line
     */
    async getOfferLineRaw(requestParameters: GetOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferLine200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling getOfferLine.');
        }

        if (requestParameters.offerLineId === null || requestParameters.offerLineId === undefined) {
            throw new runtime.RequiredError('offerLineId','Required parameter requestParameters.offerLineId was null or undefined when calling getOfferLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines/{offer_line_id}`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))).replace(`{${"offer_line_id"}}`, encodeURIComponent(String(requestParameters.offerLineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOfferLine200ResponseFromJSON(jsonValue));
    }

    /**
     * View offer line
     */
    async getOfferLine(requestParameters: GetOfferLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferLine200Response> {
        const response = await this.getOfferLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of offer lines for specific offer
     * List offer lines for offer
     */
    async getOfferLinesRaw(requestParameters: GetOfferLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferLines200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling getOfferLines.');
        }

        const queryParameters: any = {};

        if (requestParameters.applyRules !== undefined) {
            queryParameters['apply_rules'] = requestParameters.applyRules;
        }

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOfferLines200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of offer lines for specific offer
     * List offer lines for offer
     */
    async getOfferLines(requestParameters: GetOfferLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferLines200Response> {
        const response = await this.getOfferLinesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get offer PDF for specific offer
     * Get offer PDF for offer
     */
    async getOfferPdfRaw(requestParameters: GetOfferPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOfferPdf200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling getOfferPdf.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/files/pdf`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOfferPdf200ResponseFromJSON(jsonValue));
    }

    /**
     * Get offer PDF for specific offer
     * Get offer PDF for offer
     */
    async getOfferPdf(requestParameters: GetOfferPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOfferPdf200Response> {
        const response = await this.getOfferPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPaymentTermsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentTerms200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/payment-terms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentTerms200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getPaymentTerms(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentTerms200Response> {
        const response = await this.getPaymentTermsRaw(initOverrides);
        return await response.value();
    }

    /**
     * View project custom field attribute
     */
    async getProductCustomFieldAttributeRaw(requestParameters: GetProductCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductCustomFieldAttribute200Response>> {
        if (requestParameters.projectCustomFieldAttributeId === null || requestParameters.projectCustomFieldAttributeId === undefined) {
            throw new runtime.RequiredError('projectCustomFieldAttributeId','Required parameter requestParameters.projectCustomFieldAttributeId was null or undefined when calling getProductCustomFieldAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/project-custom-field-attributes/{project_custom_field_attribute_id}`.replace(`{${"project_custom_field_attribute_id"}}`, encodeURIComponent(String(requestParameters.projectCustomFieldAttributeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductCustomFieldAttribute200ResponseFromJSON(jsonValue));
    }

    /**
     * View project custom field attribute
     */
    async getProductCustomFieldAttribute(requestParameters: GetProductCustomFieldAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductCustomFieldAttribute200Response> {
        const response = await this.getProductCustomFieldAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View project
     */
    async getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProject200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/projects/{project_id}`.replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProject200ResponseFromJSON(jsonValue));
    }

    /**
     * View project
     */
    async getProject(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProject200Response> {
        const response = await this.getProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List project custom field attributes
     */
    async getProjectCustomFieldAttributesRaw(requestParameters: GetProjectCustomFieldAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectCustomFieldAttributes200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/project-custom-field-attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectCustomFieldAttributes200ResponseFromJSON(jsonValue));
    }

    /**
     * List project custom field attributes
     */
    async getProjectCustomFieldAttributes(requestParameters: GetProjectCustomFieldAttributesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectCustomFieldAttributes200Response> {
        const response = await this.getProjectCustomFieldAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List projects
     */
    async getProjectsRaw(requestParameters: GetProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjects200Response>> {
        const queryParameters: any = {};

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.showAll !== undefined) {
            queryParameters['show_all'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjects200ResponseFromJSON(jsonValue));
    }

    /**
     * List projects
     */
    async getProjects(requestParameters: GetProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjects200Response> {
        const response = await this.getProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReleaseNotesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/release-notes-proxy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getReleaseNotes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getReleaseNotesRaw(initOverrides);
        return await response.value();
    }

    /**
     * View task
     */
    async getTaskRaw(requestParameters: GetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTask200Response>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/tasks/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTask200ResponseFromJSON(jsonValue));
    }

    /**
     * View task
     */
    async getTask(requestParameters: GetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTask200Response> {
        const response = await this.getTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTaskProductRaw(requestParameters: GetTaskProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTaskProduct200Response>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getTaskProduct.');
        }

        if (requestParameters.taskProductId === null || requestParameters.taskProductId === undefined) {
            throw new runtime.RequiredError('taskProductId','Required parameter requestParameters.taskProductId was null or undefined when calling getTaskProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/tasks/{taskId}/products/{taskProductId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))).replace(`{${"taskProductId"}}`, encodeURIComponent(String(requestParameters.taskProductId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTaskProduct200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTaskProduct(requestParameters: GetTaskProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTaskProduct200Response> {
        const response = await this.getTaskProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTaskProductsRaw(requestParameters: GetTaskProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTaskProducts200Response>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getTaskProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/tasks/{taskId}/products`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTaskProducts200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTaskProducts(requestParameters: GetTaskProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTaskProducts200Response> {
        const response = await this.getTaskProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List tasks
     */
    async getTasksRaw(requestParameters: GetTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListTasks200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IListTasks200ResponseFromJSON(jsonValue));
    }

    /**
     * List tasks
     */
    async getTasks(requestParameters: GetTasksRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListTasks200Response> {
        const response = await this.getTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View text template
     */
    async getTextTemplateRaw(requestParameters: GetTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTextTemplate200Response>> {
        if (requestParameters.textTemplateId === null || requestParameters.textTemplateId === undefined) {
            throw new runtime.RequiredError('textTemplateId','Required parameter requestParameters.textTemplateId was null or undefined when calling getTextTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/text-templates/{text_template_id}`.replace(`{${"text_template_id"}}`, encodeURIComponent(String(requestParameters.textTemplateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTextTemplate200ResponseFromJSON(jsonValue));
    }

    /**
     * View text template
     */
    async getTextTemplate(requestParameters: GetTextTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTextTemplate200Response> {
        const response = await this.getTextTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List text templates
     */
    async getTextTemplatesRaw(requestParameters: GetTextTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTextTemplates200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.visible !== undefined) {
            queryParameters['visible'] = requestParameters.visible;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/text-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTextTemplates200ResponseFromJSON(jsonValue));
    }

    /**
     * List text templates
     */
    async getTextTemplates(requestParameters: GetTextTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTextTemplates200Response> {
        const response = await this.getTextTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTimeEntryRuleGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTimeEntryRuleGroups200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/time-entry-rule-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTimeEntryRuleGroups200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTimeEntryRuleGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTimeEntryRuleGroups200Response> {
        const response = await this.getTimeEntryRuleGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List users
     */
    async getUsersRaw(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsers200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['is_active'] = requestParameters.isActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsers200ResponseFromJSON(jsonValue));
    }

    /**
     * List users
     */
    async getUsers(requestParameters: GetUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsers200Response> {
        const response = await this.getUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View vendor
     */
    async getVendorRaw(requestParameters: GetVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVendor200Response>> {
        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling getVendor.');
        }

        const queryParameters: any = {};

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/vendors/{vendor_id}`.replace(`{${"vendor_id"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVendor200ResponseFromJSON(jsonValue));
    }

    /**
     * View vendor
     */
    async getVendor(requestParameters: GetVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVendor200Response> {
        const response = await this.getVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List vendors
     */
    async getVendorsRaw(requestParameters: GetVendorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVendors200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.withPriceFile !== undefined) {
            queryParameters['with_price_file'] = requestParameters.withPriceFile;
        }

        if (requestParameters.withExpense !== undefined) {
            queryParameters['with_expense'] = requestParameters.withExpense;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/vendors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVendors200ResponseFromJSON(jsonValue));
    }

    /**
     * List vendors
     */
    async getVendors(requestParameters: GetVendorsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVendors200Response> {
        const response = await this.getVendorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View wall post
     */
    async getWallPostRaw(requestParameters: GetWallPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWallPost200Response>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling getWallPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * View wall post
     */
    async getWallPost(requestParameters: GetWallPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWallPost200Response> {
        const response = await this.getWallPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List wall posts
     */
    async getWallPostsRaw(requestParameters: GetWallPostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWallPosts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWallPosts200ResponseFromJSON(jsonValue));
    }

    /**
     * List wall posts
     */
    async getWallPosts(requestParameters: GetWallPostsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWallPosts200Response> {
        const response = await this.getWallPostsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Group offer lines by product and hours
     */
    async groupByProductAndHoursRaw(requestParameters: GroupByProductAndHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling groupByProductAndHours.');
        }

        if (requestParameters.offerLine === null || requestParameters.offerLine === undefined) {
            throw new runtime.RequiredError('offerLine','Required parameter requestParameters.offerLine was null or undefined when calling groupByProductAndHours.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines/group-by-product-and-hours`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.offerLine.map(OfferLineToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatherOfferLines200ResponseFromJSON(jsonValue));
    }

    /**
     * Group offer lines by product and hours
     */
    async groupByProductAndHours(requestParameters: GroupByProductAndHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response> {
        const response = await this.groupByProductAndHoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Company has certain integration type based on the identifier
     * Check if company has integration by identifier
     */
    async hasIntegrationOfTypeRaw(requestParameters: HasIntegrationOfTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HasIntegrationOfType200Response>> {
        if (requestParameters.identifier === null || requestParameters.identifier === undefined) {
            throw new runtime.RequiredError('identifier','Required parameter requestParameters.identifier was null or undefined when calling hasIntegrationOfType.');
        }

        const queryParameters: any = {};

        if (requestParameters.identifier !== undefined) {
            queryParameters['identifier'] = requestParameters.identifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/has-integration-type`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HasIntegrationOfType200ResponseFromJSON(jsonValue));
    }

    /**
     * Company has certain integration type based on the identifier
     * Check if company has integration by identifier
     */
    async hasIntegrationOfType(requestParameters: HasIntegrationOfTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HasIntegrationOfType200Response> {
        const response = await this.hasIntegrationOfTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create product bundle
     */
    async iCreateProductBundleRaw(requestParameters: ICreateProductBundleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICreateProductBundleRequestToJSON(requestParameters.iCreateProductBundleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create product bundle
     */
    async iCreateProductBundle(requestParameters: ICreateProductBundleOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iCreateProductBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create product bundle line
     */
    async iCreateProductBundleLineRaw(requestParameters: ICreateProductBundleLineOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling iCreateProductBundleLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/{productBundleId}/product-bundle-lines`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICreateProductBundleLineRequestToJSON(requestParameters.iCreateProductBundleLineRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create product bundle line
     */
    async iCreateProductBundleLine(requestParameters: ICreateProductBundleLineOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iCreateProductBundleLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create project
     */
    async iCreateProjectRaw(requestParameters: ICreateProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICreateProjectRequestToJSON(requestParameters.iCreateProjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create project
     */
    async iCreateProject(requestParameters: ICreateProjectOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iCreateProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create users price group
     */
    async iCreateUsersPriceGroupRaw(requestParameters: ICreateUsersPriceGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users-price-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsersPriceGroupToJSON(requestParameters.usersPriceGroup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create users price group
     */
    async iCreateUsersPriceGroup(requestParameters: ICreateUsersPriceGroupRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iCreateUsersPriceGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iCreateWallCommentRaw(requestParameters: ICreateWallCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling iCreateWallComment.');
        }

        if (requestParameters.iCreateWallCommentRequest === null || requestParameters.iCreateWallCommentRequest === undefined) {
            throw new runtime.RequiredError('iCreateWallCommentRequest','Required parameter requestParameters.iCreateWallCommentRequest was null or undefined when calling iCreateWallComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{postId}/comments`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ICreateWallCommentRequestToJSON(requestParameters.iCreateWallCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     */
    async iCreateWallComment(requestParameters: ICreateWallCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iCreateWallCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete card
     */
    async iDeleteCardRaw(requestParameters: IDeleteCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IDeleteCard200Response>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling iDeleteCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/cards/delete/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: IDeleteCardRequestToJSON(requestParameters.iDeleteCardRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IDeleteCard200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete card
     */
    async iDeleteCard(requestParameters: IDeleteCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IDeleteCard200Response> {
        const response = await this.iDeleteCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete offer
     */
    async iDeleteOfferRaw(requestParameters: IDeleteOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling iDeleteOffer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete offer
     */
    async iDeleteOffer(requestParameters: IDeleteOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.iDeleteOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete product bundle
     */
    async iDeleteProductBundleRaw(requestParameters: IDeleteProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling iDeleteProductBundle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/{productBundleId}`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete product bundle
     */
    async iDeleteProductBundle(requestParameters: IDeleteProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.iDeleteProductBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete project
     */
    async iDeleteProjectRaw(requestParameters: IDeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iDeleteProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete project
     */
    async iDeleteProject(requestParameters: IDeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.iDeleteProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iDeleteWallCommentRaw(requestParameters: IDeleteWallCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling iDeleteWallComment.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling iDeleteWallComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{postId}/comments/{commentId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async iDeleteWallComment(requestParameters: IDeleteWallCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.iDeleteWallCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete wall post
     */
    async iDeleteWallPostRaw(requestParameters: IDeleteWallPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling iDeleteWallPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete wall post
     */
    async iDeleteWallPost(requestParameters: IDeleteWallPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.iDeleteWallPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit product bundle
     */
    async iEditProductBundleRaw(requestParameters: IEditProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling iEditProductBundle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/{productBundleId}`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ICreateProductBundleRequestToJSON(requestParameters.iCreateProductBundleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Edit product bundle
     */
    async iEditProductBundle(requestParameters: IEditProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iEditProductBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit product bundle line
     */
    async iEditProductBundleLineRaw(requestParameters: IEditProductBundleLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling iEditProductBundleLine.');
        }

        if (requestParameters.productBundleLineId === null || requestParameters.productBundleLineId === undefined) {
            throw new runtime.RequiredError('productBundleLineId','Required parameter requestParameters.productBundleLineId was null or undefined when calling iEditProductBundleLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/{productBundleId}/product-bundle-lines/{productBundleLineId}`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))).replace(`{${"productBundleLineId"}}`, encodeURIComponent(String(requestParameters.productBundleLineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ICreateProductBundleLineRequestToJSON(requestParameters.iCreateProductBundleLineRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Edit product bundle line
     */
    async iEditProductBundleLine(requestParameters: IEditProductBundleLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iEditProductBundleLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit project
     */
    async iEditProjectRaw(requestParameters: IEditProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iEditProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ICreateProjectRequestToJSON(requestParameters.iCreateProjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit project
     */
    async iEditProject(requestParameters: IEditProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.iEditProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit users price group
     */
    async iEditUsersPriceGroupRaw(requestParameters: IEditUsersPriceGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.usersPriceGroupId === null || requestParameters.usersPriceGroupId === undefined) {
            throw new runtime.RequiredError('usersPriceGroupId','Required parameter requestParameters.usersPriceGroupId was null or undefined when calling iEditUsersPriceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users-price-groups/{usersPriceGroupId}`.replace(`{${"usersPriceGroupId"}}`, encodeURIComponent(String(requestParameters.usersPriceGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UsersPriceGroupToJSON(requestParameters.usersPriceGroup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Edit users price group
     */
    async iEditUsersPriceGroup(requestParameters: IEditUsersPriceGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iEditUsersPriceGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iEditWallCommentRaw(requestParameters: IEditWallCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling iEditWallComment.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling iEditWallComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{postId}/comments/{commentId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IEditWallCommentRequestToJSON(requestParameters.iEditWallCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async iEditWallComment(requestParameters: IEditWallCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.iEditWallCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update wall post
     */
    async iEditWallPostRaw(requestParameters: IEditWallPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling iEditWallPost.');
        }

        if (requestParameters.iEditWallPostRequest === null || requestParameters.iEditWallPostRequest === undefined) {
            throw new runtime.RequiredError('iEditWallPostRequest','Required parameter requestParameters.iEditWallPostRequest was null or undefined when calling iEditWallPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IEditWallPostRequestToJSON(requestParameters.iEditWallPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Update wall post
     */
    async iEditWallPost(requestParameters: IEditWallPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.iEditWallPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload files for form
     */
    async iFormUploadFilesRaw(requestParameters: IFormUploadFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IFormUploadFiles200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files[]', element as any);
            })
        }

        const response = await this.request({
            path: `/control-panel-api/v1/form-files/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IFormUploadFiles200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload files for form
     */
    async iFormUploadFiles(requestParameters: IFormUploadFilesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IFormUploadFiles200Response> {
        const response = await this.iFormUploadFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List activities
     */
    async iGetActivitiesRaw(requestParameters: IGetActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetActivities200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/activities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetActivities200ResponseFromJSON(jsonValue));
    }

    /**
     * List activities
     */
    async iGetActivities(requestParameters: IGetActivitiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetActivities200Response> {
        const response = await this.iGetActivitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get total and weekly product, working hours cost consumption
     */
    async iGetConsumptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetConsumption200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/frontpage/consumption`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetConsumption200ResponseFromJSON(jsonValue));
    }

    /**
     * Get total and weekly product, working hours cost consumption
     */
    async iGetConsumption(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetConsumption200Response> {
        const response = await this.iGetConsumptionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get recent feed
     */
    async iGetFeedRaw(requestParameters: IGetFeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetFeed200Response>> {
        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/frontpage/feed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetFeed200ResponseFromJSON(jsonValue));
    }

    /**
     * Get recent feed
     */
    async iGetFeed(requestParameters: IGetFeedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetFeed200Response> {
        const response = await this.iGetFeedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get feed for project
     */
    async iGetFeedForProjectRaw(requestParameters: IGetFeedForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlPanelApiProjectFeedResponseDTO>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iGetFeedForProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = (requestParameters.dateBefore as any).toISOString();
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = (requestParameters.dateAfter as any).toISOString();
        }

        if (requestParameters.type) {
            queryParameters['type[]'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/feed`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlPanelApiProjectFeedResponseDTOFromJSON(jsonValue));
    }

    /**
     * Get feed for project
     */
    async iGetFeedForProject(requestParameters: IGetFeedForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlPanelApiProjectFeedResponseDTO> {
        const response = await this.iGetFeedForProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get total and weekly invoiced and not invoiced amounts
     */
    async iGetFrontpageInvoicedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetFrontpageInvoiced200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/frontpage/invoiced`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetFrontpageInvoiced200ResponseFromJSON(jsonValue));
    }

    /**
     * Get total and weekly invoiced and not invoiced amounts
     */
    async iGetFrontpageInvoiced(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetFrontpageInvoiced200Response> {
        const response = await this.iGetFrontpageInvoicedRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get invoice
     */
    async iGetInvoiceRaw(requestParameters: IGetInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetInvoice200Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling iGetInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetInvoice200ResponseFromJSON(jsonValue));
    }

    /**
     * Get invoice
     */
    async iGetInvoice(requestParameters: IGetInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetInvoice200Response> {
        const response = await this.iGetInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return summed date grouped by offer statuses
     */
    async iGetOffersKpiCardsRaw(requestParameters: IGetOffersKpiCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetOffersKpiCards200Response>> {
        const queryParameters: any = {};

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/kpi-cards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetOffersKpiCards200ResponseFromJSON(jsonValue));
    }

    /**
     * Return summed date grouped by offer statuses
     */
    async iGetOffersKpiCards(requestParameters: IGetOffersKpiCardsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetOffersKpiCards200Response> {
        const response = await this.iGetOffersKpiCardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get project
     */
    async iGetProjectRaw(requestParameters: IGetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetProject200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iGetProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetProject200ResponseFromJSON(jsonValue));
    }

    /**
     * Get project
     */
    async iGetProject(requestParameters: IGetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetProject200Response> {
        const response = await this.iGetProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View task
     */
    async iGetTaskRaw(requestParameters: IGetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetTask200Response>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling iGetTask.');
        }

        const queryParameters: any = {};

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/tasks/{task_id}`.replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetTask200ResponseFromJSON(jsonValue));
    }

    /**
     * View task
     */
    async iGetTask(requestParameters: IGetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetTask200Response> {
        const response = await this.iGetTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get tasks for project
     */
    async iGetTasksForProjectRaw(requestParameters: IGetTasksForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetTasksForProject200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iGetTasksForProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/tasks`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetTasksForProject200ResponseFromJSON(jsonValue));
    }

    /**
     * Get tasks for project
     */
    async iGetTasksForProject(requestParameters: IGetTasksForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetTasksForProject200Response> {
        const response = await this.iGetTasksForProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iGetWallCommentRaw(requestParameters: IGetWallCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListWallComments200ResponseInner>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling iGetWallComment.');
        }

        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling iGetWallComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{postId}/comments/{commentId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IListWallComments200ResponseInnerFromJSON(jsonValue));
    }

    /**
     */
    async iGetWallComment(requestParameters: IGetWallCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListWallComments200ResponseInner> {
        const response = await this.iGetWallCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * work related data
     */
    async iGetWorkRelatedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IGetWorkRelated200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/frontpage/work-related`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IGetWorkRelated200ResponseFromJSON(jsonValue));
    }

    /**
     * work related data
     */
    async iGetWorkRelated(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IGetWorkRelated200Response> {
        const response = await this.iGetWorkRelatedRaw(initOverrides);
        return await response.value();
    }

    /**
     * Send invoice email
     */
    async iInvoiceSendEmailRaw(requestParameters: IInvoiceSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling iInvoiceSendEmail.');
        }

        if (requestParameters.recipientEmail === null || requestParameters.recipientEmail === undefined) {
            throw new runtime.RequiredError('recipientEmail','Required parameter requestParameters.recipientEmail was null or undefined when calling iInvoiceSendEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.recipientEmail !== undefined) {
            formParams.append('recipient_email', requestParameters.recipientEmail as any);
        }

        if (requestParameters.subject !== undefined) {
            formParams.append('subject', requestParameters.subject as any);
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices/{invoice_id}/emails`.replace(`{${"invoice_id"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProductBundleUploadFile200ResponseFromJSON(jsonValue));
    }

    /**
     * Send invoice email
     */
    async iInvoiceSendEmail(requestParameters: IInvoiceSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response> {
        const response = await this.iInvoiceSendEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List invoices
     */
    async iListInvoicesRaw(requestParameters: IListInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListInvoices200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        if (requestParameters.invoiceNumber !== undefined) {
            queryParameters['invoice_number'] = requestParameters.invoiceNumber;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.issuedDate !== undefined) {
            queryParameters['issued_date'] = (requestParameters.issuedDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.projectName !== undefined) {
            queryParameters['project_name'] = requestParameters.projectName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IListInvoices200ResponseFromJSON(jsonValue));
    }

    /**
     * List invoices
     */
    async iListInvoices(requestParameters: IListInvoicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListInvoices200Response> {
        const response = await this.iListInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Offer Statuses
     */
    async iListOfferStatusesRaw(requestParameters: IListOfferStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListOfferStatuses200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.identifier !== undefined) {
            queryParameters['identifier'] = requestParameters.identifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offer-statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IListOfferStatuses200ResponseFromJSON(jsonValue));
    }

    /**
     * List Offer Statuses
     */
    async iListOfferStatuses(requestParameters: IListOfferStatusesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListOfferStatuses200Response> {
        const response = await this.iListOfferStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Project Statuses
     */
    async iListProjectStatusesRaw(requestParameters: IListProjectStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListProjectStatuses200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.identifier !== undefined) {
            queryParameters['identifier'] = requestParameters.identifier;
        }

        if (requestParameters.isCustom !== undefined) {
            queryParameters['is_custom'] = requestParameters.isCustom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/project-statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IListProjectStatuses200ResponseFromJSON(jsonValue));
    }

    /**
     * List Project Statuses
     */
    async iListProjectStatuses(requestParameters: IListProjectStatusesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListProjectStatuses200Response> {
        const response = await this.iListProjectStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List projects
     */
    async iListProjectsRaw(requestParameters: IListProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListProjects200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.isOffer !== undefined) {
            queryParameters['is_offer'] = requestParameters.isOffer;
        }

        if (requestParameters.projectStatusId !== undefined) {
            queryParameters['project_status_id'] = requestParameters.projectStatusId;
        }

        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids'] = requestParameters.activityIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.projectStatus !== undefined) {
            queryParameters['project_status'] = requestParameters.projectStatus;
        }

        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }

        if (requestParameters.employeeIds) {
            queryParameters['employee_ids[]'] = requestParameters.employeeIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IListProjects200ResponseFromJSON(jsonValue));
    }

    /**
     * List projects
     */
    async iListProjects(requestParameters: IListProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListProjects200Response> {
        const response = await this.iListProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List tasks
     */
    async iListTasksRaw(requestParameters: IListTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IListTasks200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IListTasks200ResponseFromJSON(jsonValue));
    }

    /**
     * List tasks
     */
    async iListTasks(requestParameters: IListTasksRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IListTasks200Response> {
        const response = await this.iListTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iListWallCommentsRaw(requestParameters: IListWallCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IListWallComments200ResponseInner>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{postId}/comments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IListWallComments200ResponseInnerFromJSON));
    }

    /**
     */
    async iListWallComments(requestParameters: IListWallCommentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IListWallComments200ResponseInner>> {
        const response = await this.iListWallCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send offer email
     */
    async iOfferSendEmailRaw(requestParameters: IOfferSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling iOfferSendEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.recipientEmail !== undefined) {
            formParams.append('recipient_email', requestParameters.recipientEmail as any);
        }

        if (requestParameters.subject !== undefined) {
            formParams.append('subject', requestParameters.subject as any);
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        if (requestParameters.extraFiles) {
            requestParameters.extraFiles.forEach((element) => {
                formParams.append('extra_files', element as any);
            })
        }

        if (requestParameters.pdf !== undefined) {
            formParams.append('pdf', requestParameters.pdf as any);
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/emails`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProductBundleUploadFile200ResponseFromJSON(jsonValue));
    }

    /**
     * Send offer email
     */
    async iOfferSendEmail(requestParameters: IOfferSendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response> {
        const response = await this.iOfferSendEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload offer file
     */
    async iOfferUploadFileRaw(requestParameters: IOfferUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling iOfferUploadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files[]', element as any);
            })
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/files`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProductBundleUploadFile200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload offer file
     */
    async iOfferUploadFile(requestParameters: IOfferUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response> {
        const response = await this.iOfferUploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate product bundle
     */
    async iProductBundleDuplicateRaw(requestParameters: IProductBundleDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling iProductBundleDuplicate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/duplicate/{productBundleId}`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProductBundleUploadFile200ResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate product bundle
     */
    async iProductBundleDuplicate(requestParameters: IProductBundleDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response> {
        const response = await this.iProductBundleDuplicateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set sales price based on contribution margin
     */
    async iProductBundleSetSalesPriceRaw(requestParameters: IProductBundleSetSalesPriceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleSetSalesPrice200Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling iProductBundleSetSalesPrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/set-sales-price/{productBundleId}`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IProductBundleSetSalesPriceRequestToJSON(requestParameters.iProductBundleSetSalesPriceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProductBundleSetSalesPrice200ResponseFromJSON(jsonValue));
    }

    /**
     * Set sales price based on contribution margin
     */
    async iProductBundleSetSalesPrice(requestParameters: IProductBundleSetSalesPriceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleSetSalesPrice200Response> {
        const response = await this.iProductBundleSetSalesPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a file to product bundle
     */
    async iProductBundleUploadFileRaw(requestParameters: IProductBundleUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling iProductBundleUploadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/add-file/{productBundleId}`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProductBundleUploadFile200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload a file to product bundle
     */
    async iProductBundleUploadFile(requestParameters: IProductBundleUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response> {
        const response = await this.iProductBundleUploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iProjectDeleteFileRaw(requestParameters: IProjectDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iProjectDeleteFile.');
        }

        if (requestParameters.iProjectDeleteFileRequestInner === null || requestParameters.iProjectDeleteFileRequestInner === undefined) {
            throw new runtime.RequiredError('iProjectDeleteFileRequestInner','Required parameter requestParameters.iProjectDeleteFileRequestInner was null or undefined when calling iProjectDeleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/files`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.iProjectDeleteFileRequestInner.map(IProjectDeleteFileRequestInnerToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async iProjectDeleteFile(requestParameters: IProjectDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.iProjectDeleteFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List project files
     */
    async iProjectListFilesRaw(requestParameters: IProjectListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProjectListFiles200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iProjectListFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/files`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProjectListFiles200ResponseFromJSON(jsonValue));
    }

    /**
     * List project files
     */
    async iProjectListFiles(requestParameters: IProjectListFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProjectListFiles200Response> {
        const response = await this.iProjectListFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send one or more project files by email
     */
    async iProjectSendFilesRaw(requestParameters: IProjectSendFilesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iProjectSendFiles.');
        }

        if (requestParameters.iProjectSendFilesRequest === null || requestParameters.iProjectSendFilesRequest === undefined) {
            throw new runtime.RequiredError('iProjectSendFilesRequest','Required parameter requestParameters.iProjectSendFilesRequest was null or undefined when calling iProjectSendFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/files/send`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IProjectSendFilesRequestToJSON(requestParameters.iProjectSendFilesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Send one or more project files by email
     */
    async iProjectSendFiles(requestParameters: IProjectSendFilesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.iProjectSendFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a project file
     */
    async iProjectUploadFileRaw(requestParameters: IProjectUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iProjectUploadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/files`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProductBundleUploadFile200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload a project file
     */
    async iProjectUploadFile(requestParameters: IProjectUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response> {
        const response = await this.iProjectUploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send one or more project forms by email
     */
    async iSendProjectFormsRaw(requestParameters: ISendProjectFormsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iSendProjectForms.');
        }

        if (requestParameters.iSendProjectFormsRequest === null || requestParameters.iSendProjectFormsRequest === undefined) {
            throw new runtime.RequiredError('iSendProjectFormsRequest','Required parameter requestParameters.iSendProjectFormsRequest was null or undefined when calling iSendProjectForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/send/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ISendProjectFormsRequestToJSON(requestParameters.iSendProjectFormsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISendProjectForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Send one or more project forms by email
     */
    async iSendProjectForms(requestParameters: ISendProjectFormsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response> {
        const response = await this.iSendProjectFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iStandardOfferFileDeleteRaw(requestParameters: IStandardOfferFileDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.standardOfferFileId === null || requestParameters.standardOfferFileId === undefined) {
            throw new runtime.RequiredError('standardOfferFileId','Required parameter requestParameters.standardOfferFileId was null or undefined when calling iStandardOfferFileDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/standard-offer-files/{standard_offer_file_id}`.replace(`{${"standard_offer_file_id"}}`, encodeURIComponent(String(requestParameters.standardOfferFileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async iStandardOfferFileDelete(requestParameters: IStandardOfferFileDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.iStandardOfferFileDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload standard offer file
     */
    async iStandardOfferFileUploadRaw(requestParameters: IStandardOfferFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IProductBundleUploadFile200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        const response = await this.request({
            path: `/control-panel-api/v1/standard-offer-files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IProductBundleUploadFile200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload standard offer file
     */
    async iStandardOfferFileUpload(requestParameters: IStandardOfferFileUploadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IProductBundleUploadFile200Response> {
        const response = await this.iStandardOfferFileUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List standard offer files
     */
    async iStandardOfferFilesListRaw(requestParameters: IStandardOfferFilesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFilesList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/standard-offer-files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFilesList200ResponseFromJSON(jsonValue));
    }

    /**
     * List standard offer files
     */
    async iStandardOfferFilesList(requestParameters: IStandardOfferFilesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFilesList200Response> {
        const response = await this.iStandardOfferFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iWallpostAttachFileRaw(requestParameters: IWallpostAttachFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling iWallpostAttachFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{projectId}/files`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     */
    async iWallpostAttachFile(requestParameters: IWallpostAttachFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iWallpostAttachFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create wall post
     */
    async iWallpostCreateRaw(requestParameters: IWallpostCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling iWallpostCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.projectId !== undefined) {
            formParams.append('project_id', requestParameters.projectId as any);
        }

        if (requestParameters.message !== undefined) {
            formParams.append('message', requestParameters.message as any);
        }

        if (requestParameters.postFiles) {
            requestParameters.postFiles.forEach((element) => {
                formParams.append('post_files[]', element as any);
            })
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create wall post
     */
    async iWallpostCreate(requestParameters: IWallpostCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.iWallpostCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iWallpostDeleteFileRaw(requestParameters: IWallpostDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostDeleteFile200Response>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling iWallpostDeleteFile.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling iWallpostDeleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/wall-posts/{projectId}/files`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostDeleteFile200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async iWallpostDeleteFile(requestParameters: IWallpostDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostDeleteFile200Response> {
        const response = await this.iWallpostDeleteFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add product bundle to invoice
     */
    async invoiceAddProductBundleRaw(requestParameters: InvoiceAddProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling invoiceAddProductBundle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/invoices/add-product-bundle/{invoice_id}`.replace(`{${"invoice_id"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferAddProductBundleRequestToJSON(requestParameters.offerAddProductBundleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Add product bundle to invoice
     */
    async invoiceAddProductBundle(requestParameters: InvoiceAddProductBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.invoiceAddProductBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View single invoice
     */
    async invoicesViewRaw(requestParameters: InvoicesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesView200Response>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling invoicesView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/invoices/{invoice_id}`.replace(`{${"invoice_id"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicesView200ResponseFromJSON(jsonValue));
    }

    /**
     * View single invoice
     */
    async invoicesView(requestParameters: InvoicesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesView200Response> {
        const response = await this.invoicesViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Is current user checked in
     */
    async isCheckedInRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsCheckedIn200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/clocking-records/is_checked_in`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsCheckedIn200ResponseFromJSON(jsonValue));
    }

    /**
     * Is current user checked in
     */
    async isCheckedIn(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsCheckedIn200Response> {
        const response = await this.isCheckedInRaw(initOverrides);
        return await response.value();
    }

    /**
     * List users
     */
    async listUsersRaw(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsers200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['is_active'] = requestParameters.isActive;
        }

        if (requestParameters.labels) {
            queryParameters['labels[]'] = requestParameters.labels;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsers200ResponseFromJSON(jsonValue));
    }

    /**
     * List users
     */
    async listUsers(requestParameters: ListUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsers200Response> {
        const response = await this.listUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Log in with email/password
     */
    async loginWithEmailRaw(requestParameters: LoginWithEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginWithEmail200Response>> {
        if (requestParameters.loginWithEmailRequest === null || requestParameters.loginWithEmailRequest === undefined) {
            throw new runtime.RequiredError('loginWithEmailRequest','Required parameter requestParameters.loginWithEmailRequest was null or undefined when calling loginWithEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/apiKey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithEmailRequestToJSON(requestParameters.loginWithEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginWithEmail200ResponseFromJSON(jsonValue));
    }

    /**
     * Log in with email/password
     */
    async loginWithEmail(requestParameters: LoginWithEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginWithEmail200Response> {
        const response = await this.loginWithEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lookupCVRRaw(requestParameters: LookupCVRRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LookupCVR200Response>> {
        const queryParameters: any = {};

        if (requestParameters.cvr !== undefined) {
            queryParameters['cvr'] = requestParameters.cvr;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/cvr_look_up`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookupCVR200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async lookupCVR(requestParameters: LookupCVRRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LookupCVR200Response> {
        const response = await this.lookupCVRRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark all registrations as invoiced
     */
    async markAsInvoicedRaw(requestParameters: MarkAsInvoicedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling markAsInvoiced.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/registrations/mark-as-invoiced`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Mark all registrations as invoiced
     */
    async markAsInvoiced(requestParameters: MarkAsInvoicedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.markAsInvoicedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger background job to merge projects - can take a while depending on amount of content to move
     * Merge projects
     */
    async mergeProjectsRaw(requestParameters: MergeProjectsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/merge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeProjectsRequestToJSON(requestParameters.mergeProjectsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Trigger background job to merge projects - can take a while depending on amount of content to move
     * Merge projects
     */
    async mergeProjects(requestParameters: MergeProjectsOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.mergeProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Accept offer
     */
    async offerAcceptRaw(requestParameters: OfferAcceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferAcceptRequestToJSON(requestParameters.offerAcceptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Accept offer
     */
    async offerAccept(requestParameters: OfferAcceptOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.offerAcceptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add product bundle to offer
     */
    async offerAddProductBundleRaw(requestParameters: OfferAddProductBundleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling offerAddProductBundle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/add-product-bundle/{offer_id}`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferAddProductBundleRequestToJSON(requestParameters.offerAddProductBundleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Add product bundle to offer
     */
    async offerAddProductBundle(requestParameters: OfferAddProductBundleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.offerAddProductBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject offer
     */
    async offerRejectRaw(requestParameters: OfferRejectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditContact200Response>> {
        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfferAcceptRequestToJSON(requestParameters.offerAcceptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditContact200ResponseFromJSON(jsonValue));
    }

    /**
     * Reject offer
     */
    async offerReject(requestParameters: OfferRejectRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditContact200Response> {
        const response = await this.offerRejectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List offers
     */
    async offersListRaw(requestParameters: OffersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OffersList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.offerStatusId !== undefined) {
            queryParameters['offer_status_id'] = requestParameters.offerStatusId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OffersList200ResponseFromJSON(jsonValue));
    }

    /**
     * List offers
     */
    async offersList(requestParameters: OffersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OffersList200Response> {
        const response = await this.offersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View single offer
     */
    async offersViewRaw(requestParameters: OffersViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OffersView200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling offersView.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OffersView200ResponseFromJSON(jsonValue));
    }

    /**
     * View single offer
     */
    async offersView(requestParameters: OffersViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OffersView200Response> {
        const response = await this.offersViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View productBundleLine
     */
    async productBundleLineViewRaw(requestParameters: ProductBundleLineViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleLineView200Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling productBundleLineView.');
        }

        if (requestParameters.productBundleLineId === null || requestParameters.productBundleLineId === undefined) {
            throw new runtime.RequiredError('productBundleLineId','Required parameter requestParameters.productBundleLineId was null or undefined when calling productBundleLineView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/{productBundleId}/product-bundle-lines/{productBundleLineId}`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))).replace(`{${"productBundleLineId"}}`, encodeURIComponent(String(requestParameters.productBundleLineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundleLineView200ResponseFromJSON(jsonValue));
    }

    /**
     * View productBundleLine
     */
    async productBundleLineView(requestParameters: ProductBundleLineViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleLineView200Response> {
        const response = await this.productBundleLineViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List product bundle lines
     */
    async productBundleLinesListRaw(requestParameters: ProductBundleLinesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundleLinesList200Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling productBundleLinesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/{productBundleId}/product-bundle-lines`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundleLinesList200ResponseFromJSON(jsonValue));
    }

    /**
     * List product bundle lines
     */
    async productBundleLinesList(requestParameters: ProductBundleLinesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundleLinesList200Response> {
        const response = await this.productBundleLinesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get numbers for the product bundle KPI cards
     */
    async productBundlesGetKpiCardsDataRaw(requestParameters: ProductBundlesGetKpiCardsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundlesGetKpiCardsData200Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling productBundlesGetKpiCardsData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/{productBundleId}/kpi`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundlesGetKpiCardsData200ResponseFromJSON(jsonValue));
    }

    /**
     * Get numbers for the product bundle KPI cards
     */
    async productBundlesGetKpiCardsData(requestParameters: ProductBundlesGetKpiCardsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundlesGetKpiCardsData200Response> {
        const response = await this.productBundlesGetKpiCardsDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List product bundles
     */
    async productBundlesListRaw(requestParameters: ProductBundlesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundlesList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.activityIds !== undefined) {
            queryParameters['activity_ids'] = requestParameters.activityIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundlesList200ResponseFromJSON(jsonValue));
    }

    /**
     * List product bundles
     */
    async productBundlesList(requestParameters: ProductBundlesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundlesList200Response> {
        const response = await this.productBundlesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View productBundle
     */
    async productBundlesViewRaw(requestParameters: ProductBundlesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductBundlesView200Response>> {
        if (requestParameters.productBundleId === null || requestParameters.productBundleId === undefined) {
            throw new runtime.RequiredError('productBundleId','Required parameter requestParameters.productBundleId was null or undefined when calling productBundlesView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/product-bundles/{productBundleId}`.replace(`{${"productBundleId"}}`, encodeURIComponent(String(requestParameters.productBundleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductBundlesView200ResponseFromJSON(jsonValue));
    }

    /**
     * View productBundle
     */
    async productBundlesView(requestParameters: ProductBundlesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductBundlesView200Response> {
        const response = await this.productBundlesViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get products(expense lines, vendor products) pricing history
     */
    async productPriceHistoryRaw(requestParameters: ProductPriceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductPriceHistory200Response>> {
        const queryParameters: any = {};

        if (requestParameters.expenseLineId !== undefined) {
            queryParameters['expense_line_id'] = requestParameters.expenseLineId;
        }

        if (requestParameters.vendorProductId !== undefined) {
            queryParameters['vendor_product_id'] = requestParameters.vendorProductId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products/get-price-history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductPriceHistory200ResponseFromJSON(jsonValue));
    }

    /**
     * Get products(expense lines, vendor products) pricing history
     */
    async productPriceHistory(requestParameters: ProductPriceHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductPriceHistory200Response> {
        const response = await this.productPriceHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get product variants
     */
    async productVariantsRaw(requestParameters: ProductVariantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductVariants200Response>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling productVariants.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['product_id'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products/get-variants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductVariants200ResponseFromJSON(jsonValue));
    }

    /**
     * Get product variants
     */
    async productVariants(requestParameters: ProductVariantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductVariants200Response> {
        const response = await this.productVariantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List products
     */
    async productsListRaw(requestParameters: ProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductsList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductsList200ResponseFromJSON(jsonValue));
    }

    /**
     * List products
     */
    async productsList(requestParameters: ProductsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductsList200Response> {
        const response = await this.productsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View product
     */
    async productsViewRaw(requestParameters: ProductsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductsView200Response>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling productsView.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeVariants !== undefined) {
            queryParameters['includeVariants'] = requestParameters.includeVariants;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products/{product_id}`.replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductsView200ResponseFromJSON(jsonValue));
    }

    /**
     * View product
     */
    async productsView(requestParameters: ProductsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductsView200Response> {
        const response = await this.productsViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for page behind \"Economy\" KPI card
     */
    async projectGetKpiCardEconomySubPageRaw(requestParameters: ProjectGetKpiCardEconomySubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardEconomySubPage200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardEconomySubPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = requestParameters.dateBefore;
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = requestParameters.dateAfter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards-economy`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardEconomySubPage200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for page behind \"Economy\" KPI card
     */
    async projectGetKpiCardEconomySubPage(requestParameters: ProjectGetKpiCardEconomySubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardEconomySubPage200Response> {
        const response = await this.projectGetKpiCardEconomySubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for hours KPI card page - all forms tab
     */
    async projectGetKpiCardHoursAllFormsSubPageRaw(requestParameters: ProjectGetKpiCardHoursAllFormsSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursAllFormsSubPage200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardHoursAllFormsSubPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = requestParameters.dateBefore;
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = requestParameters.dateAfter;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.isInvoiced !== undefined) {
            queryParameters['is_invoiced'] = requestParameters.isInvoiced;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards-hours/all-forms`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardHoursAllFormsSubPage200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for hours KPI card page - all forms tab
     */
    async projectGetKpiCardHoursAllFormsSubPage(requestParameters: ProjectGetKpiCardHoursAllFormsSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursAllFormsSubPage200Response> {
        const response = await this.projectGetKpiCardHoursAllFormsSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for hours KPI card page - pre calculation tab
     */
    async projectGetKpiCardHoursPreCalculationSubPageRaw(requestParameters: ProjectGetKpiCardHoursPreCalculationSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursPreCalculationSubPage200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardHoursPreCalculationSubPage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards-hours/pre-calculation`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardHoursPreCalculationSubPage200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for hours KPI card page - pre calculation tab
     */
    async projectGetKpiCardHoursPreCalculationSubPage(requestParameters: ProjectGetKpiCardHoursPreCalculationSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursPreCalculationSubPage200Response> {
        const response = await this.projectGetKpiCardHoursPreCalculationSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for hours KPI card page - `Summed by User price group` tab
     */
    async projectGetKpiCardHoursSummedByUserPriceGroupSubPageRaw(requestParameters: ProjectGetKpiCardHoursSummedByUserPriceGroupSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardHoursSummedByUserPriceGroupSubPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = requestParameters.dateBefore;
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = requestParameters.dateAfter;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.isInvoiced !== undefined) {
            queryParameters['is_invoiced'] = requestParameters.isInvoiced;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards-hours/summed-by-user-price-group`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for hours KPI card page - `Summed by User price group` tab
     */
    async projectGetKpiCardHoursSummedByUserPriceGroupSubPage(requestParameters: ProjectGetKpiCardHoursSummedByUserPriceGroupSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200Response> {
        const response = await this.projectGetKpiCardHoursSummedByUserPriceGroupSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for hours KPI card page - summed tab
     */
    async projectGetKpiCardHoursSummedSubPageRaw(requestParameters: ProjectGetKpiCardHoursSummedSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursSummedSubPage200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardHoursSummedSubPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = (requestParameters.dateBefore as any).toISOString();
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = (requestParameters.dateAfter as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards-hours/summed`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardHoursSummedSubPage200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for hours KPI card page - summed tab
     */
    async projectGetKpiCardHoursSummedSubPage(requestParameters: ProjectGetKpiCardHoursSummedSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursSummedSubPage200Response> {
        const response = await this.projectGetKpiCardHoursSummedSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for page behind \"Invoices\" KPI card
     */
    async projectGetKpiCardInvoicesSubPageRaw(requestParameters: ProjectGetKpiCardInvoicesSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardInvoicesSubPage200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardInvoicesSubPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = requestParameters.dateBefore;
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = requestParameters.dateAfter;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards-invoices`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardInvoicesSubPage200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for page behind \"Invoices\" KPI card
     */
    async projectGetKpiCardInvoicesSubPage(requestParameters: ProjectGetKpiCardInvoicesSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardInvoicesSubPage200Response> {
        const response = await this.projectGetKpiCardInvoicesSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for products KPI card page - pre calculation tab
     */
    async projectGetKpiCardProductsPreCalculationSubPageRaw(requestParameters: ProjectGetKpiCardProductsPreCalculationSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardProductsPreCalculationSubPage200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardProductsPreCalculationSubPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards-products/pre-calculation`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardProductsPreCalculationSubPage200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for products KPI card page - pre calculation tab
     */
    async projectGetKpiCardProductsPreCalculationSubPage(requestParameters: ProjectGetKpiCardProductsPreCalculationSubPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardProductsPreCalculationSubPage200Response> {
        const response = await this.projectGetKpiCardProductsPreCalculationSubPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for products KPI card page - products tab
     */
    async projectGetKpiCardProductsSubpageProductsTabRaw(requestParameters: ProjectGetKpiCardProductsSubpageProductsTabRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardProductsSubpageProductsTab200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardProductsSubpageProductsTab.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = requestParameters.dateBefore;
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = requestParameters.dateAfter;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.isInvoiced !== undefined) {
            queryParameters['is_invoiced'] = requestParameters.isInvoiced;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards-products/products`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardProductsSubpageProductsTab200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for products KPI card page - products tab
     */
    async projectGetKpiCardProductsSubpageProductsTab(requestParameters: ProjectGetKpiCardProductsSubpageProductsTabRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardProductsSubpageProductsTab200Response> {
        const response = await this.projectGetKpiCardProductsSubpageProductsTabRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get numbers for the projet KPI cards
     */
    async projectGetKpiCardsDataRaw(requestParameters: ProjectGetKpiCardsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardsData200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectGetKpiCardsData.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = requestParameters.dateBefore;
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = requestParameters.dateAfter;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.isInvoiced !== undefined) {
            queryParameters['is_invoiced'] = requestParameters.isInvoiced;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/kpi-cards`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardsData200ResponseFromJSON(jsonValue));
    }

    /**
     * Get numbers for the projet KPI cards
     */
    async projectGetKpiCardsData(requestParameters: ProjectGetKpiCardsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardsData200Response> {
        const response = await this.projectGetKpiCardsDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request a password reset with email/mobile
     */
    async requestResetPasswordRaw(requestParameters: RequestResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RequestResetPassword200Response>> {
        if (requestParameters.requestResetPasswordRequest === null || requestParameters.requestResetPasswordRequest === undefined) {
            throw new runtime.RequiredError('requestResetPasswordRequest','Required parameter requestParameters.requestResetPasswordRequest was null or undefined when calling requestResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/control-panel-api/v1/login/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestResetPasswordRequestToJSON(requestParameters.requestResetPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestResetPassword200ResponseFromJSON(jsonValue));
    }

    /**
     * Request a password reset with email/mobile
     */
    async requestResetPassword(requestParameters: RequestResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RequestResetPassword200Response> {
        const response = await this.requestResetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend welcome sms to user if he have mobile country code and mobile
     */
    async resendWelcomeSmsRaw(requestParameters: ResendWelcomeSmsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling resendWelcomeSms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/resend-welcome-sms-to-user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardsDeleteMany200ResponseFromJSON(jsonValue));
    }

    /**
     * Resend welcome sms to user if he have mobile country code and mobile
     */
    async resendWelcomeSms(requestParameters: ResendWelcomeSmsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response> {
        const response = await this.resendWelcomeSmsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends a password reset email
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/reset_password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISendProjectForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Sends a password reset email
     */
    async resetPassword(requestParameters: ResetPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reverse address lookup
     */
    async reverseLookupRaw(requestParameters: ReverseLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReverseLookup200Response>> {
        if (requestParameters.latitude === null || requestParameters.latitude === undefined) {
            throw new runtime.RequiredError('latitude','Required parameter requestParameters.latitude was null or undefined when calling reverseLookup.');
        }

        if (requestParameters.longitude === null || requestParameters.longitude === undefined) {
            throw new runtime.RequiredError('longitude','Required parameter requestParameters.longitude was null or undefined when calling reverseLookup.');
        }

        const queryParameters: any = {};

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/geolocation/reverse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReverseLookup200ResponseFromJSON(jsonValue));
    }

    /**
     * Reverse address lookup
     */
    async reverseLookup(requestParameters: ReverseLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReverseLookup200Response> {
        const response = await this.reverseLookupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send a summary report based on the type
     */
    async sendReportRaw(requestParameters: SendReportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling sendReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/reports/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendReportRequestToJSON(requestParameters.sendReportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISendProjectForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Send a summary report based on the type
     */
    async sendReport(requestParameters: SendReportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response> {
        const response = await this.sendReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set variant as primary
     */
    async setPrimaryVariantRaw(requestParameters: SetPrimaryVariantOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/products/set-primary-variant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPrimaryVariantRequestToJSON(requestParameters.setPrimaryVariantRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Set variant as primary
     */
    async setPrimaryVariant(requestParameters: SetPrimaryVariantOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.setPrimaryVariantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Only Product Bundles
     */
    async showOnlyProductBundlesRaw(requestParameters: ShowOnlyProductBundlesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatherOfferLines200Response>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling showOnlyProductBundles.');
        }

        if (requestParameters.offerLine === null || requestParameters.offerLine === undefined) {
            throw new runtime.RequiredError('offerLine','Required parameter requestParameters.offerLine was null or undefined when calling showOnlyProductBundles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/offers/{offer_id}/offer-lines/show-only-product-bundles`.replace(`{${"offer_id"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.offerLine.map(OfferLineToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatherOfferLines200ResponseFromJSON(jsonValue));
    }

    /**
     * Show Only Product Bundles
     */
    async showOnlyProductBundles(requestParameters: ShowOnlyProductBundlesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatherOfferLines200Response> {
        const response = await this.showOnlyProductBundlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Undelete deleted form
     */
    async undeleteFormRaw(requestParameters: UndeleteFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling undeleteForm.');
        }

        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling undeleteForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/{projectId}/forms/{formId}/undelete`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISendProjectForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Undelete deleted form
     */
    async undeleteForm(requestParameters: UndeleteFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response> {
        const response = await this.undeleteFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a cards list_placement and reindex other cards for same date if necessary
     * Bulk-update cards
     */
    async updateCardListingRaw(requestParameters: UpdateCardListingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCardListing200Response>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling updateCardListing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/cards/update-card-listing/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCardListingRequestToJSON(requestParameters.updateCardListingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCardListing200ResponseFromJSON(jsonValue));
    }

    /**
     * Update a cards list_placement and reindex other cards for same date if necessary
     * Bulk-update cards
     */
    async updateCardListing(requestParameters: UpdateCardListingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCardListing200Response> {
        const response = await this.updateCardListingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a form with form fields
     * update form
     */
    async updateFormRaw(requestParameters: UpdateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IWallpostCreate201Response>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling updateForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/forms/{form_id}`.replace(`{${"form_id"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFormRequestToJSON(requestParameters.createFormRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IWallpostCreate201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a form with form fields
     * update form
     */
    async updateForm(requestParameters: UpdateFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IWallpostCreate201Response> {
        const response = await this.updateFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a user - integration setting value
     * Update user integration setting value
     */
    async updateUserIntegrationSettingRaw(requestParameters: UpdateUserIntegrationSettingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardsDeleteMany200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUserIntegrationSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/integration-settings/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserIntegrationSettingRequestToJSON(requestParameters.updateUserIntegrationSettingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardsDeleteMany200ResponseFromJSON(jsonValue));
    }

    /**
     * Update a user - integration setting value
     * Update user integration setting value
     */
    async updateUserIntegrationSetting(requestParameters: UpdateUserIntegrationSettingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardsDeleteMany200Response> {
        const response = await this.updateUserIntegrationSettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload image to a specific user
     */
    async uploadUserImageRaw(requestParameters: UploadUserImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling uploadUserImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/upload-image/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload image to a specific user
     */
    async uploadUserImage(requestParameters: UploadUserImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.uploadUserImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allow/disallow employees to access project
     */
    async usersAccessToProjectRaw(requestParameters: UsersAccessToProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IStandardOfferFileDelete200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling usersAccessToProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/users-access/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IStandardOfferFileDelete200ResponseFromJSON(jsonValue));
    }

    /**
     * Allow/disallow employees to access project
     */
    async usersAccessToProject(requestParameters: UsersAccessToProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IStandardOfferFileDelete200Response> {
        const response = await this.usersAccessToProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for employees page
     */
    async usersGetKpiCardsRaw(requestParameters: UsersGetKpiCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersGetKpiCards200Response>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.employeeIds) {
            queryParameters['employee_ids[]'] = requestParameters.employeeIds;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/kpi-cards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersGetKpiCards200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for employees page
     */
    async usersGetKpiCards(requestParameters: UsersGetKpiCardsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersGetKpiCards200Response> {
        const response = await this.usersGetKpiCardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for specified tab in employees page
     */
    async usersGetSpecifiedRaw(requestParameters: UsersGetSpecifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersGetSpecified200Response>> {
        const queryParameters: any = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        if (requestParameters.employeeIds) {
            queryParameters['employee_ids[]'] = requestParameters.employeeIds;
        }

        if (requestParameters.approved !== undefined) {
            queryParameters['approved'] = requestParameters.approved;
        }

        if (requestParameters.invoiced !== undefined) {
            queryParameters['invoiced'] = requestParameters.invoiced;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/specified`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersGetSpecified200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for specified tab in employees page
     */
    async usersGetSpecified(requestParameters: UsersGetSpecifiedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersGetSpecified200Response> {
        const response = await this.usersGetSpecifiedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for employees - summed tab
     */
    async usersGetSummedRaw(requestParameters: UsersGetSummedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectGetKpiCardHoursSummedSubPage200Response>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.employeeIds) {
            queryParameters['employee_ids[]'] = requestParameters.employeeIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/summed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGetKpiCardHoursSummedSubPage200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for employees - summed tab
     */
    async usersGetSummed(requestParameters: UsersGetSummedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectGetKpiCardHoursSummedSubPage200Response> {
        const response = await this.usersGetSummedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data for employees - weekly tab
     */
    async usersGetWeeklyRaw(requestParameters: UsersGetWeeklyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersGetWeekly200Response>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.employeeIds) {
            queryParameters['employee_ids[]'] = requestParameters.employeeIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/weekly`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersGetWeekly200ResponseFromJSON(jsonValue));
    }

    /**
     * Get data for employees - weekly tab
     */
    async usersGetWeekly(requestParameters: UsersGetWeeklyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersGetWeekly200Response> {
        const response = await this.usersGetWeeklyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List users price groups
     */
    async usersPriceGroupsListRaw(requestParameters: UsersPriceGroupsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersPriceGroupsList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users-price-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersPriceGroupsList200ResponseFromJSON(jsonValue));
    }

    /**
     * List users price groups
     */
    async usersPriceGroupsList(requestParameters: UsersPriceGroupsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersPriceGroupsList200Response> {
        const response = await this.usersPriceGroupsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View users price group
     */
    async usersPriceGroupsViewRaw(requestParameters: UsersPriceGroupsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersPriceGroupsView200Response>> {
        if (requestParameters.usersPriceGroupId === null || requestParameters.usersPriceGroupId === undefined) {
            throw new runtime.RequiredError('usersPriceGroupId','Required parameter requestParameters.usersPriceGroupId was null or undefined when calling usersPriceGroupsView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users-price-groups/{usersPriceGroupId}`.replace(`{${"usersPriceGroupId"}}`, encodeURIComponent(String(requestParameters.usersPriceGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersPriceGroupsView200ResponseFromJSON(jsonValue));
    }

    /**
     * View users price group
     */
    async usersPriceGroupsView(requestParameters: UsersPriceGroupsViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersPriceGroupsView200Response> {
        const response = await this.usersPriceGroupsViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View user
     */
    async usersViewRaw(requestParameters: UsersViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersView200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersView200ResponseFromJSON(jsonValue));
    }

    /**
     * View user
     */
    async usersView(requestParameters: UsersViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersView200Response> {
        const response = await this.usersViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View vendor product
     */
    async vendorProductViewRaw(requestParameters: VendorProductViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorProductView200Response>> {
        if (requestParameters.vendorProductId === null || requestParameters.vendorProductId === undefined) {
            throw new runtime.RequiredError('vendorProductId','Required parameter requestParameters.vendorProductId was null or undefined when calling vendorProductView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/vendor-products/{vendor_product_id}`.replace(`{${"vendor_product_id"}}`, encodeURIComponent(String(requestParameters.vendorProductId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorProductView200ResponseFromJSON(jsonValue));
    }

    /**
     * View vendor product
     */
    async vendorProductView(requestParameters: VendorProductViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorProductView200Response> {
        const response = await this.vendorProductViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List vendor products
     */
    async vendorProductsListRaw(requestParameters: VendorProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorProductsList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.vendorId !== undefined) {
            queryParameters['vendor_id'] = requestParameters.vendorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/vendor-products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorProductsList200ResponseFromJSON(jsonValue));
    }

    /**
     * List vendor products
     */
    async vendorProductsList(requestParameters: VendorProductsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorProductsList200Response> {
        const response = await this.vendorProductsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View project as PDF
     */
    async viewAsPDFRaw(requestParameters: ViewAsPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling viewAsPDF.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = requestParameters.dateTo;
        }

        if (requestParameters.activityIds) {
            queryParameters['activity_ids[]'] = requestParameters.activityIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/projects/view-as-pdf/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * View project as PDF
     */
    async viewAsPDF(requestParameters: ViewAsPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.viewAsPDFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View user
     */
    async viewUserRaw(requestParameters: ViewUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersView200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling viewUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersView200ResponseFromJSON(jsonValue));
    }

    /**
     * View user
     */
    async viewUser(requestParameters: ViewUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersView200Response> {
        const response = await this.viewUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lookup City object for zip code
     */
    async zipCodeLookupRaw(requestParameters: ZipCodeLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCity200Response>> {
        if (requestParameters.zipCode === null || requestParameters.zipCode === undefined) {
            throw new runtime.RequiredError('zipCode','Required parameter requestParameters.zipCode was null or undefined when calling zipCodeLookup.');
        }

        const queryParameters: any = {};

        if (requestParameters.zipCode !== undefined) {
            queryParameters['zipCode'] = requestParameters.zipCode;
        }

        if (requestParameters.countryIdentifier !== undefined) {
            queryParameters['countryIdentifier'] = requestParameters.countryIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/address-lookup/zipCodeLookup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCity200ResponseFromJSON(jsonValue));
    }

    /**
     * Lookup City object for zip code
     */
    async zipCodeLookup(requestParameters: ZipCodeLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCity200Response> {
        const response = await this.zipCodeLookupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lookup City object for zip code
     */
    async zipCodeSearchRaw(requestParameters: ZipCodeSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCities200Response>> {
        if (requestParameters.zipCode === null || requestParameters.zipCode === undefined) {
            throw new runtime.RequiredError('zipCode','Required parameter requestParameters.zipCode was null or undefined when calling zipCodeSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.countryIdentifier !== undefined) {
            queryParameters['countryIdentifier'] = requestParameters.countryIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/control-panel-api/v1/address-lookup/zip-code-search/{zipCode}`.replace(`{${"zipCode"}}`, encodeURIComponent(String(requestParameters.zipCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCities200ResponseFromJSON(jsonValue));
    }

    /**
     * Lookup City object for zip code
     */
    async zipCodeSearch(requestParameters: ZipCodeSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCities200Response> {
        const response = await this.zipCodeSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ExpenseLinesListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ExpenseLinesListDirectionEnum = typeof ExpenseLinesListDirectionEnum[keyof typeof ExpenseLinesListDirectionEnum];
/**
 * @export
 */
export const ExpensesListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ExpensesListDirectionEnum = typeof ExpensesListDirectionEnum[keyof typeof ExpensesListDirectionEnum];
/**
 * @export
 */
export const FormTemplatesListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type FormTemplatesListDirectionEnum = typeof FormTemplatesListDirectionEnum[keyof typeof FormTemplatesListDirectionEnum];
/**
 * @export
 */
export const FormsListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type FormsListDirectionEnum = typeof FormsListDirectionEnum[keyof typeof FormsListDirectionEnum];
/**
 * @export
 */
export const GetAllRegistrationsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetAllRegistrationsDirectionEnum = typeof GetAllRegistrationsDirectionEnum[keyof typeof GetAllRegistrationsDirectionEnum];
/**
 * @export
 */
export const GetCardsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetCardsDirectionEnum = typeof GetCardsDirectionEnum[keyof typeof GetCardsDirectionEnum];
/**
 * @export
 */
export const GetContactPersonsListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetContactPersonsListDirectionEnum = typeof GetContactPersonsListDirectionEnum[keyof typeof GetContactPersonsListDirectionEnum];
/**
 * @export
 */
export const GetContactsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetContactsDirectionEnum = typeof GetContactsDirectionEnum[keyof typeof GetContactsDirectionEnum];
/**
 * @export
 */
export const GetInvoiceEmailsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetInvoiceEmailsDirectionEnum = typeof GetInvoiceEmailsDirectionEnum[keyof typeof GetInvoiceEmailsDirectionEnum];
/**
 * @export
 */
export const GetInvoicesDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetInvoicesDirectionEnum = typeof GetInvoicesDirectionEnum[keyof typeof GetInvoicesDirectionEnum];
/**
 * @export
 */
export const GetLabelsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetLabelsDirectionEnum = typeof GetLabelsDirectionEnum[keyof typeof GetLabelsDirectionEnum];
/**
 * @export
 */
export const GetOfferEmailsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetOfferEmailsDirectionEnum = typeof GetOfferEmailsDirectionEnum[keyof typeof GetOfferEmailsDirectionEnum];
/**
 * @export
 */
export const GetOfferFilesDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetOfferFilesDirectionEnum = typeof GetOfferFilesDirectionEnum[keyof typeof GetOfferFilesDirectionEnum];
/**
 * @export
 */
export const GetOfferLinesDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetOfferLinesDirectionEnum = typeof GetOfferLinesDirectionEnum[keyof typeof GetOfferLinesDirectionEnum];
/**
 * @export
 */
export const GetOfferPdfDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetOfferPdfDirectionEnum = typeof GetOfferPdfDirectionEnum[keyof typeof GetOfferPdfDirectionEnum];
/**
 * @export
 */
export const GetProjectCustomFieldAttributesDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetProjectCustomFieldAttributesDirectionEnum = typeof GetProjectCustomFieldAttributesDirectionEnum[keyof typeof GetProjectCustomFieldAttributesDirectionEnum];
/**
 * @export
 */
export const GetProjectsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetProjectsDirectionEnum = typeof GetProjectsDirectionEnum[keyof typeof GetProjectsDirectionEnum];
/**
 * @export
 */
export const GetTasksDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetTasksDirectionEnum = typeof GetTasksDirectionEnum[keyof typeof GetTasksDirectionEnum];
/**
 * @export
 */
export const GetTextTemplatesDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetTextTemplatesDirectionEnum = typeof GetTextTemplatesDirectionEnum[keyof typeof GetTextTemplatesDirectionEnum];
/**
 * @export
 */
export const GetUsersDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetUsersDirectionEnum = typeof GetUsersDirectionEnum[keyof typeof GetUsersDirectionEnum];
/**
 * @export
 */
export const GetVendorsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetVendorsDirectionEnum = typeof GetVendorsDirectionEnum[keyof typeof GetVendorsDirectionEnum];
/**
 * @export
 */
export const GetWallPostsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetWallPostsDirectionEnum = typeof GetWallPostsDirectionEnum[keyof typeof GetWallPostsDirectionEnum];
/**
 * @export
 */
export const HasIntegrationOfTypeIdentifierEnum = {
    PayrollSystem: 'payroll_system',
    Roger: 'roger',
    Erp: 'erp'
} as const;
export type HasIntegrationOfTypeIdentifierEnum = typeof HasIntegrationOfTypeIdentifierEnum[keyof typeof HasIntegrationOfTypeIdentifierEnum];
/**
 * @export
 */
export const IGetActivitiesDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type IGetActivitiesDirectionEnum = typeof IGetActivitiesDirectionEnum[keyof typeof IGetActivitiesDirectionEnum];
/**
 * @export
 */
export const IGetFeedForProjectTypeEnum = {
    Form: 'form',
    Invoice: 'invoice',
    Expense: 'expense',
    WallPost: 'wall_post',
    ProjectFile: 'project_file'
} as const;
export type IGetFeedForProjectTypeEnum = typeof IGetFeedForProjectTypeEnum[keyof typeof IGetFeedForProjectTypeEnum];
/**
 * @export
 */
export const IGetTasksForProjectDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type IGetTasksForProjectDirectionEnum = typeof IGetTasksForProjectDirectionEnum[keyof typeof IGetTasksForProjectDirectionEnum];
/**
 * @export
 */
export const IListInvoicesDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type IListInvoicesDirectionEnum = typeof IListInvoicesDirectionEnum[keyof typeof IListInvoicesDirectionEnum];
/**
 * @export
 */
export const IListInvoicesFilterEnum = {
    Booked: 'booked',
    Drafted: 'drafted',
    BookedAndSentAsDraft: 'booked_and_sent_as_draft',
    SentAsDraft: 'sent_as_draft'
} as const;
export type IListInvoicesFilterEnum = typeof IListInvoicesFilterEnum[keyof typeof IListInvoicesFilterEnum];
/**
 * @export
 */
export const IListProjectsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type IListProjectsDirectionEnum = typeof IListProjectsDirectionEnum[keyof typeof IListProjectsDirectionEnum];
/**
 * @export
 */
export const IListProjectsProjectStatusEnum = {
    Open: 'open',
    ReadyForBilling: 'ready_for_billing',
    Closed: 'closed'
} as const;
export type IListProjectsProjectStatusEnum = typeof IListProjectsProjectStatusEnum[keyof typeof IListProjectsProjectStatusEnum];
/**
 * @export
 */
export const IListTasksDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type IListTasksDirectionEnum = typeof IListTasksDirectionEnum[keyof typeof IListTasksDirectionEnum];
/**
 * @export
 */
export const IListWallCommentsDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type IListWallCommentsDirectionEnum = typeof IListWallCommentsDirectionEnum[keyof typeof IListWallCommentsDirectionEnum];
/**
 * @export
 */
export const IProjectListFilesDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type IProjectListFilesDirectionEnum = typeof IProjectListFilesDirectionEnum[keyof typeof IProjectListFilesDirectionEnum];
/**
 * @export
 */
export const IStandardOfferFileUploadTypeEnum = {
    Estimate: 'estimate',
    Quote: 'quote'
} as const;
export type IStandardOfferFileUploadTypeEnum = typeof IStandardOfferFileUploadTypeEnum[keyof typeof IStandardOfferFileUploadTypeEnum];
/**
 * @export
 */
export const IStandardOfferFilesListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type IStandardOfferFilesListDirectionEnum = typeof IStandardOfferFilesListDirectionEnum[keyof typeof IStandardOfferFilesListDirectionEnum];
/**
 * @export
 */
export const ListUsersDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListUsersDirectionEnum = typeof ListUsersDirectionEnum[keyof typeof ListUsersDirectionEnum];
/**
 * @export
 */
export const OffersListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type OffersListDirectionEnum = typeof OffersListDirectionEnum[keyof typeof OffersListDirectionEnum];
/**
 * @export
 */
export const ProductBundleLinesListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ProductBundleLinesListDirectionEnum = typeof ProductBundleLinesListDirectionEnum[keyof typeof ProductBundleLinesListDirectionEnum];
/**
 * @export
 */
export const ProductBundlesListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ProductBundlesListDirectionEnum = typeof ProductBundlesListDirectionEnum[keyof typeof ProductBundlesListDirectionEnum];
/**
 * @export
 */
export const ProductsListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ProductsListDirectionEnum = typeof ProductsListDirectionEnum[keyof typeof ProductsListDirectionEnum];
/**
 * @export
 */
export const ProjectGetKpiCardHoursAllFormsSubPageDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ProjectGetKpiCardHoursAllFormsSubPageDirectionEnum = typeof ProjectGetKpiCardHoursAllFormsSubPageDirectionEnum[keyof typeof ProjectGetKpiCardHoursAllFormsSubPageDirectionEnum];
/**
 * @export
 */
export const ProjectGetKpiCardInvoicesSubPageDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ProjectGetKpiCardInvoicesSubPageDirectionEnum = typeof ProjectGetKpiCardInvoicesSubPageDirectionEnum[keyof typeof ProjectGetKpiCardInvoicesSubPageDirectionEnum];
/**
 * @export
 */
export const ProjectGetKpiCardProductsPreCalculationSubPageDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ProjectGetKpiCardProductsPreCalculationSubPageDirectionEnum = typeof ProjectGetKpiCardProductsPreCalculationSubPageDirectionEnum[keyof typeof ProjectGetKpiCardProductsPreCalculationSubPageDirectionEnum];
/**
 * @export
 */
export const ProjectGetKpiCardProductsSubpageProductsTabDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ProjectGetKpiCardProductsSubpageProductsTabDirectionEnum = typeof ProjectGetKpiCardProductsSubpageProductsTabDirectionEnum[keyof typeof ProjectGetKpiCardProductsSubpageProductsTabDirectionEnum];
/**
 * @export
 */
export const UsersGetSpecifiedDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type UsersGetSpecifiedDirectionEnum = typeof UsersGetSpecifiedDirectionEnum[keyof typeof UsersGetSpecifiedDirectionEnum];
/**
 * @export
 */
export const UsersGetSummedDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type UsersGetSummedDirectionEnum = typeof UsersGetSummedDirectionEnum[keyof typeof UsersGetSummedDirectionEnum];
/**
 * @export
 */
export const UsersPriceGroupsListDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type UsersPriceGroupsListDirectionEnum = typeof UsersPriceGroupsListDirectionEnum[keyof typeof UsersPriceGroupsListDirectionEnum];
