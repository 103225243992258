import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Navigate } from "src/store/router/router.actions";
import { PlatformsEnum } from "src/app/shared/enums/platforms.enum";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { App } from "@capacitor/app";
import { CompaniesService } from "src/app/services/companies.service";
import { CompanySettingsService } from "src/app/services/company-settings.service";
import { ProjectStatusesService } from "src/app/services/project-statuses.service";
import { User } from "src/app/services/response-models/user.response.model";
import { UsersService } from "src/app/services/users.service";
import {
    StateInitializationInterface,
    StateInitializationService,
} from "src/app/services/state-initialization/state-initialization.service";
import { FormsService } from "src/app/services/forms.service";
import { WindowWrapper } from "src/app/services/window-wrapper.service";
import { TimeEntryService } from "src/app/services/time-entry.service";
import { CurrenciesService } from "src/app/services/currencies.service";
import { CountriesService } from "src/app/services/countries.service";
import { sentryCaptureException } from "src/app/utility/errors";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { FeaturesService } from "src/app/services/features.service";

@Injectable()
export class EmployeeStateInitializationService
    extends StateInitializationService
    implements StateInitializationInterface
{
    constructor(
        protected companiesService: CompaniesService,
        protected companySettingsService: CompanySettingsService,
        protected projectStatusesService: ProjectStatusesService,
        protected store: Store,
        protected userService: UsersService,
        protected timeEntryTypesServices: TimeEntryService,
        protected currenciesService: CurrenciesService,
        protected countriesService: CountriesService,
        protected featuresService: FeaturesService,
        private formsService: FormsService,
        private _w: WindowWrapper,
        private localStorageService: LocalStorageService
    ) {
        super(
            companiesService,
            companySettingsService,
            projectStatusesService,
            userService,
            store,
            timeEntryTypesServices,
            currenciesService,
            countriesService,
            featuresService
        );
    }

    public canUse(user: User, platform: string): boolean {
        const window = this._w.getWindow();

        const seemsLikeMobileAppUser = this.seemsLikeMobileAppUser(platform, user);

        const isIframeOrPopup = window.location !== window.parent.location;
        const forceMobileApp =
            this.localStorageService.readObject("force_mobile_app") === true || isIframeOrPopup;

        return seemsLikeMobileAppUser || forceMobileApp;
    }

    private seemsLikeMobileAppUser(platform: string, user: User): boolean {
        const platformDifferentFromDesktop = platform !== PlatformsEnum.Desktop;
        const userHasIndividualLogin = !!user.roles.find(
            (role) => role.identifier === "company_employee_individual_login"
        );

        return userHasIndividualLogin && platformDifferentFromDesktop;
    }

    public async init(): Promise<void> {
        await Promise.all([
            super.init(),
            this.companiesService.setUserCompany(),
            this.getUserCheckInStatus(),
            this.getUserHours(),
            this.getCompanyEmployees(),
            this.getFormTemplates(),
            this.navigate(),
            this.setKeyboardSettings(),
        ]).catch((reason) => {
            sentryCaptureException(reason);
            throw new Error("Could not initialize app");
        });

        this.listenForAppStateChange();
    }

    private async setKeyboardSettings(): Promise<void> {
        if (Capacitor.getPlatform() === "ios" && Capacitor.isPluginAvailable("Keyboard")) {
            await Keyboard.setAccessoryBarVisible({ isVisible: true });
        }
    }

    private async getUserCheckInStatus(): Promise<void> {
        await this.userService.isUserCheckedIn();
    }

    private async getUserHours(): Promise<void> {
        await this.userService.getUserHoursForCurrentWeek();
    }

    protected async navigate(): Promise<void> {
        this.store.dispatch(new Navigate(["employee", "projects"]));
    }

    private async getCompanyEmployees(): Promise<void> {
        await this.userService.getCompanyEmployees();
    }

    private async getFormTemplates(): Promise<void> {
        await this.formsService.getLastUsedFormTemplates();
    }

    private listenForAppStateChange(): void {
        App.addListener("appStateChange", (data) => {
            if (data.isActive) {
                this.getUserCheckInStatus();
            }
        });
    }
}
