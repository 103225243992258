/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsCheckedIn200ResponseData
 */
export interface IsCheckedIn200ResponseData {
    /**
     * Is user checked in for clocking records
     * @type {boolean}
     * @memberof IsCheckedIn200ResponseData
     */
    readonly checkedIn: boolean;
}

/**
 * Check if a given object implements the IsCheckedIn200ResponseData interface.
 */
export function instanceOfIsCheckedIn200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "checkedIn" in value;

    return isInstance;
}

export function IsCheckedIn200ResponseDataFromJSON(json: any): IsCheckedIn200ResponseData {
    return IsCheckedIn200ResponseDataFromJSONTyped(json, false);
}

export function IsCheckedIn200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsCheckedIn200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkedIn': json['checked_in'],
    };
}

export function IsCheckedIn200ResponseDataToJSON(value?: IsCheckedIn200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

