/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceLine
 */
export interface InvoiceLine {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    formId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    expenseLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    vendorProductId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    eanProductId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    type: InvoiceLineTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    discountText?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    sellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    buyingPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    placement?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    discountPercent?: number;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceLine
     */
    endTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceLine
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceLine
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof InvoiceLine
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    totalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    usersPriceGroupId?: string;
}


/**
 * @export
 */
export const InvoiceLineTypeEnum = {
    Normal: 'normal',
    User: 'user',
    Text: 'text',
    Bundle: 'bundle'
} as const;
export type InvoiceLineTypeEnum = typeof InvoiceLineTypeEnum[keyof typeof InvoiceLineTypeEnum];


/**
 * Check if a given object implements the InvoiceLine interface.
 */
export function instanceOfInvoiceLine(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "invoiceId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function InvoiceLineFromJSON(json: any): InvoiceLine {
    return InvoiceLineFromJSONTyped(json, false);
}

export function InvoiceLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'invoiceId': json['invoice_id'],
        'formId': !exists(json, 'form_id') ? undefined : json['form_id'],
        'parentId': !exists(json, 'parent_id') ? undefined : json['parent_id'],
        'materialId': !exists(json, 'material_id') ? undefined : json['material_id'],
        'expenseLineId': !exists(json, 'expense_line_id') ? undefined : json['expense_line_id'],
        'vendorProductId': !exists(json, 'vendor_product_id') ? undefined : json['vendor_product_id'],
        'eanProductId': !exists(json, 'ean_product_id') ? undefined : json['ean_product_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': json['type'],
        'discountText': !exists(json, 'discount_text') ? undefined : json['discount_text'],
        'sellingPrice': !exists(json, 'selling_price') ? undefined : json['selling_price'],
        'buyingPrice': !exists(json, 'buying_price') ? undefined : json['buying_price'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
        'discountPercent': !exists(json, 'discount_percent') ? undefined : json['discount_percent'],
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'totalPrice': !exists(json, 'total_price') ? undefined : json['total_price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'usersPriceGroupId': !exists(json, 'users_price_group_id') ? undefined : json['users_price_group_id'],
    };
}

export function InvoiceLineToJSON(value?: InvoiceLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'invoice_id': value.invoiceId,
        'form_id': value.formId,
        'parent_id': value.parentId,
        'material_id': value.materialId,
        'expense_line_id': value.expenseLineId,
        'vendor_product_id': value.vendorProductId,
        'ean_product_id': value.eanProductId,
        'user_id': value.userId,
        'product_id': value.productId,
        'name': value.name,
        'description': value.description,
        'type': value.type,
        'discount_text': value.discountText,
        'selling_price': value.sellingPrice,
        'buying_price': value.buyingPrice,
        'reference': value.reference,
        'placement': value.placement,
        'discount_percent': value.discountPercent,
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'total_price': value.totalPrice,
        'quantity': value.quantity,
        'users_price_group_id': value.usersPriceGroupId,
    };
}

