// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements
export const environment = {
    production: true,
    environmentName: "master",
    apiBaseUrl: "https://app.apacta.com/api/v1/",
    appUrl: "https://app.apacta.com/",
    v3ControlPanelUrl: "https://control-panel.apacta.com",
    tomtomApiUrl: "https://api.tomtom.com/search/2/",
    tomtomApiKey: "vyD43m3Kpr7RrJ49JKABdsrlF1OROHvO",
    segmentWriteKey: "n0L7ojy5y2OBVrU3BJsbLOnQBxzvRCGG",
    enableSentry: true,
    sentryTracesSampleRate: 0,
};
