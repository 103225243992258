import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: "[showPassword]",
})
export class ShowPasswordDirective {
    @HostListener("click", ["$event"]) click(e) {
        const input = this.el.nativeElement.parentElement;
        const icon = this.el.nativeElement;

        if (input.type === "password") {
            input.type = "text";
            icon.name = "eye-off";
            return;
        }

        input.type = "password";
        icon.name = "eye";

        e.preventDefault();
        e.stopPropagation();
    }

    constructor(private el: ElementRef) {}
}
